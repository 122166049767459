import dayjs from 'dayjs';

import { AgentStatus, Cluster } from '@cast/types';

import { SUPPORTS_STORAGE } from 'common/constants';

export const isDisconnectedForMonth = (cluster: Cluster) =>
  cluster.agentStatus === AgentStatus.DISCONNECTED &&
  dayjs(cluster.agentSnapshotReceivedAt).isBefore(dayjs().subtract(30, 'day'));

export const isStorageReportsSupported = ({ providerType }: Cluster) =>
  SUPPORTS_STORAGE.includes(providerType);
