import { Query, useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export const useUpdateCastAnywherePricingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: apiClient.pricing.updateCastAnywherePricing,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }: Query) =>
          queryKey[0] === QueryKeys.PRICING_CAST_ANYWHERE_CLUSTER_COSTS,
      });
    },
  });
};
