import * as Sentry from '@sentry/react';

import { user } from 'core/auth';

const cleanup = () => {
  document.body.classList.remove('show-booking-loader');
};

const showLoader = () => {
  document.body.classList.add('show-booking-loader');
};

type OpenScheduleDialogArgs = {
  onMeetingBooked?: () => void;
  onError?: (error: unknown) => void;
};

export const openScheduleDialog = ({
  onMeetingBooked,
  onError,
}: OpenScheduleDialogArgs = {}) => {
  const { email, name } = user();
  showLoader();
  const scriptEl = document.createElement('script');
  scriptEl.src = 'https://cdn.leandata.com/js-snippet/ld-book-v2.js';
  scriptEl.addEventListener('load', () => {
    try {
      const LDBookItV2 = window?.LDBookItV2;
      if (!LDBookItV2) {
        return Sentry.captureMessage(
          'LeanData script is loaded but window has no "LDBookItV2" entry'
        );
      }
      // Copypasted from https://leandatahelp.zendesk.com/hc/en-us/articles/20483792593947-LeanData-BookIt-for-Forms-Custom-Implementation
      LDBookItV2.initialize(
        '00D3h000003SOm7EAG',
        'Main trigger',
        'ld_bookit_log_id'
      );
      LDBookItV2.saveFormData({
        first_name: name,
        work_email: email,
      });
      LDBookItV2.submit({
        cb: () => {
          cleanup();
        },
      });
    } catch (e) {
      onError?.(e);
      cleanup();
    }
  });

  if (onError) {
    scriptEl.addEventListener('error', (err) => {
      onError(err);
      cleanup();
    });
  }
  document.body.appendChild(scriptEl);

  // https://leandatahelp.zendesk.com/hc/en-us/articles/24466862228123-LeanData-BookIt-for-Forms-Custom-Code-Snippet-Events
  window.addEventListener(
    'message',
    function (e) {
      switch (e.data.message) {
        case 'LD_POST_BOOKING_IMMEDIATE':
          setTimeout(() => {
            onMeetingBooked?.();
          }, 3000);
          break;
      }
    },
    // add once to avoid multiple events
    { once: true }
  );
};
