import { Box, Stack, Typography } from '@mui/material';

import { CostMonitoringVisualSvg } from 'assets/svg';

import {
  AgentSetupBlock,
  AgentSetupBlockProps,
} from './_components/AgentSetupBlock';
import { AgentSetupCompleteBlock } from './_components/AgentSetupCompleteBlock';
import FeatureLabel from './_components/FeatureLabel';
import { ExtendedSettings, ProductFeatures } from '../../../../types';

type AgentCostSetupProps = Partial<AgentSetupBlockProps> & {
  extendedSettingsSelection?: ExtendedSettings;
  activeFeatures?: ProductFeatures;
  onAdvancedOptionChange?: (payload: Partial<ExtendedSettings>) => void;
};

export const AgentCostSetup = (props: AgentCostSetupProps) => {
  const {
    setupComplete,
    showCheckbox,
    extendedSettingsSelection,
    activeFeatures,
    onAdvancedOptionChange,
  } = props;

  const heading = 'Cost monitoring';
  const icon = <CostMonitoringVisualSvg />;
  const networkCostHeading = 'Network cost use metrics';
  const gpuMetricsHeading = 'GPU metrics exporter';
  const optionalFeaturesTitle = (
    <Typography
      variant="L10M"
      color="grey.400"
      textTransform="uppercase"
      mb={4}
    >
      Advanced (Optional)
    </Typography>
  );

  if (setupComplete) {
    return (
      <AgentSetupCompleteBlock
        heading={heading}
        body={
          <Stack ml={32}>
            {optionalFeaturesTitle}
            <AgentSetupCompleteBlock
              heading={networkCostHeading}
              checked={!!extendedSettingsSelection?.includeNetworkCosts}
              isNestedBlock
              testId="agent-network-costs-complete"
            />
            <AgentSetupCompleteBlock
              heading={gpuMetricsHeading}
              checked={!!extendedSettingsSelection?.includeGpuExporter}
              isNestedBlock
              testId="agent-gpu-exporter-complete"
            />
          </Stack>
        }
        icon={icon}
        testId="agent-cost-complete"
        {...props}
      />
    );
  }

  return (
    <AgentSetupBlock
      heading={heading}
      description={
        <Stack>
          <Box mb={24}>
            Track and analyze your Kubernetes spending with detailed cost
            breakdowns and reports
          </Box>
          <Stack>
            {optionalFeaturesTitle}
            <AgentSetupBlock
              checked={!!extendedSettingsSelection?.includeNetworkCosts}
              readonly={!!activeFeatures?.networkCostMetrics}
              heading={networkCostHeading}
              description="Visualize network traffic costs and identify optimization opportunities across your cluster"
              onChange={(enabled) =>
                onAdvancedOptionChange?.({
                  includeNetworkCosts: enabled,
                })
              }
              labelChip={<FeatureLabel />}
              isNestedBlock
              checkboxTestId="network-cost-metrics-checkbox"
              showCheckbox={showCheckbox}
            />
            <AgentSetupBlock
              checked={!!extendedSettingsSelection?.includeGpuExporter}
              readonly={!!activeFeatures?.gpuMetricsExporter}
              heading={gpuMetricsHeading}
              description="Monitor GPU utilization and performance to maximize resource efficiency"
              onChange={(enabled) =>
                onAdvancedOptionChange?.({
                  includeGpuExporter: enabled,
                })
              }
              labelChip={<FeatureLabel />}
              isNestedBlock
              checkboxTestId="gpu-metrics-exporter-checkbox"
              showCheckbox={showCheckbox}
            />
          </Stack>
        </Stack>
      }
      icon={icon}
      labelChip={<FeatureLabel />}
      checkboxTestId="agent-cost-checkbox"
      {...props}
    />
  );
};
