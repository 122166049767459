import { Divider, Stack, Typography } from '@mui/material';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';
import { Icons, Scroller } from '@cast/design-system';
import { K8sProvider } from '@cast/types';
import { isDemoMode, usePath } from '@cast/utils';

import {
  CostMonitoringVisualSvg,
  OrgOptimizationSvg,
  SecurityVisualSvg,
} from 'assets/svg';
import {
  makeOrgCostMonitoringAllocationGroupsLink,
  makeOrgCostMonitoringClustersLink,
} from 'core/navigation';
import {
  getOrgSecurityRoutePath,
  makeOrgCompliancePath,
  makeOrgVulnerabilityManagementPath,
} from 'features/organization/security-insights';
import { useHasNoAgentsEnabled } from 'features/organization/security-insights/hooks/useHasNoAgentsEnabled';
import { useClusters } from 'hooks/useClusters';
import { useOrgHasClustersWithProvider } from 'hooks/useOrgHasClustersWithProvider';
import { useMainLayout } from 'main-layout/hooks';
import { BetaLabel } from 'main-layout/sidebar/_components/BetaLabel';
import { SidebarItem } from 'main-layout/sidebar/_components/SidebarItem';

import { DemoLabel } from './DemoLabel';
import { useTrial } from '../../../core/trial/TrialProvider';

const COMMITMENTS_PROVIDERS: Array<`${K8sProvider}`> = ['aks', 'gke'];

export const OrganizationLevelSidebar = () => {
  const { isEligible } = useTrial();

  const { sidebarState } = useMainLayout();
  const { anywhereOnly } = useClusters();

  const { hasNoAgentsEnabled, isLoading: isAgentsLoading } =
    useHasNoAgentsEnabled();
  const securityNotEnabled = !isAgentsLoading && hasNoAgentsEnabled;

  const hasAksOrGkeClusters = useOrgHasClustersWithProvider(
    COMMITMENTS_PROVIDERS
  );

  const orgComplianceLink = usePath(makeOrgCompliancePath);
  const orgVulnerabilityManagementLink = usePath(
    makeOrgVulnerabilityManagementPath
  );

  const clustersCostMonitoringLink = usePath(() =>
    makeOrgCostMonitoringClustersLink()
  );

  const allocationGroupsCostMonitoringLink = usePath(() =>
    makeOrgCostMonitoringAllocationGroupsLink()
  );

  if (!sidebarState) {
    return null;
  }

  const isMinimized = sidebarState === 'minimized';

  return (
    <>
      <Typography
        variant="L10M"
        color="textSecondary"
        pl={isMinimized ? 0 : 20}
        pr={isMinimized ? 0 : 8}
        mb={10}
        textAlign={isMinimized ? 'center' : 'left'}
      >
        {isMinimized ? 'org.' : 'organization'}
      </Typography>

      <Scroller
        sx={{
          px: isMinimized ? '0px' : '8px',
          '& .os-scrollbar-vertical .os-scrollbar-track': {
            pl: '2px !important',
          },
        }}
      >
        <Stack gap={8}>
          <SidebarItem
            title="Cluster list"
            icon={<Icons.Hexagon height={18} weight="fill" />}
            url="/dashboard"
          />

          <SidebarItem
            title="Optimization"
            icon={<OrgOptimizationSvg />}
            url={
              anywhereOnly
                ? '/organization/management/billing-report#sidebar'
                : '/settings/scheduled-rebalancing'
            }
            childrenWrapperSx={
              isEligible
                ? {
                    '& .sidebar-children-line': {
                      borderColor: 'green.500',
                    },
                  }
                : undefined
            }
          >
            {isEligible && (
              <Typography
                variant="L10M"
                color="green.500"
                pl={isMinimized ? 0 : 12}
                mb={10}
                textAlign={isMinimized ? 'center' : 'left'}
              >
                {isMinimized ? 'prm.' : 'premium features'}
              </Typography>
            )}

            <SidebarItem
              title="Scheduled rebalancing"
              url="/settings/scheduled-rebalancing"
              rules={[['view', 'OrganizationRebalanceSchedules']]}
              disabled={anywhereOnly}
            />

            <SidebarItem
              title="Commitments"
              url="/commitments"
              disabled={!hasAksOrGkeClusters || anywhereOnly}
              rules={[['view', 'OrganizationCommitments']]}
              supportsDemo={false}
            />

            <SidebarItem
              title="Billing report"
              url="/organization/management/billing-report#sidebar"
              rules={[['view', 'OrganizationBillingReport']]}
              supportsDemo={false}
            />
            <SidebarItem
              title="Discount engine"
              url="/optimization/discount-engine"
              rules={[['view', 'OrganizationDiscounts']]}
              disabled={anywhereOnly}
              supportsDemo={false}
            />
          </SidebarItem>

          <SidebarItem
            title="Cost monitoring"
            icon={<CostMonitoringVisualSvg />}
            url="/organization/cost-report"
          >
            <SidebarItem
              title="Clusters"
              url={clustersCostMonitoringLink}
              rules={[['view', 'OrganizationCostMonitoring']]}
            />
            <SidebarItem
              title="Allocation groups"
              url={allocationGroupsCostMonitoringLink}
              endAdornment={<BetaLabel />}
              rules={[['view', 'OrganizationCostMonitoring']]}
            />
          </SidebarItem>

          {!anywhereOnly && (
            <SidebarItem
              title="Security"
              icon={<SecurityVisualSvg width={16} height={16} />}
              url="/organization/security/dashboard"
              // Only show demo label if org has clusters onboarded to phase 1
              // but security is not enabled for any of them
              endAdornment={
                !isDemoMode() && securityNotEnabled && <DemoLabel />
              }
            >
              {(isDemoMode() || CAN_VIEW_SECURITY()) && (
                <SidebarItem
                  title="Dashboard"
                  url="/organization/security/dashboard"
                />
              )}

              <SidebarItem
                title="Compliance"
                url={orgComplianceLink}
                rules={[['view', 'OrganizationSecurityCompliance']]}
              />

              <SidebarItem
                url={orgVulnerabilityManagementLink}
                title="Vulnerabilities"
                rules={[['view', 'OrganizationSecurityVulnerabilities']]}
              />

              <SidebarItem
                url={getOrgSecurityRoutePath('attack-path')}
                title="Attack paths"
                rules={[['view', 'OrganizationSecurityAttackPaths']]}
              />

              <SidebarItem
                url={getOrgSecurityRoutePath('runtime')}
                title="Runtime"
                rules={[['view', 'OrganizationSecurityRuntime']]}
              />

              <SidebarItem
                url={getOrgSecurityRoutePath('workloads')}
                title="Workloads"
                rules={[['view', 'OrganizationSecurityWorkloads']]}
              />

              <SidebarItem
                url={getOrgSecurityRoutePath('node-updates')}
                title="Node updates"
                rules={[['view', 'OrganizationSecurityNodeRotation']]}
              />

              <SidebarItem
                url={getOrgSecurityRoutePath('settings')}
                title="Settings"
                rules={[['view', 'OrganizationSecuritySettings']]}
              />
            </SidebarItem>
          )}
        </Stack>
      </Scroller>

      <Divider
        sx={{
          borderColor: 'grey.50',
          margin: '8px 0px 10px',
        }}
      />
    </>
  );
};
