import { useMemo } from 'react';

import { AgentStatus, Cluster, ClusterStatus, K8sProvider } from '@cast/types';

import { MANAGED_PROVIDERS } from 'features/cluster/constants';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';
import { isClusterManaged } from 'utils/cluster';

import { useClusters } from './useClusters';

// prettier-ignore
export const useGetClusterActionsVisibility = (cluster: Cluster) => {
  const { clusters } = useClusters();
  const clusterIds = useMemo(() => clusters.map((c) => c.id), [clusters]);
  const {
    agentsData,
    isLoading: isLoadingSecurityAgents,
    error: securityAgentsError,
  } = useSecurityInsightsAgents({ clusterIds, determineSecurityDemoMode: false, enabled: !!clusterIds.length });

  const isFailed = Boolean(cluster.reconcileError);
  const isManaged = isClusterManaged(cluster);
  const isPaused = cluster.status === ClusterStatus.PAUSED;
  const isPausing = cluster.status === ClusterStatus.PAUSING;
  const isResuming = cluster.status === ClusterStatus.RESUMING;
  const isOnline = cluster.agentStatus === AgentStatus.ONLINE;
  const isReady = cluster.status === ClusterStatus.READY;
  const isDisconnected = cluster.agentStatus === AgentStatus.DISCONNECTED;
  const isConnecting = cluster.status === ClusterStatus.CONNECTING || cluster.agentStatus === AgentStatus.CONNECTING;
  const canBeManaged = MANAGED_PROVIDERS.includes(cluster.providerType);
  const isSecurityAgentEnabled = Boolean(agentsData?.statuses?.[cluster.id]?.installed);
  const wasSecurityLoaded = !isLoadingSecurityAgents || Boolean(securityAgentsError);
  const canConnect = !isFailed && !isDisconnected && !isManaged && !isPaused && !isPausing && !isResuming && !isConnecting && isOnline && canBeManaged;
  const isCastAnywhere = cluster.providerType === K8sProvider.ANYWHERE;

  return {
    pause: !isFailed && ((isManaged && !isDisconnected && !isPaused) || isResuming),
    resume: !isFailed && ((isManaged && isPaused) || isPausing),
    disconnect: !isFailed && ((isManaged && !isDisconnected) || isOnline),
    delete: !isCastAnywhere && ((!isFailed && isDisconnected) || isConnecting),
    reconnect: !isReady && !isCastAnywhere && (isManaged || isDisconnected || isFailed),
    reconcile: isManaged && !isCastAnywhere && !isDisconnected,
    remove: isFailed || (isDisconnected && isCastAnywhere),
    connectPhase2: canConnect && wasSecurityLoaded && !isSecurityAgentEnabled,
    connectAutoscaler: canConnect && wasSecurityLoaded && isSecurityAgentEnabled,
    connectSecurity: isManaged && !isCastAnywhere && !isFailed && !isDisconnected && !isPaused && !isPausing && !isResuming && !isConnecting && isOnline && wasSecurityLoaded && !isSecurityAgentEnabled,
    adjustCosts: isCastAnywhere,
    features: !isCastAnywhere && (isManaged ? isReady : isOnline),
  };
};
