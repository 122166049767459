import { useMemo } from 'react';

import orderBy from 'lodash/orderBy';

import { K8sProvider } from '@cast/types';

import {
  useClustersQuery,
  UseClustersQueryArgs,
} from 'hooks/queries/cluster/useClustersQuery';

export const useClusters = (args?: UseClustersQueryArgs) => {
  const query = useClustersQuery(args);

  const _clusters = useMemo(() => {
    return orderBy(query.clusters, ['createdAt'], ['desc']);
  }, [query.clusters]);

  const anywhereSummary = useMemo(() => {
    if (!_clusters?.length) {
      return { hasAnywhere: false, anywhereOnly: false };
    }
    const anywhereCount = _clusters.reduce(
      (acc, curr) =>
        curr.providerType === K8sProvider.ANYWHERE ? acc + 1 : acc,
      0
    );
    return {
      hasAnywhere: anywhereCount > 0,
      anywhereOnly: anywhereCount === _clusters.length,
    };
  }, [_clusters]);

  return {
    ...query,
    ...anywhereSummary,
    clusters: _clusters,
    hasError: Boolean(query.error),
  };
};
