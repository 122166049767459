import { PriceType } from '@cast/types';

import { fullPriceTypeMap, priceTypeMap } from 'common/maps';
import { AvailableSavingsHistoryChartDataPoint } from 'types/available-savings';

import { HistoryChartDataType, Period } from './HistorySection/types';

export const OPTIMAL_ZONE_BOUNDARY = 85;
export const LARGE_CLUSTER_COSTS_FROM = 1000;

export const historyCharCurrentDataKeyMap: Record<
  HistoryChartDataType,
  [
    keyof AvailableSavingsHistoryChartDataPoint,
    keyof AvailableSavingsHistoryChartDataPoint
  ]
> = {
  [HistoryChartDataType.CPU]: ['cpuSpot', 'cpuOnDemand'],
  [HistoryChartDataType.MEMORY]: ['memorySpot', 'memoryOnDemand'],
  [HistoryChartDataType.NODES]: ['nodesSpot', 'nodesOnDemand'],
};

export const historyCharOptimizedDataKeyMap: Record<
  HistoryChartDataType,
  [
    keyof AvailableSavingsHistoryChartDataPoint,
    keyof AvailableSavingsHistoryChartDataPoint
  ]
> = {
  [HistoryChartDataType.CPU]: ['cpuSpotOptimized', 'cpuOnDemandOptimized'],
  [HistoryChartDataType.MEMORY]: [
    'memorySpotOptimized',
    'memoryOnDemandOptimized',
  ],
  [HistoryChartDataType.NODES]: [
    'nodesSpotOptimized',
    'nodesOnDemandOptimized',
  ],
};

export const historyCharTotalsKeyMap: Record<
  HistoryChartDataType,
  [
    keyof AvailableSavingsHistoryChartDataPoint,
    keyof AvailableSavingsHistoryChartDataPoint
  ]
> = {
  [HistoryChartDataType.CPU]: ['totalCpu', 'totalCpuOptimized'],
  [HistoryChartDataType.MEMORY]: ['totalMemory', 'totalMemoryOptimized'],
  [HistoryChartDataType.NODES]: ['totalNodes', 'totalNodesOptimized'],
};

export const historyCharAxisLabelMap: Record<HistoryChartDataType, string> = {
  [HistoryChartDataType.CPU]: 'CPU',
  [HistoryChartDataType.MEMORY]: 'GiB',
  [HistoryChartDataType.NODES]: 'Nodes',
};

export const periodMap = {
  [Period.DAY]: '24 hours',
  [Period.WEEK]: '7 days',
  [Period.MONTH]: '30 days',
};

export const rateMap = {
  [PriceType.HOURLY]: {
    title: fullPriceTypeMap[PriceType.HOURLY],
    suffix: `${priceTypeMap[PriceType.HOURLY]}`,
  },
  [PriceType.DAILY]: {
    title: fullPriceTypeMap[PriceType.DAILY],
    suffix: `${priceTypeMap[PriceType.DAILY]}`,
  },
  [PriceType.MONTHLY]: {
    title: fullPriceTypeMap[PriceType.MONTHLY],
    suffix: `${priceTypeMap[PriceType.MONTHLY]}`,
  },
};
