import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../utils/cssVars';

const { getters, setValues, defs } = cssVars(
  {
    px: '8px',
    height: '24px',
    fontSize: '12px',
    minHeight: 'initial',
    gap: '4px',
    overlayIconSize: '12px',
  },
  'ds-chip'
);

export const chipTheme: ThemeOptions['components'] = {
  DsChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        width: 'max-content',
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
        borderRadius: 4,
        paddingLeft: getters.px(),
        paddingRight: getters.px(),
        height: !ownerState?.multiline ? getters.height() : undefined,
        minHeight: ownerState?.multiline ? getters.height() : undefined,
        fontSize: getters.fontSize(),
        gap: getters.gap(),
        fontFamily: theme.constants.fonts.body,
        fontWeight: '500',
        lineHeight: 1,
        '&.Ds-disabled': {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[400],
        },
      }),
      iconChip: {
        width: getters.height(),
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'center',
      },
      micro: ({ ownerState }) => ({
        ...setValues({
          px: '4px',
          height: !ownerState?.multiline ? '10px' : undefined,
          minHeight: ownerState?.multiline ? '10px' : undefined,
          fontSize: '8px',
          gap: '2px',
          overlayIconSize: '10px',
        }),
      }),
      extraSmall: ({ ownerState }) => ({
        ...setValues({
          px: '6px',
          height: !ownerState?.multiline ? '16px' : undefined,
          minHeight: ownerState?.multiline ? '16px' : undefined,
          fontSize: '10px',
          gap: '2px',
          overlayIconSize: '12px',
        }),
      }),
      small: ({ ownerState }) => ({
        ...setValues({
          px: '6px',
          height: !ownerState?.multiline ? '20px' : undefined,
          minHeight: ownerState?.multiline ? '20px' : undefined,
          fontSize: '12px',
          overlayIconSize: '12px',
        }),
      }),
      medium: ({ ownerState }) => ({
        ...defs,
        height: !ownerState?.multiline ? '24px' : undefined,
        minHeight: ownerState?.multiline ? '24px' : undefined,
        overlayIconSize: '14px',
      }),
      large: ({ ownerState }) => ({
        ...setValues({
          height: !ownerState?.multiline ? '28px' : undefined,
          minHeight: ownerState?.multiline ? '28px' : undefined,
          fontSize: '14px',
          overlayIconSize: '14px',
        }),
      }),
      extraLarge: ({ ownerState }) => ({
        ...setValues({
          px: '12px',
          height: !ownerState?.multiline ? '32px' : undefined,
          minHeight: ownerState?.multiline ? '32px' : undefined,
          fontSize: '14px',
        }),
      }),
      content: ({ ownerState }) => ({
        cursor: 'default',
        display: 'flex',
        position: 'relative',
        whiteSpace: 'pre-wrap',
        ...(ownerState?.multiline && {
          wordBreak: 'break-all',
          lineHeight: '18px',
        }),
        ...(ownerState?.size === 'micro' && {
          height: '100%',
        }),
      }),
      iconWrapper: {
        display: 'flex',
      },
      destroyIcon: {
        fontSize: 'calc(1em + 2px)',
        fontWeight: 'bold',
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': {
          opacity: 1,
        },
        '.Ds-disabled &': {
          cursor: 'default',
          opacity: 0.2,
        },
      },
      destroyIconWrapper: {
        paddingLeft: 4,
      },
      overlayIconWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: `translate(calc(50% + ${getters.px()}), -50%)`,
        '.DsChip-OverlayIcon': {
          fontSize: getters.overlayIconSize(),
        },
      },
    },
  },
};
