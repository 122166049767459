import { useQuery } from '@tanstack/react-query';

import { TrialStatusResponse } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';

import { TRIAL_ENABLED } from '../../../common/constants';
import { useAuth } from '../../../core/auth';
import { useOrganizations } from '../../useOrganizations';

type useTrialStatusQueryArgs = {
  data?: TrialStatusResponse;
  isLoading: boolean;
  error: unknown;
  refetch: () => void;
};

export const useTrialStatusQuery = (): useTrialStatusQueryArgs => {
  const { isAuthenticated } = useAuth();
  const { isReady: isOrganizationReady } = useOrganizations();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [QueryKeys.TRIAL_STATUS],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getTrialStatus();
      return data;
    },
    enabled: isAuthenticated && isOrganizationReady && TRIAL_ENABLED,
  });

  return { data, isLoading, error, refetch };
};
