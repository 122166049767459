/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1CreateServiceAccountKeyResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1CreateServiceAccountResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1GetServiceAccountKeyResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1GetServiceAccountResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1ListServiceAccountsResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1UpdateServiceAccountKeyResponse } from '../models';
// @ts-ignore
import type { CastaiServiceaccountsV1beta1UpdateServiceAccountResponse } from '../models';
// @ts-ignore
import type { KeyIsTheServiceAccountKeyToUpdate } from '../models';
// @ts-ignore
import type { ServiceAccountsAPICreateServiceAccountKeyRequest } from '../models';
// @ts-ignore
import type { ServiceAccountsAPIUpdateServiceAccountRequest } from '../models';
/**
 * ServiceAccountsAPIApi - axios parameter creator
 * @export
 */
export const ServiceAccountsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CreateServiceAccount creates a service account for the given organization.
         * @param {string} organizationId 
         * @param {CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount} serviceAccount ServiceAccount is the service account to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPICreateServiceAccount: async (organizationId: string, serviceAccount: CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPICreateServiceAccount', 'organizationId', organizationId)
            // verify required parameter 'serviceAccount' is not null or undefined
            assertParamExists('serviceAccountsAPICreateServiceAccount', 'serviceAccount', serviceAccount)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateServiceAccountKey creates a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {ServiceAccountsAPICreateServiceAccountKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPICreateServiceAccountKey: async (organizationId: string, serviceAccountId: string, body: ServiceAccountsAPICreateServiceAccountKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPICreateServiceAccountKey', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPICreateServiceAccountKey', 'serviceAccountId', serviceAccountId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('serviceAccountsAPICreateServiceAccountKey', 'body', body)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}/keys`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteServiceAccount deletes a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} serviceAccountId ID is the id of the service account to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccount: async (organizationId: string, serviceAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccount', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccount', 'serviceAccountId', serviceAccountId)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteServiceAccountKey deletes a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {string} keyId KeyID is the key ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccountKey: async (organizationId: string, serviceAccountId: string, keyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccountKey', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccountKey', 'serviceAccountId', serviceAccountId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccountKey', 'keyId', keyId)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}/keys/{keyId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteServiceAccounts deletes a list of service accounts for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {Array<string>} serviceAccountIds ServiceAccountIDs is the list of service account ids to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccounts: async (organizationId: string, serviceAccountIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccounts', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountIds' is not null or undefined
            assertParamExists('serviceAccountsAPIDeleteServiceAccounts', 'serviceAccountIds', serviceAccountIds)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (serviceAccountIds) {
                localVarQueryParameter['serviceAccountIds'] = serviceAccountIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetServiceAccount gets a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} serviceAccountId ID is the id of the service account to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIGetServiceAccount: async (organizationId: string, serviceAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIGetServiceAccount', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIGetServiceAccount', 'serviceAccountId', serviceAccountId)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetServiceAccountKey gets a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to get a service account key for.
         * @param {string} serviceAccountId ServiceAccountID is the id of the service account to get a key for.
         * @param {string} keyId KeyID is the id of the key to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIGetServiceAccountKey: async (organizationId: string, serviceAccountId: string, keyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIGetServiceAccountKey', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIGetServiceAccountKey', 'serviceAccountId', serviceAccountId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('serviceAccountsAPIGetServiceAccountKey', 'keyId', keyId)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}/keys/{keyId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListServiceAccounts lists all service accounts for organization in context.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIListServiceAccounts: async (organizationId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIListServiceAccounts', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateServiceAccount updates a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to update the service account for.
         * @param {string} serviceAccountId ServiceAccountID is the id of the service account to be updated.
         * @param {ServiceAccountsAPIUpdateServiceAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIUpdateServiceAccount: async (organizationId: string, serviceAccountId: string, body: ServiceAccountsAPIUpdateServiceAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccount', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccount', 'serviceAccountId', serviceAccountId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccount', 'body', body)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateServiceAccountKey updates a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {string} keyId 
         * @param {KeyIsTheServiceAccountKeyToUpdate} key Key is the service account key to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIUpdateServiceAccountKey: async (organizationId: string, serviceAccountId: string, keyId: string, key: KeyIsTheServiceAccountKeyToUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccountKey', 'organizationId', organizationId)
            // verify required parameter 'serviceAccountId' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccountKey', 'serviceAccountId', serviceAccountId)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccountKey', 'keyId', keyId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('serviceAccountsAPIUpdateServiceAccountKey', 'key', key)
            const localVarPath = `/v1/organizations/{organizationId}/service-accounts/{serviceAccountId}/keys/{key.id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(serviceAccountId)))
                .replace(`{${"key.id"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(key, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceAccountsAPIApi - functional programming interface
 * @export
 */
export const ServiceAccountsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceAccountsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CreateServiceAccount creates a service account for the given organization.
         * @param {string} organizationId 
         * @param {CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount} serviceAccount ServiceAccount is the service account to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPICreateServiceAccount(organizationId: string, serviceAccount: CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1CreateServiceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPICreateServiceAccount(organizationId, serviceAccount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPICreateServiceAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateServiceAccountKey creates a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {ServiceAccountsAPICreateServiceAccountKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPICreateServiceAccountKey(organizationId: string, serviceAccountId: string, body: ServiceAccountsAPICreateServiceAccountKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1CreateServiceAccountKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPICreateServiceAccountKey(organizationId, serviceAccountId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPICreateServiceAccountKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteServiceAccount deletes a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} serviceAccountId ID is the id of the service account to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIDeleteServiceAccount(organizationId: string, serviceAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIDeleteServiceAccount(organizationId, serviceAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIDeleteServiceAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteServiceAccountKey deletes a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {string} keyId KeyID is the key ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIDeleteServiceAccountKey(organizationId: string, serviceAccountId: string, keyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIDeleteServiceAccountKey(organizationId, serviceAccountId, keyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIDeleteServiceAccountKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteServiceAccounts deletes a list of service accounts for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {Array<string>} serviceAccountIds ServiceAccountIDs is the list of service account ids to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIDeleteServiceAccounts(organizationId: string, serviceAccountIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIDeleteServiceAccounts(organizationId, serviceAccountIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIDeleteServiceAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetServiceAccount gets a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} serviceAccountId ID is the id of the service account to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIGetServiceAccount(organizationId: string, serviceAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1GetServiceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIGetServiceAccount(organizationId, serviceAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIGetServiceAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetServiceAccountKey gets a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to get a service account key for.
         * @param {string} serviceAccountId ServiceAccountID is the id of the service account to get a key for.
         * @param {string} keyId KeyID is the id of the key to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIGetServiceAccountKey(organizationId: string, serviceAccountId: string, keyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1GetServiceAccountKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIGetServiceAccountKey(organizationId, serviceAccountId, keyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIGetServiceAccountKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListServiceAccounts lists all service accounts for organization in context.
         * @param {string} organizationId OrganizationID is the id of the organization to list service accounts for.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIListServiceAccounts(organizationId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1ListServiceAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIListServiceAccounts(organizationId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIListServiceAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateServiceAccount updates a service account for the given organization.
         * @param {string} organizationId OrganizationID is the id of the organization to update the service account for.
         * @param {string} serviceAccountId ServiceAccountID is the id of the service account to be updated.
         * @param {ServiceAccountsAPIUpdateServiceAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIUpdateServiceAccount(organizationId: string, serviceAccountId: string, body: ServiceAccountsAPIUpdateServiceAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1UpdateServiceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIUpdateServiceAccount(organizationId, serviceAccountId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIUpdateServiceAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateServiceAccountKey updates a service account key for the given organization.
         * @param {string} organizationId OrganizationID is the organization ID.
         * @param {string} serviceAccountId ServiceAccountID is the service account ID.
         * @param {string} keyId 
         * @param {KeyIsTheServiceAccountKeyToUpdate} key Key is the service account key to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceAccountsAPIUpdateServiceAccountKey(organizationId: string, serviceAccountId: string, keyId: string, key: KeyIsTheServiceAccountKeyToUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiServiceaccountsV1beta1UpdateServiceAccountKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceAccountsAPIUpdateServiceAccountKey(organizationId, serviceAccountId, keyId, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceAccountsAPIApi.serviceAccountsAPIUpdateServiceAccountKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceAccountsAPIApi - factory interface
 * @export
 */
export const ServiceAccountsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceAccountsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary CreateServiceAccount creates a service account for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPICreateServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1CreateServiceAccountResponse> {
            return localVarFp.serviceAccountsAPICreateServiceAccount(requestParameters.organizationId, requestParameters.serviceAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateServiceAccountKey creates a service account key for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPICreateServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1CreateServiceAccountKeyResponse> {
            return localVarFp.serviceAccountsAPICreateServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteServiceAccount deletes a service account for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.serviceAccountsAPIDeleteServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteServiceAccountKey deletes a service account key for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.serviceAccountsAPIDeleteServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteServiceAccounts deletes a list of service accounts for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIDeleteServiceAccounts(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.serviceAccountsAPIDeleteServiceAccounts(requestParameters.organizationId, requestParameters.serviceAccountIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetServiceAccount gets a service account for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIGetServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1GetServiceAccountResponse> {
            return localVarFp.serviceAccountsAPIGetServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetServiceAccountKey gets a service account key for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIGetServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1GetServiceAccountKeyResponse> {
            return localVarFp.serviceAccountsAPIGetServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListServiceAccounts lists all service accounts for organization in context.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIListServiceAccounts(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1ListServiceAccountsResponse> {
            return localVarFp.serviceAccountsAPIListServiceAccounts(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateServiceAccount updates a service account for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIUpdateServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1UpdateServiceAccountResponse> {
            return localVarFp.serviceAccountsAPIUpdateServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateServiceAccountKey updates a service account key for the given organization.
         * @param {ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceAccountsAPIUpdateServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiServiceaccountsV1beta1UpdateServiceAccountKeyResponse> {
            return localVarFp.serviceAccountsAPIUpdateServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, requestParameters.key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceAccountsAPICreateServiceAccount operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccount
     */
    readonly organizationId: string

    /**
     * ServiceAccount is the service account to be created.
     * @type {CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccount
     */
    readonly serviceAccount: CastaiServiceaccountsV1beta1CreateServiceAccountRequestServiceAccount
}

/**
 * Request parameters for serviceAccountsAPICreateServiceAccountKey operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest {
    /**
     * OrganizationID is the organization ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKey
     */
    readonly organizationId: string

    /**
     * ServiceAccountID is the service account ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKey
     */
    readonly serviceAccountId: string

    /**
     * 
     * @type {ServiceAccountsAPICreateServiceAccountKeyRequest}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKey
     */
    readonly body: ServiceAccountsAPICreateServiceAccountKeyRequest
}

/**
 * Request parameters for serviceAccountsAPIDeleteServiceAccount operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest {
    /**
     * OrganizationID is the id of the organization to list service accounts for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccount
     */
    readonly organizationId: string

    /**
     * ID is the id of the service account to be deleted.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccount
     */
    readonly serviceAccountId: string
}

/**
 * Request parameters for serviceAccountsAPIDeleteServiceAccountKey operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest {
    /**
     * OrganizationID is the organization ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKey
     */
    readonly organizationId: string

    /**
     * ServiceAccountID is the service account ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKey
     */
    readonly serviceAccountId: string

    /**
     * KeyID is the key ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKey
     */
    readonly keyId: string
}

/**
 * Request parameters for serviceAccountsAPIDeleteServiceAccounts operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest {
    /**
     * OrganizationID is the id of the organization to list service accounts for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccounts
     */
    readonly organizationId: string

    /**
     * ServiceAccountIDs is the list of service account ids to be deleted.
     * @type {Array<string>}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccounts
     */
    readonly serviceAccountIds: Array<string>
}

/**
 * Request parameters for serviceAccountsAPIGetServiceAccount operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest {
    /**
     * OrganizationID is the id of the organization to list service accounts for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccount
     */
    readonly organizationId: string

    /**
     * ID is the id of the service account to be fetched.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccount
     */
    readonly serviceAccountId: string
}

/**
 * Request parameters for serviceAccountsAPIGetServiceAccountKey operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest {
    /**
     * OrganizationID is the id of the organization to get a service account key for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKey
     */
    readonly organizationId: string

    /**
     * ServiceAccountID is the id of the service account to get a key for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKey
     */
    readonly serviceAccountId: string

    /**
     * KeyID is the id of the key to be fetched.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKey
     */
    readonly keyId: string
}

/**
 * Request parameters for serviceAccountsAPIListServiceAccounts operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest {
    /**
     * OrganizationID is the id of the organization to list service accounts for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIListServiceAccounts
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIListServiceAccounts
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIListServiceAccounts
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for serviceAccountsAPIUpdateServiceAccount operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest {
    /**
     * OrganizationID is the id of the organization to update the service account for.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccount
     */
    readonly organizationId: string

    /**
     * ServiceAccountID is the id of the service account to be updated.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccount
     */
    readonly serviceAccountId: string

    /**
     * 
     * @type {ServiceAccountsAPIUpdateServiceAccountRequest}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccount
     */
    readonly body: ServiceAccountsAPIUpdateServiceAccountRequest
}

/**
 * Request parameters for serviceAccountsAPIUpdateServiceAccountKey operation in ServiceAccountsAPIApi.
 * @export
 * @interface ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest
 */
export interface ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest {
    /**
     * OrganizationID is the organization ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKey
     */
    readonly organizationId: string

    /**
     * ServiceAccountID is the service account ID.
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKey
     */
    readonly serviceAccountId: string

    /**
     * 
     * @type {string}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKey
     */
    readonly keyId: string

    /**
     * Key is the service account key to update.
     * @type {KeyIsTheServiceAccountKeyToUpdate}
     * @memberof ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKey
     */
    readonly key: KeyIsTheServiceAccountKeyToUpdate
}

/**
 * ServiceAccountsAPIApi - object-oriented interface
 * @export
 * @class ServiceAccountsAPIApi
 * @extends {BaseAPI}
 */
export class ServiceAccountsAPIApi extends BaseAPI {
    /**
     * 
     * @summary CreateServiceAccount creates a service account for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPICreateServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPICreateServiceAccount(requestParameters.organizationId, requestParameters.serviceAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateServiceAccountKey creates a service account key for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPICreateServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPICreateServiceAccountKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPICreateServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteServiceAccount deletes a service account for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIDeleteServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIDeleteServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteServiceAccountKey deletes a service account key for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIDeleteServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIDeleteServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteServiceAccounts deletes a list of service accounts for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIDeleteServiceAccounts(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIDeleteServiceAccountsRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIDeleteServiceAccounts(requestParameters.organizationId, requestParameters.serviceAccountIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetServiceAccount gets a service account for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIGetServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIGetServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetServiceAccountKey gets a service account key for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIGetServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIGetServiceAccountKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIGetServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListServiceAccounts lists all service accounts for organization in context.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIListServiceAccounts(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIListServiceAccountsRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIListServiceAccounts(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateServiceAccount updates a service account for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIUpdateServiceAccount(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIUpdateServiceAccount(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateServiceAccountKey updates a service account key for the given organization.
     * @param {ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceAccountsAPIApi
     */
    public serviceAccountsAPIUpdateServiceAccountKey(requestParameters: ServiceAccountsAPIApiServiceAccountsAPIUpdateServiceAccountKeyRequest, options?: RawAxiosRequestConfig) {
        return ServiceAccountsAPIApiFp(this.configuration).serviceAccountsAPIUpdateServiceAccountKey(requestParameters.organizationId, requestParameters.serviceAccountId, requestParameters.keyId, requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }
}

