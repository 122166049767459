import { PropsWithChildren, ReactNode } from 'react';

import { Stack } from '@mui/material';

import { DialogActions, DialogContent, DialogTitle } from '@cast/design-system';

import { CloseDialogButton } from './CloseDialogButton';

type Props = PropsWithChildren<{
  variant?: 'success' | 'error';
  title?: string;
  actions?: ReactNode;
  onClose?: () => void;
}>;

export const DialogFeedbackLayout = ({
  variant,
  children,
  actions,
  onClose,
}: Props) => {
  return (
    <Stack
      position="relative"
      data-testid={
        variant === 'success' ? 'feedback-success' : 'feedback-failure'
      }
    >
      <DialogTitle sx={{ px: 24, py: 12 }}>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ py: 16, px: 24, borderTop: 'grey.200' }}>
        {actions}
      </DialogActions>
    </Stack>
  );
};
