import { cloneElement, ReactElement } from 'react';

import { Box, BoxProps, styled, SvgIcon } from '@mui/material';

import { mergeSx } from '@cast/design-system';

import { useThemeColor } from 'hooks/theme';

const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
});

const animationByType = {
  blink: '2s infinite normal blink',
  pulsate: '1s infinite alternate pulsate',
  pulsateSlow: '1.3s infinite alternate pulsateSlow',
  none: undefined,
};

export type HexIconContainerProps = BoxProps & {
  color: string;
  size?: number;
  opacity?: number;
  animationType?: keyof typeof animationByType;
  icon?: ReactElement;
  testId?: string;
  children: ReactElement;
};

export const HexIconContainer = ({
  size = 100,
  opacity = 0.15,
  children,
  color,
  icon,
  animationType = 'pulsate',
  sx,
  testId,
  ...props
}: HexIconContainerProps) => {
  color = useThemeColor(color);
  const iconSize = size / 2.7;

  return (
    <Box
      className="HexIcon-Root"
      position="relative"
      sx={mergeSx(
        {
          width: size,
          height: size,
          '@keyframes pulsate': {
            from: { scale: '0.7' },
            to: { scale: '1' },
          },
          '@keyframes pulsateSlow': {
            '0%': { scale: '0.7' },
            '70%': { scale: '1' },
            '100%': { scale: '1' },
          },
          '@keyframes blink': {
            from: { scale: '0.7', opacity: '1' },
            '70%': { scale: '1', opacity: '0.3' },
            to: { scale: '1', opacity: 0 },
          },
          '& .HexIcon-Icon': {
            fontSize: size,
          },
          '& .HexIcon-Shadow': {
            position: 'absolute',
            animation: animationByType[animationType],
          },
          '& .HexIcon-Main': {
            position: 'relative',
          },
        },
        sx
      )}
      data-testid={testId}
      {...props}
    >
      <SvgIcon
        className="HexIcon-Shadow HexIcon-Icon"
        width="58"
        height="64"
        viewBox="0 0 58 64"
        fill="none"
      >
        <path
          opacity={opacity}
          fill={color}
          d="M2.06778 14.8575C0.792126 15.5613 -2.05868e-06 16.9029 -1.99499e-06 18.3599L-8.02629e-07 45.638C-7.38949e-07 47.0948 0.792037 48.4364 2.06757 49.1402L27.0664 62.9338C28.2692 63.5974 29.7284 63.5975 30.9312 62.9339L55.9323 49.1402C57.2079 48.4364 58 47.0948 58 45.6379L58 18.3599C58 16.903 57.2078 15.5613 55.9321 14.8575L30.931 1.06586C29.7283 0.402415 28.2693 0.40244 27.0667 1.06593L2.06778 14.8575Z"
        />
      </SvgIcon>
      {cloneElement(children, { className: 'HexIcon-Main HexIcon-Icon' })}
      {!!icon && (
        <IconWrapper width={size} height={size}>
          <SvgIcon
            sx={{ width: iconSize, height: iconSize }}
            viewBox={`0 0 ${iconSize} ${iconSize}`}
          >
            <defs>
              {/* https://css-tricks.com/adding-shadows-to-svg-icons-with-css-and-svg-filters/ */}
              <filter id="inset-shadow">
                {/* Shadow offset */}
                <feOffset dx="2" dy="2" />
                {/* Shadow blur */}
                <feGaussianBlur stdDeviation="2" result="offset-blur" />
                {/* Invert drop shadow to make an inset shadow */}
                <feComposite
                  operator="out"
                  in="SourceGraphic"
                  in2="offset-blur"
                  result="inverse"
                />
                {/* Cut color inside shadow */}
                <feFlood floodColor="black" floodOpacity=".5" result="color" />
                <feComposite
                  operator="in"
                  in="color"
                  in2="inverse"
                  result="shadow"
                />
                {/* Placing shadow over element */}
                <feComposite operator="over" in="shadow" in2="SourceGraphic" />
              </filter>
            </defs>
            <g filter="url(#inset-shadow)">
              {cloneElement(icon, {
                size: iconSize,
                width: iconSize,
                height: iconSize,
              })}
            </g>
          </SvgIcon>
        </IconWrapper>
      )}
    </Box>
  );
};
