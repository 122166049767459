import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';

import { notify } from '../../../core/snackbars';
import { parseApiError } from '../../../utils/api';

export const useMarkSalesContactedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => apiClient.costReport.markSalesContacted(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TRIAL_STATUS],
      });
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
