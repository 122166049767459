import { Box } from '@mui/material';
import { Field, FieldProps } from 'react-final-form';

import { Button } from '@cast/design-system';

type Props = FieldProps<string, any> & {
  name: string;
  label: string;
};

export const JobFunctionToggle = ({ name, label, ...fieldProps }: Props) => {
  const jobFunctions = [
    'Development',
    'Product Engineering',
    'Cloud Engineering',
    'DevOps',
    'Security',
    'FinOps',
    'CTO',
    'CxO',
    'Other',
  ];

  return (
    <Field
      {...fieldProps}
      name={name}
      render={(props) => {
        return (
          <>
            <Box
              component="span"
              sx={{
                fontFamily: 'Soehne Kraftig',
                fontSize: '14px',
                lineHeight: '20px',
                gridColumn: '1 / -1',
                marginBottom: '4px',
                display: 'flex',
                fontWeight: '500',
                marginTop: '24px',
              }}
            >
              {label}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'wrap',
                gap: '16px',
              }}
            >
              {jobFunctions.map((functionName) => {
                const isSelected = props.input.value === functionName;
                const boxShadow = isSelected
                  ? '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #0669FD'
                  : '';

                return (
                  <Box
                    key={functionName}
                    sx={{
                      '&& .DsButton-root[variant="tertiary"]:hover': {
                        boxShadow,
                      },
                    }}
                  >
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        props.input.onChange(functionName);
                      }}
                      sx={{
                        boxShadow,
                      }}
                    >
                      {functionName}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </>
        );
      }}
    ></Field>
  );
};
