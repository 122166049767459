import {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  BoxProps,
  Popover,
  Stack,
  styled,
  Theme,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';

import {
  activeProps,
  Badge,
  ConditionalWrapper,
  focusProps,
  Icons,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ProgressBar,
  Tooltip,
} from '@cast/design-system';
import { isDemoMode } from '@cast/utils';

import { Link as RouterLink } from 'components/router';
import { ability, AbilityConditions, Actions, Subjects } from 'core/ability';
import { useOrganizations } from 'hooks/useOrganizations';

import { NotificationIcon } from './NotificationIcon';
import { useMainLayout } from '../../hooks';

type OwnerState = {
  isSubItem: boolean;
  isDisabled: boolean;
  isExpanded: boolean;
  isMinimized: boolean;
  isPopoverOpen: boolean;
  isSelected: boolean;
  isChildSelected: boolean;
  hasChildren: boolean;
};

type MenuBoxProps = BoxProps & {
  ownerState: OwnerState;
};

const MenuBox = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'ownerState',
})<MenuBoxProps>(({ theme, ownerState }) => [
  {
    marginLeft: ownerState.isSubItem ? 8 : undefined,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: ownerState.isSubItem ? '5px 8px' : '5px 8px 5px 12px',
    borderRadius: '4px',
    minHeight: '32px',
    border: '1px solid transparent',
    color: theme.palette.grey[600],

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200],
    },
  },
  !ownerState.isDisabled && {
    '&&:active': {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.blue[100],
      ...activeProps(theme),
    },

    '&:focus-visible': {
      backgroundColor: theme.palette.grey[100],
      ...focusProps(theme),
    },
  },
  ownerState.isDisabled && {
    '& > div': {
      color: theme.palette.grey[300],
    },
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'none',
    },
  },
  ownerState.isExpanded &&
    !ownerState.isMinimized && {
      '& svg line': {
        stroke: theme.palette.blue[500],
      },
      '& span': {
        color: theme.palette.blue[500],
      },
    },
  ownerState.isMinimized && {
    justifyContent: 'center',
    width: 32,
    height: 32,
    padding: 0,
    margin: '0 auto',
  },
  ownerState.isPopoverOpen && {
    backgroundColor: theme.palette.blue[100],
  },
  ownerState.isMinimized &&
    ownerState.isChildSelected && {
      backgroundColor: theme.palette.blue[100],
    },
  ownerState.isSelected &&
    !ownerState.hasChildren && {
      backgroundColor: theme.palette.blue[100],
      '& > div': {
        color: theme.palette.blue[600],
      },
      '&:hover': {
        backgroundColor: theme.palette.blue[200],
      },
    },
  ownerState.isSelected &&
    ownerState.hasChildren && {
      '& > div': {
        color: theme.palette.blue[500],
      },
      '&:hover': {
        backgroundColor: theme.palette.blue[200],
      },
    },
  ownerState.hasChildren && {
    '&:active': {
      color: theme.palette.blue[100],
    },
  },
]);

export type SidebarItemProps = {
  title: ReactNode;
  url?: string;
  icon?: ReactElement;
  hasError?: boolean;
  disabled?: boolean;
  isSubItem?: boolean;
  tooltip?: string;
  endAdornment?: ReactNode;
  progressBar?: { show: boolean; value: number };
  rules?: Array<[Actions, Subjects]>;
  rulesConditions?: AbilityConditions;
  supportsDemo?: boolean;
  childrenWrapperSx?: SxProps<Theme>;
};

const getPathFromUrl = (url: string) => {
  return url.split(/[?#]/)[0];
};

const hasRenderableChildren = (
  children: any,
  rulesConditions?: AbilityConditions
): boolean => {
  return (Array.isArray(children) ? children : [children]).some((child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      return true;
    }

    if (isValidElement(child)) {
      if (child.type === Fragment) {
        const props = child.props as PropsWithChildren<unknown>;
        return hasRenderableChildren(props.children, rulesConditions);
      }

      if (child.type === SidebarItem) {
        const props = child.props as SidebarItemProps;

        if (isDemoMode() && !props.supportsDemo) {
          return false;
        }

        return props.rules?.length
          ? ability.canMany(props.rules, rulesConditions).every((v) => v)
          : true;
      }

      return true;
    }

    return false;
  });
};

const appendOrganizationIdToUrl = (
  url: string | undefined,
  organizationId: string | undefined
) => {
  if (!url) return null;
  const urlWithOrg = new URL(url, window.location.origin);
  if (organizationId) {
    urlWithOrg.searchParams.set('org', organizationId);
  }
  return `${urlWithOrg.pathname}${urlWithOrg.search}`;
};

const getChildItems = (children: ReactNode) => {
  const result: ReactElement[] = [];
  Children.forEach(children, (child) => {
    if (!isValidElement(child)) {
      return;
    }
    if (child.type === Fragment) {
      result.push(...getChildItems(child.props.children));
    } else {
      result.push(
        cloneElement(child, {
          ...child.props,
          isSubItem: true,
        })
      );
    }
  });
  return result;
};

export const SidebarItem = ({
  children,
  isSubItem,
  title,
  url,
  icon,
  hasError,
  disabled,
  tooltip,
  endAdornment,
  progressBar,
  rules,
  rulesConditions,
  supportsDemo = true,
  childrenWrapperSx,
}: PropsWithChildren<SidebarItemProps>) => {
  const theme = useTheme();
  const { sidebarState } = useMainLayout();
  const location = useLocation();
  const { currentOrganization } = useOrganizations();

  const _children = getChildItems(children);

  const isChildSelected = useMemo(() => {
    for (const child of _children) {
      if (
        child.props.url &&
        location.pathname.startsWith(getPathFromUrl(child.props.url))
      ) {
        return true;
      }
    }
    return false;
  }, [location.pathname, _children]);

  const isSelected =
    isChildSelected ||
    Boolean(url && location.pathname.startsWith(getPathFromUrl(url)));

  const [isExpanded, setIsExpanded] = useState(children && isSelected);
  useEffect(() => {
    setIsExpanded(children && isSelected);
  }, [isSelected, children]);

  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isMinimized = sidebarState === 'minimized';

  const openPopover = useCallback(() => {
    if (isMinimized || !disabled) {
      return setIsPopoverOpen(true);
    }
  }, [disabled, isMinimized]);

  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const ownerState: OwnerState = {
    isSubItem: Boolean(isSubItem),
    isDisabled: Boolean(disabled),
    isExpanded: Boolean(isExpanded),
    isMinimized,
    isPopoverOpen,
    isSelected,
    isChildSelected,
    hasChildren: Boolean(children),
  };

  const doesntHaveAccess =
    rules?.length && ability.canMany(rules, rulesConditions).some((v) => !v);
  const noRenderableChildren =
    _children.length && !hasRenderableChildren(_children, rulesConditions);

  if (isDemoMode()) {
    if (!supportsDemo || doesntHaveAccess) return null;
  } else {
    if (doesntHaveAccess || noRenderableChildren) return null;
  }

  const parentUrl = appendOrganizationIdToUrl(url, currentOrganization?.id);

  return (
    <>
      <ConditionalWrapper
        condition={!!tooltip}
        wrapper={(children) => (
          <Tooltip
            arrow
            size="small"
            title={tooltip as string}
            placement="right"
          >
            <div>{children}</div>
          </Tooltip>
        )}
      >
        <MenuBox
          ownerState={ownerState}
          data-testid={`sidebar-item-${title}`}
          data-enabled={!disabled}
          {...((parentUrl && !isExpanded && !disabled && !isMinimized) ||
          isMinimized
            ? {
                component: RouterLink,
                to: parentUrl,
              }
            : {})}
          aria-owns="sidebar-popover"
          aria-haspopup="true"
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          ref={anchorRef}
          onClick={closePopover}
        >
          <Box
            display="flex"
            justifyContent={isMinimized ? 'center' : 'space-between'}
            width="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={isMinimized ? 4 : 8}
              position="relative"
              width="100%"
            >
              {icon && hasError && (
                <Badge
                  count={1}
                  horizontal="right"
                  size="triangle"
                  variant="primary"
                  vertical="top"
                >
                  {cloneElement(icon, {
                    size: 16,
                    color: 'currentColor',
                  })}
                </Badge>
              )}

              {icon &&
                !hasError &&
                cloneElement(icon, {
                  size: 16,
                  color: 'currentColor',
                })}

              <Box
                sx={{
                  position: isMinimized ? 'absolute' : 'relative',
                  width: '100%',
                  bottom: 0,
                  left: 0,
                }}
              >
                {!isMinimized && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={6}
                    pr={ownerState.hasChildren ? 8 : 0}
                  >
                    <Stack
                      display="inline-flex"
                      direction="row"
                      alignItems="center"
                      gap={6}
                    >
                      <Typography
                        variant="P14M"
                        component="div"
                        data-testid="title"
                      >
                        {title}
                      </Typography>
                      {tooltip && <Icons.Info size={16} />}
                    </Stack>

                    {endAdornment}
                  </Stack>
                )}

                {progressBar?.show && (
                  <ProgressBar
                    sx={{
                      position: 'absolute',
                      bottom: '-2px',
                      left: '0',
                      width: '100%',

                      ...(isSelected && {
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: theme.palette.blue[600],
                        },
                      }),
                    }}
                    size="small"
                    color="tertiary"
                    variant="determinate"
                    value={progressBar.value}
                  />
                )}
              </Box>

              {hasError && !icon && <NotificationIcon />}

              {isMinimized && (
                <Popover
                  id="sidebar-popover"
                  open={isPopoverOpen}
                  anchorEl={anchorRef.current}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: -8,
                  }}
                  sx={{
                    pointerEvents: 'none', // lets anchor receive mouse events
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        pointerEvents: 'auto',
                      },
                      onMouseEnter: openPopover,
                      onMouseLeave: closePopover,
                    },
                  }}
                  disableScrollLock
                >
                  <List
                    size="small"
                    sx={{
                      minWidth: _children.length > 0 ? 197 : 'auto',
                      py: '4px',
                    }}
                  >
                    <ListSubheader
                      sx={{ color: 'grey.400', '&:only-child': { py: '8px' } }}
                      testId="title"
                    >
                      {title}
                    </ListSubheader>

                    {_children.map((child, i) => {
                      const key = child.props.key || child.props.url || i;
                      return (
                        <ConditionalWrapper
                          key={key}
                          condition={child.props.tooltip}
                          wrapper={(children) => (
                            <Tooltip
                              arrow
                              size="small"
                              title={child.props.tooltip}
                              placement="right"
                            >
                              <div>{children}</div>
                            </Tooltip>
                          )}
                        >
                          <ListItem
                            onClick={closePopover}
                            selected={Boolean(
                              location?.pathname &&
                                child.props.url?.startsWith(location.pathname)
                            )}
                            disabled={child.props.disabled}
                          >
                            <ConditionalWrapper
                              condition={!child.props.disabled}
                              wrapper={(children) => (
                                <RouterLink
                                  to={child.props.url}
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  {children}
                                </RouterLink>
                              )}
                            >
                              <ListItemText
                                primary={
                                  <Stack
                                    component="span"
                                    direction="row"
                                    alignItems="center"
                                    gap="4px"
                                  >
                                    {child.props.title}
                                    {child.props.tooltip && <Icons.Info />}
                                    {child.props.hasError && (
                                      <NotificationIcon />
                                    )}
                                  </Stack>
                                }
                              />
                            </ConditionalWrapper>
                          </ListItem>
                        </ConditionalWrapper>
                      );
                    })}
                  </List>
                </Popover>
              )}
            </Box>
          </Box>

          {!isMinimized && children && (
            <Box display="flex" color="grey.600">
              {isExpanded ? (
                <Icons.CaretUp size={16} />
              ) : (
                <Icons.CaretDown size={16} />
              )}
            </Box>
          )}
        </MenuBox>
      </ConditionalWrapper>

      {!isMinimized && children && isExpanded && (
        <Box
          position="relative"
          display="flex"
          ml="20px"
          mt="4px"
          sx={childrenWrapperSx}
          data-testid={`sidebar-item-${title}-children`}
        >
          <Box
            className="sidebar-children-line"
            position="absolute"
            top="0"
            left="0"
            bottom="0px"
            borderLeft="1px solid"
            borderColor="grey.200"
          />

          <Stack flexGrow={1} gap="4px">
            {_children.map((child, index) => (
              <Fragment key={index}>{child}</Fragment>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};
