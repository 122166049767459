import { useState } from 'react';

import {
  FormControlLabel,
  formControlLabelClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Input, Radio, RadioGroup } from '@cast/design-system';

import { RosaClusterOnboardingParameters } from 'types/cluster';

export const useRosaParametersForm = () => {
  const [sccEnabled, setSccEnabled] = useState(false);
  const [rosaParameters, setRosaParameters] = useState<
    RosaClusterOnboardingParameters | undefined
  >();

  return {
    rosaFormProps: {
      sccEnabled,
      setSccEnabled,
      setRosaParameters,
    },
    rosaQueryArg: sccEnabled ? rosaParameters : undefined,
  };
};

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 'unset',
  [`& .${formControlLabelClasses.label}`]: {
    ...theme.typography.A2,
    paddingLeft: '8px',
  },
}));

type SetRosaParameters = (
  rosaParameters?: RosaClusterOnboardingParameters
) => RosaClusterOnboardingParameters | undefined;

type Props = {
  sccEnabled: boolean;
  setSccEnabled: (enabled: boolean) => void;
  setRosaParameters: (setRosaParameters: SetRosaParameters) => void;
};

export const RosaParametersForm = ({
  sccEnabled,
  setSccEnabled,
  setRosaParameters,
}: Props) => {
  const renderInput = (param: keyof RosaClusterOnboardingParameters) => (
    <Input
      testId={`rosa-${param}-input`}
      fullWidth
      size="small"
      label={param}
      onBlur={(e) => {
        setRosaParameters((params) => ({
          ...params,
          [param]: e.target.value || undefined,
        }));
      }}
    />
  );

  return (
    <Stack gap="16px" data-testid="scc-setup-section">
      <Stack gap="14px">
        <Stack gap="4px">
          <Typography variant="h6">SCC setup on the cluster</Typography>

          <Typography variant="P12R">
            Do you use a custom Security Context Constraints setup?
            <br />
            If so, additional parameters will need to be specified.
          </Typography>
        </Stack>

        <RadioGroup
          value={sccEnabled}
          onChange={(_, value) => setSccEnabled(value === 'true')}
          sx={{ flexDirection: 'row', mb: '6px' }}
        >
          <StyledLabel
            value={false}
            control={<Radio size="small" testId="ssc-disabled-option" />}
            label="No"
          />
          <StyledLabel
            value={true}
            control={<Radio size="small" testId="ssc-enabled-option" />}
            label="Yes"
          />
        </RadioGroup>
      </Stack>

      {sccEnabled && (
        <Stack bgcolor="grey.100" mx="-32px" p="12px 32px 16px" gap="24px">
          <Stack gap="8px">
            <Typography variant="A2">
              Provide SCC IDs to be used by the CAST AI agent
            </Typography>

            <Stack direction="row" justifyContent="space-between" gap="16px">
              {renderInput('runAsUser')}
              {renderInput('runAsGroup')}
              {renderInput('fsGroup')}
            </Stack>
          </Stack>

          <div>
            <Typography variant="A2" mb="6px">
              Add CAST AI service account to SCC users
            </Typography>
            <Typography variant="P12R" color="grey.700">
              In scenarios where access to SCC is restricted, please update the
              relevant SCC to let the castai-agent account use it.
            </Typography>
          </div>
        </Stack>
      )}
    </Stack>
  );
};
