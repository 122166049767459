import { useRef, useState } from 'react';

import { useModal } from 'mui-modal-provider';

import { Dialog } from '@cast/design-system';

import { trialEvents } from 'core/analytics';
import { openScheduleDialog } from 'core/integrations';
import { ShowModalOutput } from 'types/modal';

import { InProgressSlide } from './InProgressSlide';
import { SelfCheckoutSlide } from './SelfCheckoutSlide';
import { useMarkSalesContactedMutation } from '../../../../hooks/mutations/trial';
import { SwipeableViews } from '../../../boxes/swipeable-views';

type Props = {
  onSettle?: () => void;
  onClose: () => void;
};

export const MainDialog = ({ onClose, onSettle }: Props) => {
  const [selfCheckout, setSelfCheckout] = useState(false);
  const { mutateAsync: markSalesContacted } = useMarkSalesContactedMutation();

  const handleScheduleCall = () => {
    trialEvents.selectedToScheduleCall({
      from: selfCheckout ? 'Self-checkout dialog' : 'Trial in progress dialog',
    });
    openScheduleDialog({
      onMeetingBooked: () => {
        markSalesContacted().then(() => {
          onSettle?.();
          trialEvents.callScheduled({
            from: selfCheckout
              ? 'Self-checkout dialog'
              : 'Trial in progress dialog',
          });
        });
      },
    });
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      testId="trial-in-progress-dialog"
    >
      <SwipeableViews
        slides={{
          main: (
            <InProgressSlide
              onSelfCheckout={() => {
                trialEvents.selectedSelfCheckout();
                setSelfCheckout(true);
              }}
              onScheduleCall={handleScheduleCall}
              onClose={onClose}
            />
          ),
          selfCheckout: (
            <SelfCheckoutSlide
              onBack={() => setSelfCheckout(false)}
              onScheduleCall={handleScheduleCall}
              onClose={onClose}
            />
          ),
        }}
        activeSlide={selfCheckout ? 'selfCheckout' : 'main'}
      />
    </Dialog>
  );
};

type Args = {
  onClose: () => void;
  onSettle?: () => void;
};

export const useTrialInProgressDialog = () => {
  const { showModal } = useModal();

  const modalInstance = useRef<ShowModalOutput>();

  const openModal = (props?: Args) => {
    modalInstance.current = showModal(MainDialog, {
      onClose: () => {
        modalInstance.current?.destroy();
        props?.onClose?.();
      },
      onSettle: () => {
        modalInstance.current?.destroy();
        props?.onSettle?.();
      },
    });
  };

  return { openTrialInProgressDialog: openModal };
};
