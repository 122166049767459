import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Organization } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

type UseCreateOrganizationMutation = UseMutationResult<
  unknown,
  unknown,
  Organization
>;

export const useCreateOrganizationMutation = (
  onSuccess?: (organization: Organization) => void
): UseCreateOrganizationMutation => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) =>
      apiClient.user.createOrganization({ organization: payload }),
    onSuccess: async ({ data }) => {
      await queryClient.refetchQueries({ queryKey: [QueryKeys.ORGANIZATIONS] });
      onSuccess &&
        onSuccess({
          id: data.id!,
          name: data.name,
          createdAt: data.createdAt!,
        });
    },
    onError: (error: unknown) => {
      notify.error(parseApiError(error));
    },
  });
};
