import { cloneElement, ReactElement, ReactNode, SyntheticEvent } from 'react';

import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import { Button, Dialog } from '@cast/design-system';

export type FeedbackDialogProps = {
  icon: ReactElement;
  title: ReactNode;
  body?: ReactNode;
  actions?: ReactNode;
  destroy: (event?: SyntheticEvent) => void;
  size?: number;
  contentSx?: SxProps;
  bodySx?: SxProps;
  testId?: string;
};

export const FeedbackDialog = ({
  size = 96,
  testId,
  ...props
}: FeedbackDialogProps) => {
  const iconEl = cloneElement(props.icon, { size });

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="sm"
      onClose={props.destroy}
      PaperProps={{ sx: { overflow: 'visible' } }}
      testId={testId}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: -1 * (size / 2),
          left: 0,
          width: '100%',
        }}
      >
        {iconEl}
      </Stack>
      <Stack py={56} pb={24} px={24} alignItems="center" sx={props.contentSx}>
        <Typography variant="h4" textAlign="center">
          {props.title}
        </Typography>
        <Typography
          variant="P12R"
          color="grey.500"
          mt={8}
          textAlign="center"
          sx={props.bodySx}
        >
          {props.body}
        </Typography>
      </Stack>
      {props.actions || (
        <Stack pb={24} alignItems="center" justifyContent="center">
          <Button onClick={props.destroy} variant="tertiary">
            Close
          </Button>
        </Stack>
      )}
    </Dialog>
  );
};
