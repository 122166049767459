import { ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  subTitle: string;
  children: ReactNode;
}

export const ContactFormSlide = ({ title, subTitle, children }: Props) => {
  return (
    <Stack
      direction="column"
      gap="8px"
      sx={{
        padding: '0px 80px',
        maxWidth: '552px',
        placeContent: 'stretch space-between',
        alignItems: 'stretch',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Soehne Breit Kraftig',
          fontWeight: '500',
          fontSize: '2rem',
          lineHeight: '1.25',
          marginBlockEnd: '1.5rem',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Soehne Buch',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.375',
          color: '#525866',
          marginBlockEnd: '1.5rem',
        }}
      >
        {subTitle}
      </Typography>
      {children}
    </Stack>
  );
};
