import {
  SecurityInsightsPackage,
  SecurityInsightsContainerImageVulnerability,
  SecurityInsightsContainerImagePackage,
  K8sProvider,
  Node,
  Anomaly,
  Cluster,
} from '@cast/types';

import { ClusterWithSchedules } from 'features/scheduled-rebalancing/types';
import { OrganizationMember } from 'types/organization';

export type VulnerabilityStatus = 'active' | 'excepted';

export type VulnerabilityPackage = SecurityInsightsPackage & {
  vulnerabilityStatus?: VulnerabilityStatus;
};

export type ImageVulnerability = Omit<
  SecurityInsightsContainerImageVulnerability,
  'package'
> & {
  vulnerabilityStatus?: VulnerabilityStatus;
  package: VulnerabilityPackage;
};

export type ImagePackage = SecurityInsightsContainerImagePackage & {
  vulnerabilityStatus: VulnerabilityStatus;
};
export enum ImageVulnerabilitiesTabEnum {
  VULNERABILITIES = 'vulnerabilities',
  PACKAGES = 'packages',
  BASE_IMAGE_RECOMMENDATION = 'base-image-recommendation',
}

export enum UpdatesStatus {
  ON = 'on',
  OFF = 'off',
  UNAVAILABLE = 'unavailable',
  UNKNOWN = 'unknown',
}

export enum AgeGroup {
  MORE_THAN_MONTH = 'old',
  UP_TO_MONTH = 'month',
  UP_TO_WEEK = 'week',
}

export type PreparedNode = {
  id: string;
  name: string;
  cluster: ClusterWithSchedules;
  created: string;
  os: string;
  osVersion: string;
  managedBy: string;
  provider: K8sProvider;
  updatesStatus: UpdatesStatus;
  ageGroup: AgeGroup;
  timeAgo: {
    formattedDate: string;
    distance: string;
  };
  orig: Node;
};

export type EnhancedAnomaly = Anomaly & {
  cluster?: Cluster;
  user?: OrganizationMember;
};
