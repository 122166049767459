import { K8sProvider, RebalancingPlanStatusEnum } from '@cast/types';

export const PROVIDER_NAME_MAP: Record<`${K8sProvider}`, string> = {
  [K8sProvider.EKS]: 'EKS',
  [K8sProvider.GKE]: 'GKE',
  [K8sProvider.AKS]: 'AKS',
  [K8sProvider.KOPS]: 'kOps',
  [K8sProvider.ROSA]: 'OpenShift',
  [K8sProvider.ANYWHERE]: 'Other',
};

export const GENERATED_REBALANCING_STATES: RebalancingPlanStatusEnum[] = [
  RebalancingPlanStatusEnum.generated,
  RebalancingPlanStatusEnum.generating,
];

export const REBALANCING_IN_PROGRESS_STATES: RebalancingPlanStatusEnum[] = [
  RebalancingPlanStatusEnum.creating_nodes,
  RebalancingPlanStatusEnum.draining_nodes,
  RebalancingPlanStatusEnum.preparing_nodes,
  RebalancingPlanStatusEnum.deleting_nodes,
];

export const ACTIVE_REBALANCING_STATES = [
  ...GENERATED_REBALANCING_STATES,
  ...REBALANCING_IN_PROGRESS_STATES,
];
