import { useRef, useState } from 'react';

import { useModal } from 'mui-modal-provider';

import { Dialog } from '@cast/design-system';

import { trialEvents } from 'core/analytics';
import { ShowModalOutput } from 'types/modal';

import { ConfirmationSlide } from './ConfirmationSlide';
import { MainSlide } from './MainSlide';
import { openScheduleDialog } from '../../../../core/integrations';
import {
  useMarkSalesContactedMutation,
  useMarkDowngradedMutation,
} from '../../../../hooks/mutations/trial';
import { SwipeableViews } from '../../../boxes/swipeable-views';

type Props = {
  onClose: () => void;
};

const MainDialog = ({ onClose }: Props) => {
  const [downgrade, setDowngrade] = useState(false);
  const { mutateAsync: markSalesContacted } = useMarkSalesContactedMutation();
  const { mutateAsync: markDowngraded } = useMarkDowngradedMutation();

  const handleScheduleCall = () => {
    trialEvents.selectedToScheduleCall({ from: 'Trial ended dialog' });
    openScheduleDialog({
      onMeetingBooked: () => {
        markSalesContacted().then(() => {
          onClose();
          trialEvents.callScheduled({ from: 'Trial ended dialog' });
        });
      },
    });
  };

  const handleDowngrade = () => {
    markDowngraded().then(() => {
      trialEvents.confirmedToDowngrade();
      onClose();
    });
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      testId="trial-has-ended-dialog"
    >
      <SwipeableViews
        slides={{
          main: (
            <MainSlide
              onDowngrade={() => {
                trialEvents.selectedToDowngrade();
                setDowngrade(true);
              }}
              onScheduleCall={handleScheduleCall}
              onClose={onClose}
            />
          ),
          confirmation: (
            <ConfirmationSlide
              onBack={() => setDowngrade(false)}
              onConfirm={handleDowngrade}
              onClose={onClose}
            />
          ),
        }}
        activeSlide={downgrade ? 'confirmation' : 'main'}
      />
    </Dialog>
  );
};

type Args = {
  onClose?: () => void;
};

export const useTrialHasEndedDialog = () => {
  const { showModal } = useModal();

  const modalInstance = useRef<ShowModalOutput>();

  const openModal = (props?: Args) => {
    modalInstance.current = showModal(MainDialog, {
      onClose: () => {
        modalInstance.current?.destroy();
        props?.onClose?.();
      },
    });
  };

  return { openTrialHasEndedDialog: openModal };
};
