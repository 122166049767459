import { PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import pluralizeFn from 'pluralize';

export type TableRecordsCountProps = PropsWithChildren<{
  title?: string;
  count?: number;
  outOf?: number;
  postfix?: string;
  visible?: boolean;
  pluralize?: boolean;
  sx?: SxProps;
  showZero?: boolean;
}>;

export const TableRecordsCount = ({
  count,
  outOf,
  title = 'entry',
  postfix,
  pluralize = true,
  showZero = false,
  sx,
  children,
}: TableRecordsCountProps) => {
  const renderCount = () => {
    if (count === 0) {
      return showZero ? '0/' : undefined;
    }

    if (!count) {
      return undefined;
    }
    if (outOf === undefined) {
      return count;
    }
    return `${count}/`;
  };

  return (
    <Box className="RecordsCount-root" mr={8} sx={sx}>
      <Typography
        variant="A1"
        color="textSecondary"
        data-testid="records-count"
      >
        {children ?? (
          <>
            {renderCount()}
            {outOf} {pluralize ? pluralizeFn(title, outOf ?? 0) : title}
            {postfix ? ` ${postfix}` : ''}
          </>
        )}
      </Typography>
    </Box>
  );
};
