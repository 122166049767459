import { useRef } from 'react';

import { useModal } from 'mui-modal-provider';

import { Dialog, DialogTitle } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { CloseDialogButton } from 'components/dialogs';
import { K8sProviderIcon } from 'components/icons';
import { useCastAnywhereClusterPricingQuery } from 'hooks/queries/pricing';
import { ShowModalOutput } from 'types/modal';

import { SetupCastAnywhere } from './_components/SetupCastAnywhere';

type Props = {
  cluster: Cluster;
};

type FullProps = {
  destroy: () => void;
} & Props;

const AdjustClusterCostsDialog = ({ destroy, cluster }: FullProps) => {
  const { clusterPricing } = useCastAnywhereClusterPricingQuery({
    organizationId: cluster.organizationId,
    clusterId: cluster.id,
  });

  return (
    <Dialog onClose={destroy} open disableCloseOnBackdropClick>
      <CloseDialogButton />
      <DialogTitle variant="A1" display="flex" alignItems="center" gap={2}>
        <K8sProviderIcon size={24} provider={cluster.providerType} />
        {cluster.name}
      </DialogTitle>
      <SetupCastAnywhere
        cluster={cluster}
        pricing={clusterPricing}
        showCancel
      />
    </Dialog>
  );
};

export const useAdjustClusterCostsDialog = () => {
  const modalRef = useRef<ShowModalOutput>();
  const { showModal } = useModal();

  const openAdjustClusterCostsDialog = (props: Props) => {
    modalRef.current = showModal(AdjustClusterCostsDialog, {
      destroy: () => modalRef.current?.destroy(),
      ...props,
    });
  };

  return { openAdjustClusterCostsDialog };
};
