import difference from 'lodash/difference';

import { FeatureFlagResolved } from '@cast/types';

import { AccessControlData } from 'core/access-control';

import { resolveActionFlags } from './resolve-action-flags';
import { ABILITY_CONFIG } from '../config';
import { Actions, ConsoleRules } from '../types';

export const ruleBuilder = (
  subjectId: string,
  accessData: AccessControlData[string],
  flags: FeatureFlagResolved[]
): ConsoleRules[] => {
  const rules: ConsoleRules[] = [];

  const subjects = Object.keys(
    ABILITY_CONFIG
  ) as (keyof typeof ABILITY_CONFIG)[];
  const organizationIds = Object.keys(accessData.organizations);
  const clusterIds = Object.keys(accessData.clusters);

  subjects.forEach((subject) => {
    const subjectActions = Object.keys(ABILITY_CONFIG[subject]) as Actions[];
    subjectActions.forEach((action) => {
      const featureFlags = ABILITY_CONFIG[subject][action]?.flags ?? [];
      const isFeatureToggled = Array.isArray(featureFlags)
        ? featureFlags?.length
        : featureFlags?.flags?.length;

      const isEnabled = isFeatureToggled
        ? resolveActionFlags(featureFlags ?? [], flags)
        : true;

      const requiredPermissions =
        ABILITY_CONFIG[subject][action]?.permissions ?? [];

      organizationIds.forEach((organizationId) => {
        if (!isEnabled) {
          rules.push({
            action,
            subject,
            inverted: true,
            conditions: {
              subjectId,
              organizationId,
            },
            reason: `Feature ${subject} is not enabled`,
          });
          return;
        }

        const organizationAccess = accessData.organizations[organizationId];
        const organizationLevelAccess = organizationAccess.hasAccessTo ?? [];

        const hasPermissions = requiredPermissions.length
          ? requiredPermissions.every((permission) =>
              organizationLevelAccess.includes(permission)
            )
          : true;

        rules.push({
          action,
          subject,
          inverted: !hasPermissions,
          conditions: {
            subjectId,
            organizationId,
          },
          reason: !hasPermissions
            ? `Missing permissions for ${difference(
                requiredPermissions,
                organizationLevelAccess
              ).join(', ')}`
            : undefined,
        });
      });

      if (isEnabled) {
        clusterIds.forEach((clusterId) => {
          const clusterAccess = accessData.clusters[clusterId];
          const clusterLevelAccess = clusterAccess.hasAccessTo ?? [];

          const hasPermissions = requiredPermissions.length
            ? requiredPermissions.every((permission) =>
                clusterLevelAccess.includes(permission)
              )
            : true;

          rules.push({
            action,
            subject,
            inverted: !hasPermissions,
            conditions: {
              subjectId,
              clusterId,
            },
            reason: !hasPermissions
              ? `Missing permissions for ${difference(
                  requiredPermissions,
                  clusterLevelAccess
                ).join(', ')}`
              : undefined,
          });
        });
      }
    });
  });

  return rules;
};
