import { Stack, Typography } from '@mui/material';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@cast/design-system';

import { CloseDialogButton } from '../../../dialogs';

type Props = {
  onBack: () => void;
  onScheduleCall: () => void;
  onClose: () => void;
};

export const SelfCheckoutSlide = ({
  onBack,
  onScheduleCall,
  onClose,
}: Props) => {
  return (
    <Stack data-testid="self-checkout-slide" position="relative">
      <DialogTitle sx={{ padding: '24px 32px' }}>
        <Typography variant="h5">Self-checkout is coming soon</Typography>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <Stack gap="18px">
          <Typography variant="P12R" color="textSecondary">
            Self-checkout will be available soon.
          </Typography>

          <Typography variant="P12R" color="textSecondary">
            In the meantime, our team would love to create a custom package
            tailored to your needs and infrastructure requirements.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px 32px',
          borderTop: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Stack direction="row" gap="8px">
          <Button data-testid="back-button" variant="tertiary" onClick={onBack}>
            Back
          </Button>

          <Button
            data-testid="schedule-sales-call-button"
            variant="primary"
            onClick={onScheduleCall}
          >
            Talk to us to upgrade
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  );
};
