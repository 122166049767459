import { Box, Typography } from '@mui/material';

import { Icons, Tab, Tooltip } from '@cast/design-system';

type ComingSoonTabProps = {
  label: string;
};

export const ComingSoonTab = ({ label }: ComingSoonTabProps) => {
  return (
    <Tooltip
      title={
        <Box display="flex" alignItems="center" fontSize={12} gap={4}>
          <Icons.ClockClockwise />
          <Typography variant="P12R">Coming soon</Typography>
        </Box>
      }
      color="tertiary"
      placement="top"
      arrow
      disableInteractive
    >
      <span>
        <Tab label={label} disabled />
      </span>
    </Tooltip>
  );
};
