import { useQuery, useQueryClient } from '@tanstack/react-query';
import uniq from 'lodash/uniq';

import { demoClusterDetails } from '@cast/fixtures';
import { Cluster, ClusterDisplayStatus, ClusterResponse } from '@cast/types';
import { removeDemoMode, setDemoMode } from '@cast/utils';

import {
  AFFILIATE_ID_COOKIE_KEY,
  IS_PROD,
  VISIT_ID_COOKIE_KEY,
} from 'common/constants';
import { useAbility } from 'core/ability/hooks/useAbility';
import { userId } from 'core/auth';
import { apiClient, QueryKeys } from 'core/react-query';
import { useOrganizations } from 'hooks/useOrganizations';
import { enhanceCluster } from 'utils/api-utils';
import { isClusterOnboardingPhase1 } from 'utils/cluster';
import { getCookie } from 'utils/cookie';
import { readStorage, writeStorage } from 'utils/storage';

import { useReferralMutation } from '../../mutations/marketing';

export const useDemoClusters = () => {
  const { currentOrganization: organization } = useOrganizations();

  return useQuery({
    queryKey: ['demoCluster', organization?.id],
    queryFn: async () => {
      const demoCluster = await demoClusterDetails(organization!.id);
      return demoCluster ? [enhanceCluster(demoCluster)] : [];
    },
    enabled: !!organization,
  });
};

const CONNECTING_CLUSTERS_STORAGE = 'connecting-clusters-before';
const CONNECTED_CLUSTERS_STORAGE = 'connected-clusters-before';

export type UseClustersQueryArgs = {
  polling?: boolean;
  enabled?: boolean;
};

export const useClustersQuery = ({
  polling,
  enabled = true,
}: UseClustersQueryArgs = {}) => {
  const ability = useAbility();
  const { currentOrganization: organization } = useOrganizations();
  const queryClient = useQueryClient();
  const { mutate } = useReferralMutation();
  const { data: demoClusters } = useDemoClusters();

  const {
    data: clusters,
    error,
    refetch: refetchClusters,
    isLoading,
  } = useQuery({
    // using select doesn't affect cached data
    select: (data) => {
      const clustersWithPermissions = data?.filter((c: Cluster) =>
        ability.can('view', 'Clusters', { clusterId: c.id })
      );

      if (!clustersWithPermissions?.length) {
        setDemoMode(organization?.id);
        return demoClusters;
      } else {
        removeDemoMode();
        return clustersWithPermissions;
      }
    },
    queryKey: [QueryKeys.EXTERNAL_CLUSTERS],
    queryFn: async () => {
      return await apiClient.clusters.getClusters().then(({ data }) => {
        const enhancedList = data.items?.map((cluster) => {
          const enhancedData = enhanceCluster(cluster as ClusterResponse);

          queryClient.setQueryData(
            [QueryKeys.EXTERNAL_CLUSTER, enhancedData.id],
            enhancedData
          );

          return enhancedData;
        });

        // TODO: delete all of this once backend starts communicating with refer portal
        try {
          if (enhancedList) {
            const connectedBefore: string[] = readStorage(
              CONNECTED_CLUSTERS_STORAGE,
              []
            );
            const connectingBefore: string[] = readStorage(
              CONNECTING_CLUSTERS_STORAGE,
              []
            );
            const affiliate_id = getCookie(AFFILIATE_ID_COOKIE_KEY);
            const visit_id = getCookie(VISIT_ID_COOKIE_KEY);
            const trackConnection =
              IS_PROD && Boolean(affiliate_id && visit_id);

            const connectingClusters = enhancedList
              .filter((cluster) =>
                isClusterOnboardingPhase1(
                  cluster.displayStatus,
                  Boolean(cluster.credentialsId)
                )
              )
              .map((c) => c.id);

            const connectedClusters = enhancedList
              .filter(
                (cluster) =>
                  cluster.displayStatus === ClusterDisplayStatus.READ_ONLY
              )
              .map((c) => c.id);

            connectedClusters.forEach((id) => {
              // send event only if cookies exist and new connection was found (reconnection does not count)
              if (
                trackConnection &&
                connectingBefore.includes(id) &&
                !connectedBefore.includes(id)
              ) {
                queryClient.invalidateQueries({
                  queryKey: [QueryKeys.ORGANIZATION_CLUSTERS_SUMMARY],
                });
                mutate({
                  event: 'cluster-connect',
                  visit_id: parseInt(visit_id!),
                  affiliate_id: parseInt(affiliate_id!),
                  reference: `${userId()}/${id}`,
                });
              }
            });
            writeStorage(CONNECTING_CLUSTERS_STORAGE, connectingClusters);
            writeStorage(
              CONNECTED_CLUSTERS_STORAGE,
              uniq([...connectedClusters, ...connectedBefore])
            );
          }
        } catch (e) {
          console.error(e);
        }

        return enhancedList;
      });
    },
    enabled: !ability.isLoading && enabled,
    refetchInterval: polling ? 20_000 : false,
  });

  return { clusters, error, isLoading, refetchClusters };
};
