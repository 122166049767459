import { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@cast/design-system';

import { EXTERNAL_LINKS } from '../../../../common/links';
import { AccountType } from '../../../../types/accounts';
import { CloseDialogButton } from '../../../dialogs';
import { ExternalLink } from '../../../router/ExternalLink';
import { SelectAccountTypeRadio } from '../../_components/SelectAccountType';

type Props = {
  onDowngrade: () => void;
  onScheduleCall: () => void;
  onClose: () => void;
};

export const MainSlide = ({ onDowngrade, onScheduleCall, onClose }: Props) => {
  const [accountType, setAccountType] = useState<AccountType>('paid');

  return (
    <Stack data-testid="main-slide" position="relative">
      <DialogTitle sx={{ padding: '24px 32px' }}>
        <Typography variant="h5">Your trial has ended</Typography>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <Stack gap="24px">
          <Typography variant="P12R" color="textSecondary">
            Your trial period has concluded, but your premium Cast AI experience
            doesn&apos;t have to stop here. Take the next step to decide how
            you&apos;d like to continue benefiting from our platform.
          </Typography>

          <Stack display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="8px">
            <SelectAccountTypeRadio
              type="free"
              checked={accountType === 'free'}
              onClick={() => setAccountType('free')}
            />
            <SelectAccountTypeRadio
              type="paid"
              checked={accountType === 'paid'}
              onClick={() => setAccountType('paid')}
            />
          </Stack>

          <Stack gap="4px">
            <Typography variant="h6">
              What will happen to my clusters after the trial if I do nothing?
            </Typography>

            <Typography variant="P12R" color="textSecondary">
              Your clusters and workloads will remain intact and fully
              operational. However, access to premium features will be removed.
              An automated downgrading process will transition your account to
              the free version, ensuring your infrastructure is preserved. For
              more details, see our{' '}
              <ExternalLink link={EXTERNAL_LINKS.docs_trial} noIcon>
                documentation
              </ExternalLink>
              .
            </Typography>
          </Stack>

          <Stack gap="4px">
            <Typography variant="h6">Our pricing</Typography>

            <Typography variant="P12R" color="textSecondary">
              Discover a plan that fits your needs. Visit our{' '}
              <ExternalLink link={EXTERNAL_LINKS.castai_pricing} noIcon>
                pricing page
              </ExternalLink>{' '}
              for more information about options and features.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: '24px 32px',
          borderTop: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        {accountType === 'free' && (
          <Button
            data-testid="downgrade-button"
            variant="tertiary"
            onClick={onDowngrade}
          >
            Downgrade
          </Button>
        )}

        {accountType === 'paid' && (
          <Button
            data-testid="schedule-sales-call-button"
            variant="primary"
            onClick={onScheduleCall}
          >
            Talk to us to upgrade
          </Button>
        )}
      </DialogActions>
    </Stack>
  );
};
