import { IS_PROD_US } from 'common/constants';
import { useAuth } from 'core/auth';
import { useHubspotContact } from 'core/hubspot';
import { useTrial } from 'core/trial';
import {
  useCreateFullHubspotContactMutation,
  useCreateHubspotContactMutation,
} from 'hooks/mutations/marketing';
import { usePersistentState } from 'hooks/usePersistentState';

export const useContactsForm = () => {
  const { user } = useAuth();
  const { isEligible } = useTrial();
  const { contactState } = useHubspotContact();
  const [showForm, setShowForm] = usePersistentState(
    `phone-form-${user?.id}`,
    IS_PROD_US && user?.firstLogin && contactState === 'pending'
  );

  const { success } = useCreateHubspotContactMutation({
    pageName: 'Signup form - initial',
  });

  const { mutate: submitContactsForm } = useCreateFullHubspotContactMutation({
    pageName: 'Signup form - full',
    enabled: success && IS_PROD_US,
  });

  const closeContactsForm = () => {
    setShowForm(false);
  };

  return {
    submitContactsForm,
    showForm:
      showForm && success && contactState === 'pending' && isEligible !== true,
    closeContactsForm,
  };
};
