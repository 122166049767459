import { PropsWithChildren, createContext, useContext } from 'react';

import dayjs from 'dayjs';

import { useTrialStatusQuery } from 'hooks/queries/trial';

import { _CASTAI_TRIAL } from './trialStatus';
import { CastTrialState, TrialState } from './types';

export const TrialContext = createContext<CastTrialState>(undefined as never);

export const TrialProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading, error } = useTrialStatusQuery();

  const isEligible = data?.eligibleForTrial ?? false;
  const hasContactedSales = data?.salesContacted ?? false;
  const hasDowngraded = data?.downgraded ?? false;
  const expiresInDays = dayjs(data?.trialEndDate).diff(dayjs(), 'days');
  const isAboutToExpire = expiresInDays <= 7;

  let state: TrialState = 'pre-trial';

  if (
    data?.trialActive &&
    dayjs().isBetween(data.trialStartDate, data.trialEndDate, 'hour', '[]')
  ) {
    state = 'active';
  }

  if (data?.trialEndDate && dayjs().isAfter(data.trialEndDate, 'hour')) {
    state = 'post-trial';
  }

  _CASTAI_TRIAL['isEligible'] = isEligible;
  _CASTAI_TRIAL['hasDowngraded'] = hasDowngraded;
  _CASTAI_TRIAL['state'] = state;
  _CASTAI_TRIAL['hasContactedSales'] = hasContactedSales;
  _CASTAI_TRIAL['expiresInDays'] = expiresInDays;
  _CASTAI_TRIAL['isAboutToExpire'] = isAboutToExpire;

  return (
    <TrialContext.Provider
      value={{
        isLoading,
        state,
        expiresInDays,
        isAboutToExpire,
        isEligible,
        hasContactedSales,
        hasDowngraded,
        hasError: !!error,
      }}
    >
      {children}
    </TrialContext.Provider>
  );
};

export const useTrial = () => {
  return useContext(TrialContext);
};
