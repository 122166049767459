import { FeatureFlagResolved, OrganizationRole } from '@cast/types';

import { resolveActionFlags } from './resolve-action-flags';
import { ABILITY_CONFIG } from '../config';
import { ABILITY_CONFIG_V1 } from '../config.v1';
import { Actions, ConsoleRules } from '../types';

export const ruleBuilder = (
  subjectId: string,
  organizationId: string,
  role: OrganizationRole,
  flags: FeatureFlagResolved[]
): ConsoleRules[] => {
  const rules: ConsoleRules[] = [];

  const subjects = Object.keys(
    ABILITY_CONFIG
  ) as (keyof typeof ABILITY_CONFIG)[];

  subjects.forEach((subject) => {
    const subjectActions = Object.keys(ABILITY_CONFIG[subject]) as Actions[];
    subjectActions.forEach((action) => {
      const featureFlags = ABILITY_CONFIG_V1[role][subject][action]?.flags;
      const isFeatureToggled = Array.isArray(featureFlags)
        ? featureFlags?.length
        : featureFlags?.flags?.length;

      const isEnabled = isFeatureToggled
        ? resolveActionFlags(featureFlags ?? [], flags)
        : true;

      if (!isEnabled) {
        rules.push({
          action,
          subject,
          inverted: true,
          conditions: {
            subjectId,
            organizationId,
          },
          reason: `Feature ${subject} is not enabled`,
        });
        return;
      }

      const hasPermissions =
        ABILITY_CONFIG_V1[role][subject][action]?.permissions ?? true;

      rules.push({
        action,
        subject,
        inverted: !hasPermissions,
        conditions: {
          subjectId,
          organizationId,
        },
        reason: !hasPermissions
          ? `User don't have permissions to ${action} ${subject}`
          : undefined,
      });
    });
  });

  return rules;
};
