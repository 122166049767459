import { useEffect } from 'react';

import { useTrial } from './TrialProvider';
import { useTrialHasEndedDialog } from '../../components/trial/dialogs/useTrialHasEndedDialog';
import { useTrialInProgressDialog } from '../../components/trial/dialogs/useTrialInProgressDialog';
import { usePersistentState } from '../../hooks/usePersistentState';

export const TrialNudgeUserManager = () => {
  const [trialNudge15Days, setTrialNudge15Days] = usePersistentState<boolean>(
    'PLG-nudge-15-days',
    false
  );
  const [trialNudge7Days, setTrialNudge7Days] = usePersistentState<boolean>(
    'PLG-nudge-7-days',
    false
  );
  const [trialNudge3Days, setTrialNudge3Days] = usePersistentState<boolean>(
    'PLG-nudge-3-days',
    false
  );
  const [trialNudgeExpired, setTrialNudgeExpired] = usePersistentState<boolean>(
    'PLG-nudge-expired',
    false
  );

  const { openTrialInProgressDialog } = useTrialInProgressDialog();
  const { openTrialHasEndedDialog } = useTrialHasEndedDialog();
  const { isEligible, state, expiresInDays, hasDowngraded } = useTrial();

  useEffect(() => {
    if (
      isEligible &&
      state === 'post-trial' &&
      !hasDowngraded &&
      !trialNudgeExpired
    ) {
      const markNudgeAsSeen = () => {
        setTrialNudgeExpired(true);
        setTrialNudge3Days(true);
        setTrialNudge7Days(true);
        setTrialNudge15Days(true);
      };

      openTrialHasEndedDialog({
        onClose: markNudgeAsSeen,
      });
    }

    if (isEligible && state === 'active') {
      if (expiresInDays <= 3 && !trialNudge3Days) {
        const markNudgeAsSeen = () => {
          setTrialNudge3Days(true);
          setTrialNudge7Days(true);
          setTrialNudge15Days(true);
        };

        openTrialInProgressDialog({
          onClose: markNudgeAsSeen,
          onSettle: markNudgeAsSeen,
        });

        return;
      }

      if (expiresInDays <= 7 && !trialNudge7Days) {
        const markNudgeAsSeen = () => {
          setTrialNudge7Days(true);
          setTrialNudge15Days(true);
        };

        openTrialInProgressDialog({
          onClose: markNudgeAsSeen,
          onSettle: markNudgeAsSeen,
        });

        return;
      }

      if (expiresInDays <= 15 && !trialNudge15Days) {
        const markNudgeAsSeen = () => {
          setTrialNudge15Days(true);
        };

        openTrialInProgressDialog({
          onClose: markNudgeAsSeen,
          onSettle: markNudgeAsSeen,
        });

        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
