import { useRef, useState } from 'react';

import { useModal } from 'mui-modal-provider';

import { Dialog } from '@cast/design-system';

import { trialEvents } from 'core/analytics';
import { ShowModalOutput } from 'types/modal';

import { InitializeTrialSlide } from './InitializeTrailSlide';
import { useStartTrialMutation } from '../../../../hooks/mutations/trial';
import { SwipeableViews } from '../../../boxes/swipeable-views';
import { FeedbackMessageSlide } from '../../_components/FeedbackMessageSlide';

type Props = {
  onSettle?: () => void;
  onClose: () => void;
};

export const MainDialog = (props: Props) => {
  const [isDone, setDone] = useState(false);
  const { isSuccess, mutateAsync: startTrial } = useStartTrialMutation();

  const handleConfirm = () => {
    startTrial().then(() => {
      trialEvents.trialStarted({ from: 'Initialize trial dialog' });
      setDone(true);
    });
  };

  const handleContinue = () => {
    trialEvents.freeVersionSelected();
    props.onSettle?.();
  };

  const handleClose = () => {
    setDone(false);
    props.onClose?.();
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={props?.onClose}
      onClick={(e) => e.stopPropagation()}
      testId="initialize-trial-dialog"
    >
      <SwipeableViews
        slides={{
          main: (
            <InitializeTrialSlide
              onContinue={handleContinue}
              onConfirm={handleConfirm}
              onClose={handleClose}
            />
          ),
          feedback: (
            <FeedbackMessageSlide
              success={isSuccess}
              onClose={handleClose}
              onContinue={isSuccess ? () => props.onSettle?.() : handleClose}
            />
          ),
        }}
        activeSlide={isDone ? 'feedback' : 'main'}
      />
    </Dialog>
  );
};

type Args = {
  onSettle?: () => void;
};

export const useInitializeTrialDialog = () => {
  const { showModal } = useModal();

  const modalInstance = useRef<ShowModalOutput>();

  const openModal = (props?: Args) => {
    modalInstance.current = showModal(MainDialog, {
      onSettle: () => {
        modalInstance.current?.destroy();
        props?.onSettle?.();
      },
      onClose: () => {
        modalInstance.current?.destroy();
      },
    });
  };

  return { openInitializeTrialDialog: openModal };
};
