import { SxProps, Theme, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import { mergeSx } from '@cast/design-system';

type Props = {
  text?: string;
  sx?: SxProps<Theme>;
};

export const DividerWithText = ({ text, sx }: Props) => {
  return (
    <Divider
      textAlign="left"
      sx={mergeSx(
        {
          borderColor: 'green.400',
          '&::before': { width: '52px' },
          '&::before, &:after': { borderColor: 'inherit' },
        },
        sx
      )}
    >
      {!!text && (
        <Typography
          variant="L10M"
          color="green.500"
          sx={{
            background: 'inherit',
            padding: '0 4px',
          }}
        >
          {text}
        </Typography>
      )}
    </Divider>
  );
};
