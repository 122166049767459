import { K8sProvider } from '@cast/types';

import { ReactComponent as AKSIcon } from 'assets/icons/aks.svg';
import { ReactComponent as AnywhereIcon } from 'assets/icons/anywhere.svg';
import { ReactComponent as EKSIcon } from 'assets/icons/eks.svg';
import { ReactComponent as GKEIcon } from 'assets/icons/gke.svg';
import { ReactComponent as KopsIcon } from 'assets/icons/kops.svg';
import { ReactComponent as KubernetesIcon } from 'assets/icons/kubernetes-logo.svg';
import { ReactComponent as ROSAIcon } from 'assets/icons/rosa.svg';

type Props = {
  provider?: `${K8sProvider}`;
  size: number | string;
  className?: string;
};

const clusterIconMapper: Record<`${K8sProvider}`, typeof AKSIcon> = {
  gke: GKEIcon,
  eks: EKSIcon,
  aks: AKSIcon,
  kops: KopsIcon,
  openshift: ROSAIcon,
  anywhere: AnywhereIcon,
};

export const K8sProviderIcon = ({ provider, size, className }: Props) => {
  const ClusterIcon =
    (provider && clusterIconMapper[provider]) ?? KubernetesIcon;

  return (
    <ClusterIcon
      height={size}
      width={size}
      className={className}
      data-provider={provider}
    />
  );
};
