import * as Yup from 'yup';

const firstNameSchema = Yup.string()
  .required('Required field')
  .label('First name');
const lastNameSchema = Yup.string()
  .required('Required field')
  .label('Last name');

export const contactsFormFirstStepSchema = Yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
});

export const contactsFormSchema = Yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  company: Yup.string().required('Required field').label('Company name'),
  jobTitle: Yup.string().required('Required field').label('Job title'),
  countryCode: Yup.object()
    .nullable()
    .test('code selected', 'Required field', (value) => !!value)
    .shape({
      code: Yup.string(),
      label: Yup.string(),
      phone: Yup.string(),
    }),
  phone: Yup.lazy((value, context) => {
    const countryCode = context.parent.countryCode?.code;
    return Yup.string().phone(countryCode, 'Invalid phone number');
  }),
});
