import { Stack } from '@mui/material';
import { useMatch } from 'react-router-dom';

import { Icons, Scroller } from '@cast/design-system';
import { K8sProvider } from '@cast/types';
import { isDemoMode, usePath } from '@cast/utils';

import { SUPPORTS_NODE_CONFIGURATION } from 'common/constants';
import { useAbility } from 'core/ability';
import { isDisconnectedForMonth } from 'features/cluster';
import {
  makeAllocationGroupsCostMonitoringLink,
  makeClusterCostMonitoringLink,
  makeCostComparisonLink,
  makeNamespacesCostMonitoringLink,
  makeWorkloadsCostMonitoringLink,
} from 'features/cluster/cost-report';
import { useIsPhase1WoopEnabled } from 'features/cluster/workload-optimization/hooks/useIsPhase1WoopEnabled';
import { useClusters } from 'hooks/useClusters';
import { isClusterManaged } from 'utils/cluster';

import { DividerWithText } from './DividerWithText';
import { SidebarItem } from './SidebarItem';
import { SwitchClustersPicker } from './SwitchClustersPicker';
import { useTrial } from '../../../core/trial/TrialProvider';
import { useMainLayout } from '../../hooks';
import { useAutoscalerSidebarItemProps } from '../_hooks/useAutoscalerSidebarItemProps';
import { useRebalancingPlanSidebarItemProps } from '../_hooks/useRabalancingPlanSidebarItemProps';
import { useSidebarCluster } from '../_hooks/useSidebarCluster';

const useRouteMatch = () => useMatch('/external-clusters/:clusterId/*');

type WorkloadOptimizationItemProps = {
  clusterId?: string;
  isManaged: boolean;
};

const WorkloadOptimizationItem = ({
  isManaged,
  clusterId,
}: WorkloadOptimizationItemProps) => {
  const cluster = useSidebarCluster();
  const match = useRouteMatch();
  const isDemo = isDemoMode();
  const { isPhase1WoopEnabled } = useIsPhase1WoopEnabled(clusterId, !isManaged);

  return (
    <SidebarItem
      title="Workload autoscaler"
      icon={<Icons.SelectionAll />}
      url={`${match?.pathnameBase}/workload-optimization`}
      disabled={!isManaged && !isPhase1WoopEnabled && !isDemo}
      rules={[['view', 'ClusterWorkloadAutoscaler']]}
      rulesConditions={{ clusterId: cluster?.id }}
    >
      <SidebarItem
        title="Optimization"
        url={`${match?.pathnameBase}/workload-optimization/workloads`}
      />
      <SidebarItem
        title="Event log"
        url={`${match?.pathnameBase}/workload-optimization/events`}
      />
    </SidebarItem>
  );
};

const AutoscalerItem = ({
  nodeTemplatesDisabled,
  nodeConfigurationDisabled,
}: {
  nodeTemplatesDisabled: boolean | undefined;
  nodeConfigurationDisabled: boolean | undefined;
}) => {
  const cluster = useSidebarCluster();
  const match = useRouteMatch();
  const autoscalerSidebarItemProps = useAutoscalerSidebarItemProps();

  return (
    <SidebarItem
      title="Autoscaler"
      icon={<Icons.ToggleLeft />}
      url={`${match?.pathnameBase}/autoscaler`}
      rules={[['view', 'ClusterAutoscaler']]}
      rulesConditions={{ clusterId: cluster?.id }}
      {...autoscalerSidebarItemProps}
    >
      <SidebarItem
        title="Settings"
        url={`${match?.pathnameBase}/autoscaler/settings`}
      />

      {cluster?.providerType !== K8sProvider.ANYWHERE && (
        <>
          <SidebarItem
            title="Node templates"
            url={`${match?.pathnameBase}/autoscaler/node-selection-templates`}
            disabled={nodeTemplatesDisabled}
            tooltip={
              nodeTemplatesDisabled
                ? 'Feature is not supported for this provider'
                : undefined
            }
          />

          <SidebarItem
            title="Node configuration"
            url={`${match?.pathnameBase}/autoscaler/node-configurations`}
            disabled={nodeConfigurationDisabled}
            tooltip={
              nodeConfigurationDisabled
                ? 'Feature is not supported for this provider'
                : undefined
            }
          />
        </>
      )}

      <SidebarItem
        title="Pod mutations"
        url={`${match?.pathnameBase}/autoscaler/pod-mutations`}
        rules={[['view', 'ClusterPodMutations']]}
        rulesConditions={{ clusterId: cluster?.id }}
      />
    </SidebarItem>
  );
};

const RebalancerItem = () => {
  const cluster = useSidebarCluster();
  const rebalancingPlanSidebarItemProps = useRebalancingPlanSidebarItemProps();

  return (
    <SidebarItem
      title="Rebalancer"
      icon={<Icons.Rebalancer />}
      rules={[['view', 'ClusterRebalancer']]}
      rulesConditions={{ clusterId: cluster?.id }}
      {...rebalancingPlanSidebarItemProps}
    />
  );
};

export const ClusterLevelSidebar = () => {
  const { sidebarState } = useMainLayout();
  const match = useRouteMatch();
  const cluster = useSidebarCluster();
  const { clusters } = useClusters({
    polling: false,
    enabled: Boolean(cluster?.id),
  });

  const clusterId = cluster?.id;
  const _clusterOptions = clusters.map((cluster) => {
    return {
      cluster,
      selected: cluster.id === clusterId,
    };
  });

  const clusterCostMonitoringLink = usePath(
    () =>
      makeClusterCostMonitoringLink({
        clusterId: cluster?.id,
      }),
    { enabled: !!cluster }
  );
  const workloadsCostMonitoringLink = usePath(
    () =>
      makeWorkloadsCostMonitoringLink({
        clusterId: cluster?.id,
      }),
    { enabled: !!cluster }
  );
  const namespacesConstMonitoringLink = usePath(
    () =>
      makeNamespacesCostMonitoringLink({
        clusterId: cluster?.id,
      }),
    { enabled: !!cluster }
  );
  const allocationGroupsCostMonitoringLink = usePath(
    () =>
      makeAllocationGroupsCostMonitoringLink({
        clusterId: cluster?.id,
      }),
    { enabled: !!cluster }
  );

  const costComparisonLink = usePath(
    () => makeCostComparisonLink({ clusterId: cluster?.id }),
    { enabled: !!cluster }
  );

  const { isEligible } = useTrial();
  const isDemo = isDemoMode();
  const isManaged = isClusterManaged(cluster);
  const isDisconnected = cluster && isDisconnectedForMonth(cluster);

  const nodeTemplatesDisabled =
    cluster &&
    !isDemo &&
    !SUPPORTS_NODE_CONFIGURATION.includes(cluster.providerType);
  const nodeConfigurationDisabled =
    cluster &&
    !isDemo &&
    (!isManaged ||
      isDisconnected ||
      !SUPPORTS_NODE_CONFIGURATION.includes(cluster.providerType));
  const auditLogDisabled = !isDemo && (!isManaged || isDisconnected);

  const [canViewSavingsProgress] = useAbility().canMany(
    [['view', 'FeatureClusterCostMonitoringSavingsProgress']],
    { clusterId: cluster?.id }
  );

  if (!cluster || !sidebarState) {
    return null;
  }

  const isMinimized = sidebarState === 'minimized';
  const displayPremiumFeatures = (isEligible && !isDemo) || isDemo;

  return (
    <Stack
      display="grid"
      gridTemplateRows="max-content max-content auto"
      overflow="hidden"
    >
      <SwitchClustersPicker data={_clusterOptions} />

      <Scroller
        sx={{
          px: isMinimized ? '0px' : '8px',
          '& .os-scrollbar-vertical .os-scrollbar-track': {
            pl: '2px !important',
          },
        }}
      >
        <Stack gap="8px" height="100%">
          <SidebarItem
            title="Dashboard"
            icon={<Icons.Gauge />}
            url={`${match?.pathnameBase}/dashboard`}
          />

          <SidebarItem
            title="Cost monitoring"
            icon={<Icons.ChartBar />}
            url={`${match?.pathnameBase}/cost-report`}
            rules={[['view', 'ClusterCostMonitoring']]}
            rulesConditions={{ clusterId: cluster?.id }}
            disabled={isDisconnected}
          >
            <SidebarItem title="Cluster" url={clusterCostMonitoringLink} />

            <SidebarItem title="Workloads" url={workloadsCostMonitoringLink} />

            <SidebarItem
              title="Namespaces"
              url={namespacesConstMonitoringLink}
            />

            <SidebarItem
              title="Allocation groups"
              url={allocationGroupsCostMonitoringLink}
            />

            <SidebarItem
              title="Cost comparison"
              url={costComparisonLink}
              disabled={!isManaged && !isDemoMode()}
              tooltip={
                !isManaged && !isDemoMode()
                  ? 'Enable cost optimization'
                  : undefined
              }
              rulesConditions={{ clusterId: cluster?.id }}
            />
          </SidebarItem>

          {canViewSavingsProgress &&
          cluster?.providerType !== K8sProvider.ANYWHERE ? (
            <SidebarItem
              title="Savings"
              icon={<Icons.CurrencyDollarSimple />}
              url={`${match?.pathnameBase}/available-savings`}
            >
              <SidebarItem
                title="Available savings"
                url={`${match?.pathnameBase}/available-savings`}
                disabled={isDisconnected}
              />
              <SidebarItem
                title="Savings progress"
                url={`${match?.pathnameBase}/savings/savings-progress`}
                disabled={isDisconnected || !isManaged}
                supportsDemo={false}
              />
            </SidebarItem>
          ) : (
            <SidebarItem
              title="Available savings"
              icon={<Icons.CurrencyDollarSimple />}
              url={`${match?.pathnameBase}/available-savings`}
              disabled={isDisconnected}
            />
          )}

          <SidebarItem
            title="Node list"
            icon={<Icons.NodeIcon size={16} borderColor="currentColor" />}
            url={`${match?.pathnameBase}/nodes`}
            rules={[['view', 'ClusterNodes']]}
            rulesConditions={{ clusterId: cluster?.id }}
            disabled={isDisconnected}
          />

          {!displayPremiumFeatures && (
            <>
              <AutoscalerItem
                nodeConfigurationDisabled={nodeConfigurationDisabled}
                nodeTemplatesDisabled={nodeTemplatesDisabled}
              />
              <WorkloadOptimizationItem
                isManaged={isManaged}
                clusterId={cluster.id}
              />
              <RebalancerItem />
            </>
          )}

          {displayPremiumFeatures && (
            <>
              <DividerWithText
                text={!isMinimized ? 'Premium features' : undefined}
              />
              <AutoscalerItem
                nodeConfigurationDisabled={nodeConfigurationDisabled}
                nodeTemplatesDisabled={nodeTemplatesDisabled}
              />

              <WorkloadOptimizationItem
                isManaged={isDemo || isManaged}
                clusterId={cluster.id}
              />
              <RebalancerItem />
            </>
          )}

          <SidebarItem
            title="Audit log"
            icon={<Icons.SquareHalf />}
            url={`${match?.pathnameBase}/audit`}
            rules={[['view', 'ClusterAuditLog']]}
            rulesConditions={{ clusterId: cluster?.id }}
            disabled={auditLogDisabled}
            tooltip={auditLogDisabled ? 'Enable cost optimization' : undefined}
          />
        </Stack>
      </Scroller>
    </Stack>
  );
};
