import { AgentStatus, SecurityFeature } from '@cast/types';

import {
  useSecurityInsightsAgentQuery,
  useSingleClusterSettingsQuery,
} from 'hooks/queries/security-insights';
import { Cluster } from 'types/cluster';

import { ProductFeatures } from '../types';

type UseActiveFeaturesArgs = {
  cluster: Cluster;
  enabled: boolean;
};

export const useActiveFeatures = ({
  cluster,
  enabled,
}: UseActiveFeaturesArgs): ProductFeatures => {
  const { agent: securityAgent } = useSecurityInsightsAgentQuery({
    clusterId: cluster.id,
    enabled,
  });
  const { settings } = useSingleClusterSettingsQuery({
    clusterId: cluster.id,
    enabled: enabled && cluster.agentStatus === AgentStatus.ONLINE,
  });

  return {
    costMonitoring: true, // Cost monitoring is automatically enabled
    autoscaler: !!cluster.credentialsId,
    security: !!securityAgent?.installed,
    networkCostMetrics: !!settings?.features?.includes(SecurityFeature.NETFLOW),
    // TODO (Alex): - to be implemented as part of UI-3586
    gpuMetricsExporter: false,
  };
};
