import { ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

import { Icons, withProps } from '@cast/design-system';

import { HexIconError, HexIconSuccess } from '../hex-icons';

type Props = {
  variant: 'success' | 'error';
  title: ReactNode;
  subTitle?: ReactNode;
  body?: ReactNode;
};

export const DialogFeedbackMessage = ({
  variant,
  title,
  subTitle,
  body,
}: Props) => {
  const Icon =
    variant === 'success'
      ? withProps(HexIconSuccess, { icon: <Icons.Check /> })
      : HexIconError;

  return (
    <Stack display="grid" gridTemplateColumns="36px auto" gap={8}>
      <Stack>
        <Icon size={36} />
      </Stack>
      <Stack>
        <Typography variant="h5" mt={4} mb={6}>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="P12B" mb={16}>
            {subTitle}
          </Typography>
        )}
        {body && (
          <Typography variant="P12R" color="textSecondary">
            {body}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
