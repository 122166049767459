import {
  demoClusterNodeConfigList,
  demoClusterNodeConfigMaxPodsFormulaPresets,
  demoClusterNodeConfigSuggestions,
} from '@cast/fixtures';

import { NodeConfigTemplatesApiAdapter } from '../api-client/node-config-templates-api-adapter';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const NodeConfigTemplatesApiProxy = (
  origin: ReturnType<typeof NodeConfigTemplatesApiAdapter>
) => {
  return {
    ...origin,
    listNodeConfigTemplates: async (
      ...args: Parameters<typeof origin.listNodeConfigTemplates>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNodeConfigList();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.listNodeConfigTemplates(...args);
    },

    getNodeConfigTemplate: async (
      ...args: Parameters<typeof origin.getNodeConfigTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixtures = await demoClusterNodeConfigList();
        const fixture = fixtures.items.find((item) => item.id === args[0].id);

        return resolveDemoClusterRequest(fixture);
      }

      return origin.getNodeConfigTemplate(...args);
    },

    createNodeConfigTemplate: (
      ...args: Parameters<typeof origin.createNodeConfigTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.createNodeConfigTemplate(...args);
    },

    deleteNodeConfigTemplate: (
      ...args: Parameters<typeof origin.deleteNodeConfigTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteNodeConfigTemplate(...args);
    },

    updateNodeConfigTemplate: (
      ...args: Parameters<typeof origin.updateNodeConfigTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateNodeConfigTemplate(...args);
    },

    setDefaultNodeConfigTemplate: (
      ...args: Parameters<typeof origin.setDefaultNodeConfigTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.setDefaultNodeConfigTemplate(...args);
    },

    getNodeConfigSuggestions: async (
      ...args: Parameters<typeof origin.getNodeConfigSuggestions>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNodeConfigSuggestions();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getNodeConfigSuggestions(...args);
    },
    getMaxPodsFormulaPresets: async (
      ...args: Parameters<typeof origin.getMaxPodsFormulaPresets>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNodeConfigMaxPodsFormulaPresets();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getMaxPodsFormulaPresets(...args);
    },
  };
};
