import { useTheme } from '@mui/material';

import { Icons } from '@cast/design-system';

export const GreyCheckIcon = () => {
  const { palette } = useTheme();
  return <Icons.Check color={palette.grey[300]} weight="bold" />;
};

export const GreenCheckIcon = () => {
  const { palette } = useTheme();
  return <Icons.Check color={palette.green[500]} weight="bold" />;
};

export const RedXIcon = () => {
  const { palette } = useTheme();
  return <Icons.X color={palette.red[400]} weight="bold" />;
};
