import { forwardRef } from 'react';

import { Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { Button, DrawerProps } from '@cast/design-system';
import { SecurityInsightsContainerImage } from '@cast/types';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { ExpandableList } from 'features/organization/security-insights/_components';

import { useCancelException } from './useCancelException';
import { getImageName } from '../../../utils';

type Props = {
  images: SecurityInsightsContainerImage[];
  onCancelled?: () => void;
} & Omit<DrawerProps, 'testId'>;

export const CancelImageExceptionDrawer = forwardRef<HTMLDivElement, Props>(
  ({ images, onCancelled, ...drawerProps }, ref) => {
    const cancelException = useCancelException({ images, onCancelled });

    return (
      <Drawer
        ref={ref}
        size="small"
        {...drawerProps}
        testId="cancel-exceptions-drawer"
      >
        <DrawerHeader noDivider sticky={false}>
          <Typography variant="h5" mb={8}>
            Cancel repository exception
          </Typography>
          <Typography variant="P14R" color="textSecondary" mb={16}>
            Selected repositories would be returned to Active repositories list
            view
          </Typography>
        </DrawerHeader>
        <DrawerContent>
          <Stack
            mx={-32}
            padding="16px 32px"
            bgcolor="grey.50"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="grey.200"
          >
            <Typography variant="P14B" color="grey.900">
              {pluralize('image', images.length, true)} repositories selected:
            </Typography>
            <ExpandableList childrenContainerSx={{ gap: 10, my: 8 }}>
              {images.map((image) => (
                <Typography
                  key={image.tags?.[0].name}
                  variant="P12M"
                  color="grey.900"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {getImageName(image)}
                </Typography>
              ))}
            </ExpandableList>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" mt={56} gap={8}>
            <Button variant="tertiary" onClick={drawerProps.onClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(e: MouseEvent) => {
                cancelException();
                drawerProps?.onClose?.(e, 'backdropClick');
              }}
              testId="cancel-exception-button"
            >
              Cancel exception
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
    );
  }
);

CancelImageExceptionDrawer.displayName = 'CancelImageExceptionDrawer';
