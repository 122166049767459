import keyBy from 'lodash/keyBy';

import { FeatureFlagResolved } from '@cast/types';

import { FlagResolver, FlagResolverList } from '../types';

const resolveFlag = (
  flags: FlagResolverList,
  flagsResolved: FeatureFlagResolved[],
  matcher: 'AND' | 'OR' = 'AND'
) => {
  return flags[matcher === 'AND' ? 'every' : 'some']((configFlag) => {
    if (typeof configFlag === 'function') {
      return configFlag(flagsResolved);
    }

    const flagsByName = keyBy(flagsResolved, 'flagName');

    if (configFlag in flagsByName) {
      return flagsByName[configFlag]?.boolean;
    }

    return false;
  });
};

export const resolveActionFlags = (
  configFlags: FlagResolver,
  flagsResolved: FeatureFlagResolved[]
) => {
  if (Array.isArray(configFlags)) {
    return resolveFlag(configFlags, flagsResolved, 'AND');
  }

  return resolveFlag(configFlags.flags, flagsResolved, configFlags.matcher);
};
