import { Box, Stack, useMediaQuery } from '@mui/material';

import { Button, Icons, theme } from '@cast/design-system';
import { getQueryParam } from '@cast/utils';

import { Logo as NewLogo, CircleIllustration } from 'assets/svg';
import { IS_PROD_EU } from 'common/constants';
import { LabelChip } from 'components/chips';
import { LogoutWrapper } from 'components/router';

export const ErrorPage = () => {
  const title = getQueryParam('title') || 'Failed to authorize';
  const description = getQueryParam('description');
  const errorType = getQueryParam('error-type') || 'internal-error';

  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const shouldHideRightPanel = isTablet || errorType === 'internal-error';

  const actions =
    errorType === 'internal-error' ? (
      <LogoutWrapper screenHint="SIGNUP">
        <Button
          sx={{
            color: '#0669FD',
            fontSize: '14px',
            fontFamily: 'Soehne Kraftig',
          }}
          variant="text"
        >
          Go back
        </Button>
      </LogoutWrapper>
    ) : null;

  const icon =
    errorType === 'unverified-email' ? (
      <Icons.Envelope weight="thin" size={32} color="#0669fd" />
    ) : (
      <Icons.Warning weight="thin" size={32} color="#d80e53" />
    );

  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        inset: 0,
        zIndex: 'modal',
        background: '#F5F7FA',
        flexDirection: 'column',
        padding: '32px',
        gap: '40px',
      }}
    >
      <Stack
        sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
      >
        <NewLogo style={{ flex: '0 0 34px' }} />
        {IS_PROD_EU && (
          <LabelChip
            size="micro"
            sx={{
              backgroundColor: 'white',
              color: 'grey.800',
              mt: 11,
            }}
          >
            EU
          </LabelChip>
        )}
      </Stack>
      <Box
        sx={{
          border: '1px solid #E1E4EA',
          padding: '12px',
          borderRadius: '24px',
          flex: '1 1 100%',
          maxHeight: '736px',
          width: isTablet ? '90%' : shouldHideRightPanel ? '40%' : '90%',
          maxWidth: '1224px',
        }}
      >
        <Box
          sx={{
            borderRadius: '12px',
            backgroundColor: '#fff',
            height: '100%',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              flex: '1 1 100%',
              padding: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#fff',
              borderRadius: shouldHideRightPanel ? '8px' : '8px 0 0 8px',
            }}
          >
            <Stack
              sx={{
                gap: '40px',
                alignItems: 'center',
              }}
            >
              <Stack
                sx={{
                  width: '56px',
                  height: '56px',
                  borderRadius: '100%',
                  padding: '10px',
                  backgroundColor:
                    errorType === 'internal-error' ? '#d80e531a' : '#0669fd1a',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </Stack>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
              >
                <Box
                  sx={{
                    color: '#0E0F13',
                    textAlign: 'center',
                    fontFamily: 'Soehne Breit Kraftig',
                    fontSize: '32px',
                    fontWeight: 500,
                    lineHeight: '40px',
                  }}
                  data-testid="error-title"
                >
                  {title}
                </Box>
                {description && (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                    sx={{
                      color: '#525866',
                      textAlign: 'center',
                      fontFamily: 'Soehne Buch',
                      fontSize: '14px',
                      lineHeight: '22px',
                      '&& em': {
                        textDecoration: 'underline',
                        fontStyle: 'normal',
                      },
                    }}
                    data-testid="error-description"
                  />
                )}
              </Box>
              {actions}
            </Stack>
          </Box>
          <Box
            sx={{
              flex: '1 1 100%',
              padding: '12px',
              backgroundColor: '#D8ECFF',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '50%',
              display: shouldHideRightPanel ? 'none' : 'flex',
              borderRadius: shouldHideRightPanel ? '0px' : '0px 8px 8px 0px',
            }}
          >
            <CircleIllustration />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          fontFamily: 'Soehne Buch',
          fontWeight: '400',
          color: '#525866',
          lineHeight: '22px',
        }}
      >
        © {new Date().getFullYear()} CAST AI Group Inc.
      </Box>
    </Box>
  );
};
