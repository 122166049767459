import dayjs from 'dayjs';
import upperCase from 'lodash/upperCase';

import { DATE_TIME_PRECISE } from '@cast/constants';
import {
  BestPracticeCheck,
  Cluster,
  ClusterDisplayStatus,
  K8sProvider,
  NodeResponse,
  SecurityInsightsContainerImage,
} from '@cast/types';
import { getTimeAgo, toCsv } from '@cast/utils';

import { isManagedByCASTAI } from 'features/cluster/nodes/_utils/common';
import { ClusterWithSchedules } from 'features/scheduled-rebalancing/types';
import { severityToTextMap } from 'features/security-insights';
import { enhanceNode } from 'utils/api-utils';

import { cisByProvider } from './constants';
import { AgeGroup, PreparedNode, UpdatesStatus } from './types';

export const getImageName = (image?: SecurityInsightsContainerImage) => {
  const tagName = image?.tags?.find((tag) => tag.name)?.name ?? '';
  return tagName.split('@sha256')[0].split(':')[0];
};

export const getImageTagId = (image?: SecurityInsightsContainerImage) => {
  return image?.tags?.find((t) => t.id)?.id ?? '';
};

export const getSecurityStandardData = (standard: K8sProvider) => {
  return (
    cisByProvider[standard as keyof typeof cisByProvider] ?? {
      title: 'CAST AI',
      value: 'cast',
    }
  );
};

const validStatuses = [
  ClusterDisplayStatus.ONLINE,
  ClusterDisplayStatus.READ_ONLY,
  ClusterDisplayStatus.READY,
];
export const isValidCluster = ({ displayStatus }: Cluster) => {
  return validStatuses.includes(displayStatus);
};

const getUpdateStatus = (cluster: ClusterWithSchedules) => {
  if (cluster.providerType === K8sProvider.ANYWHERE) {
    return UpdatesStatus.UNAVAILABLE;
  }
  if (!cluster.credentialsId) {
    return UpdatesStatus.UNKNOWN;
  }
  if (cluster.schedules.length) {
    return UpdatesStatus.ON;
  }
  return UpdatesStatus.OFF;
};

export const prepareNode = (
  node: NodeResponse,
  cluster: ClusterWithSchedules
): PreparedNode => {
  const timeStr = node.createdAt! || node.joinedAt!;
  const timeAgo: PreparedNode['timeAgo'] = {
    formattedDate: '',
    distance: '',
  };
  let ageGroup = AgeGroup.MORE_THAN_MONTH;
  if (timeStr) {
    const time = dayjs(timeStr);
    timeAgo.formattedDate = time.format(DATE_TIME_PRECISE);
    timeAgo.distance = getTimeAgo(timeStr, true);
    const now = dayjs();
    if (time.isSameOrAfter(now.subtract(1, 'week'))) {
      ageGroup = AgeGroup.UP_TO_WEEK;
    } else if (time.isSameOrAfter(now.subtract(1, 'month'))) {
      ageGroup = AgeGroup.UP_TO_MONTH;
    }
  }

  const isManaged = isManagedByCASTAI(node);

  return {
    id: node.id!,
    name: node.name!,
    cluster,
    created: node.createdAt!,
    os: node.nodeInfo?.operatingSystem || '',
    osVersion: node.nodeInfo?.osImage || '',
    provider: cluster.providerType,
    managedBy: isManaged ? 'CAST AI' : cluster.providerType,
    updatesStatus: getUpdateStatus(cluster),
    ageGroup,
    timeAgo,
    orig: enhanceNode(node),
  };
};

export const getOrgNodesSummary = (nodes: PreparedNode[] | undefined) => {
  if (!nodes) {
    return;
  }
  let managedByCastAi = 0;
  let updatesScheduled = 0;
  for (const node of nodes) {
    if (node.managedBy === 'CAST AI') {
      managedByCastAi++;
    }
    if (node.updatesStatus === UpdatesStatus.ON) {
      updatesScheduled++;
    }
  }
  return { managedByCastAi, updatesScheduled };
};

export const sanitizeFileName = (fileName: string) => {
  const illegalChars = /[/?<>\\:*|"]/g;
  return fileName.replace(illegalChars, '');
};

export const prepareComplianceExportData = (data: BestPracticeCheck[]) => {
  const csvData = data.map((item) => {
    return {
      type: item.manual ? 'Manual' : 'Automated',
      id: item.ruleId ?? '',
      check: item.name ?? '',
      standard: item.labels?.join(', ') ?? '',
      affectedClusters: item.clustersCount ?? 0,
      affectedResources: item.failed ?? 0,
      exceptedResources: item.excepted ?? 0,
      totalResources: item.total ?? 0,
      severity:
        item.severityLevel && item.severityLevel in severityToTextMap
          ? severityToTextMap[item.severityLevel]
          : upperCase(item.severityLevel),
    };
  });

  return toCsv<
    (typeof csvData)[number] & {
      gpuRequested?: number;
      gpuProvisioned?: number;
      gpuAllocatable?: number;
      gpuCost?: number;
    }
  >(
    {
      type: {
        header: 'Type',
        map: (value) => value,
      },
      id: {
        header: 'ID',
        map: (value) => value,
      },
      check: {
        header: 'Check',
        map: (value) => value,
      },
      standard: {
        header: 'Standard',
        map: (value) => value,
      },
      affectedClusters: {
        header: 'Affected clusters',
        map: (value) => String(value),
      },
      affectedResources: {
        header: 'Affected resources',
        map: (value) => String(value),
      },
      exceptedResources: {
        header: 'Excepted resources',
        map: (value) => String(value),
      },
      totalResources: {
        header: 'Total resources',
        map: (value) => String(value),
      },
      severity: {
        header: 'Severity',
        map: (value) => value ?? 'Unknown',
      },
    },
    csvData
  );
};
