import { Box, Typography } from '@mui/material';

import { Radio, RadioProps, Tooltip } from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { PROVIDER_NAME_MAP } from 'misc/constants';

import { K8sProviderIcon } from '../icons';

const tooltipByProvider: Partial<Record<K8sProvider, string>> = {
  [K8sProvider.ROSA]: `${PROVIDER_NAME_MAP[K8sProvider.ROSA]} service on AWS`,
  [K8sProvider.KOPS]: `${PROVIDER_NAME_MAP[K8sProvider.KOPS]} service on AWS`,
};

const secondLineByProvider: Partial<Record<K8sProvider, string>> = {
  [K8sProvider.ROSA]: 'on AWS',
  [K8sProvider.KOPS]: 'on AWS',
  [K8sProvider.ANYWHERE]: 'anywhere',
};

type Props = Omit<RadioProps, 'onClick'> & {
  provider: `${K8sProvider}`;
  isCompact?: boolean;
  onClick: () => void;
};

export const SelectProviderRadio = ({
  provider,
  onClick,
  isCompact,
  checked,
  ...props
}: Props) => {
  return (
    <Tooltip title={tooltipByProvider[provider]} placement="top">
      <Box
        border={checked ? 'blue.500' : 'grey.200'}
        borderRadius="8px"
        onClick={onClick}
        display="flex"
        gap={isCompact ? '6px' : '4px'}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          cursor: 'pointer',
          minWidth: 86,
          height: 108,
          ...(isCompact && {
            padding: '0px 8px',
            height: '42px',
            flexDirection: 'row-reverse',
          }),
        }}
      >
        <Box
          gap="6px"
          display="flex"
          alignItems="center"
          flexDirection={isCompact ? 'row' : 'column'}
        >
          <K8sProviderIcon provider={provider} size={isCompact ? 18 : 24} />

          <Box
            display="flex"
            flexDirection="column"
            minHeight="34px"
            textAlign="center"
            {...(isCompact && {
              textAlign: 'left',
              minHeight: 'inherit',
            })}
          >
            <Typography variant="A2" color={checked ? 'blue.600' : 'grey.600'}>
              {PROVIDER_NAME_MAP[provider]}
            </Typography>

            {!!secondLineByProvider[provider] && (
              <Typography
                component="p"
                variant="P10R"
                color="grey.400"
                fontSize="8px"
                lineHeight="10px"
                {...(isCompact && {
                  marginTop: '-2px',
                })}
              >
                {secondLineByProvider[provider]}
              </Typography>
            )}
          </Box>
        </Box>

        <Radio
          size={isCompact ? 'small' : 'medium'}
          checked={checked}
          {...props}
        />
      </Box>
    </Tooltip>
  );
};
