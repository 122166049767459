import { useEffect, useState } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import 'yup-phone-lite';

import { Button, theme } from '@cast/design-system';
import { removeHiddenChars, makeValidateSync } from '@cast/utils';

import { ContactsFormStep1, ContactsFormStep2, Logo } from 'assets/svg';
import { IS_PROD } from 'common/constants';
import { analyticsEvents } from 'core/analytics';
import { RffInput } from 'core/forms/rff';

import { ContactFormSlide, JobFunctionToggle } from './_components';
import { ContactFormStepper, Slide } from './_components/ContactFormStepper';
import { useContactsForm } from './_hooks';
import {
  contactsFormFirstStepSchema,
  contactsFormSchema,
} from './validation.schema';
import { SwipeableViews } from '../../boxes';
import { CountryCodeAutocomplete } from '../CountryCodeAutocomplete';

const validateFirstStep = makeValidateSync(contactsFormFirstStepSchema);
const validate = makeValidateSync(contactsFormSchema);

export const ContactsForm = () => {
  const { showForm, submitContactsForm, closeContactsForm } = useContactsForm();
  const [slide, setSlide] = useState<Slide>('contacts');
  const isFirstStepInvalid = (values: Record<string, any>) => {
    const { firstName, lastName } = values;

    return Object.keys(validateFirstStep({ firstName, lastName })).length > 0;
  };
  const isLaptop = useMediaQuery(theme.breakpoints.down('laptop'));

  useEffect(() => {
    if (showForm) {
      analyticsEvents.signupFormVisited();
    }
  }, [showForm]);

  if (!showForm) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        placeContent: 'center center',
        alignItems: 'center',
        inset: 0,
        zIndex: 'modal',
        background: '#F5F7FA',
        flexDirection: 'column',
        padding: '32px',
        gap: '40px',
      }}
    >
      <Logo style={{ flex: '0 0 34px' }} />
      <Box
        sx={{
          border: '1px solid #E1E4EA',
          padding: '12px',
          borderRadius: '24px',
          flex: '1 1 100%',
          maxHeight: '760px',
        }}
      >
        <Box
          sx={{
            borderRadius: '12px',
            backgroundColor: '#fff',
            height: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            '&& .DS-InputLabel-label': {
              fontFamily: 'Soehne Kraftig',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '500',
            },
            '&& .MuiInputBase-input': {
              fontFamily: 'Soehne Buch',
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: '400',
            },
            '&& .MuiButtonBase-root': {
              height: '38px',
            },
            '&& .DsButton-root[variant="primary"]:not([disabled])': {
              backgroundColor: '#0669FD',
            },
            '&& .DsButton-root[variant="primary"]:not([disabled]):hover': {
              backgroundColor: '#0560E6',
            },
          }}
        >
          <Form
            onSubmit={({
              firstName,
              lastName,
              countryCode,
              phone,
              company,
              jobTitle,
            }: Yup.InferType<typeof contactsFormSchema>) => {
              const data = {
                firstName,
                lastName,
                phone: `${countryCode?.phone ?? ''}${phone?.replaceAll(
                  ' ',
                  ''
                )}`,
                company,
                jobTitle,
              };

              if (IS_PROD) {
                submitContactsForm(data);
              }

              closeContactsForm();
            }}
            validate={validate}
            render={({ handleSubmit, values }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 0',
                  placeContent: 'stretch center',
                  alignItems: 'stretch',
                  maxWidth: '552px',
                }}
              >
                <SwipeableViews
                  animateHeight={false}
                  activeSlide={slide}
                  sx={{ width: 'auto' }}
                  slides={{
                    contacts: (
                      <ContactFormSlide
                        title="Let's get started"
                        subTitle="We need a few details to set up your account."
                      >
                        <RffInput
                          size="large"
                          name="firstName"
                          id="firstName"
                          label="First name"
                          placeholder="John"
                          sx={{ paddingTop: '16px' }}
                        />
                        <RffInput
                          size="large"
                          name="lastName"
                          id="lastName"
                          label="Last name"
                          placeholder="Smith"
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                          }}
                        >
                          <Typography
                            variant="P14B"
                            sx={{
                              fontFamily: 'Soehne Kraftig',
                              fontSize: '14px',
                              lineHeight: '20px',
                              display: 'flex',
                              fontWeight: '500',
                            }}
                          >
                            Phone number
                            <Typography
                              variant="P14R"
                              sx={{
                                color: '#525866',
                                fontFamily: 'Soehne Buch',
                                fontWeight: '400',
                              }}
                            >
                              &nbsp;(optional)
                            </Typography>
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '8px',
                            }}
                          >
                            <Box sx={{ maxWidth: '124px' }}>
                              <CountryCodeAutocomplete
                                size="large"
                                name="countryCode"
                              />
                            </Box>

                            <RffInput
                              autoComplete="off"
                              size="large"
                              name="phone"
                              placeholder="000 000 000"
                              fullWidth
                              fieldProps={{
                                parse: removeHiddenChars,
                              }}
                            />
                          </Box>
                        </Box>

                        <Button
                          variant="primary"
                          onClick={() => {
                            setSlide('role');
                          }}
                          disabled={isFirstStepInvalid(values)}
                          sx={{ marginTop: '16px' }}
                          fullWidth
                        >
                          Continue
                        </Button>
                      </ContactFormSlide>
                    ),
                    role: (
                      <ContactFormSlide
                        title="Tell us about your role"
                        subTitle="Add your company name and select the role that best
                      describes your work."
                        // eslint-disable-next-line react/no-children-prop
                      >
                        <RffInput
                          size="large"
                          name="company"
                          id="company"
                          label="Company name"
                          placeholder="e.g. Google"
                          required
                        />
                        <JobFunctionToggle
                          name="jobTitle"
                          label="Job title"
                          required
                        />

                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          sx={{ marginTop: '16px' }}
                          disabled={Object.keys(validate(values)).length > 0}
                          fullWidth
                        >
                          Finish
                        </Button>
                      </ContactFormSlide>
                    ),
                  }}
                />

                <ContactFormStepper
                  disabled={isFirstStepInvalid(values)}
                  slide={slide}
                  setSlide={(slide) => setSlide(slide)}
                ></ContactFormStepper>
              </Box>
            )}
          />
          {!isLaptop && (
            <Box
              sx={{
                backgroundColor: '#D8ECFF',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                display: 'flex',
              }}
            >
              {slide === 'contacts' ? (
                <ContactsFormStep1 />
              ) : (
                <ContactsFormStep2 />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Typography
        variant="P14R"
        sx={{
          fontFamily: 'Soehne Buch',
          fontWeight: '400',
          color: '#525866',
          lineHeight: '22px',
        }}
      >
        © 2025 CAST AI Group Inc.
      </Typography>
    </Box>
  );
};
