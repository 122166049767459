import { useMatch } from 'react-router-dom';

import { ClusterStatus, K8sProvider } from '@cast/types';
import { isDemoMode } from '@cast/utils';

import { getRebalanceProgress } from 'features/cluster/rebalance/utils';
import { useClusterQuery } from 'hooks/queries/cluster/useClusterQuery';
import {
  useRebalancePlanQuery,
  useRebalancePlansQuery,
} from 'hooks/queries/rebalancer';
import { REBALANCING_IN_PROGRESS_STATES } from 'misc/constants';
import { isClusterManaged } from 'utils/cluster';

import { SidebarItemProps } from '../_components/SidebarItem';

export const useRebalancingPlanSidebarItemProps =
  (): Partial<SidebarItemProps> => {
    const match = useMatch('/external-clusters/:clusterId/*');
    const clusterId = match?.params?.clusterId;
    const { cluster } = useClusterQuery(clusterId);
    const isManaged = isClusterManaged(cluster);
    const { isLoading: isLoadingRebalancingPlans, rebalancePlans } =
      useRebalancePlansQuery({
        clusterId,
        limit: 1,
        disabled: !isManaged,
        includeStatuses: REBALANCING_IN_PROGRESS_STATES,
      });
    const activeRebalancingPlanId =
      rebalancePlans?.[0]?.rebalancingPlanId ?? undefined;

    const { rebalancePlan } = useRebalancePlanQuery({
      clusterId,
      rebalancePlanId: activeRebalancingPlanId,
      includeOperations: true,
    });

    if (!isDemoMode()) {
      if (cluster?.providerType === K8sProvider.ANYWHERE) {
        return {
          url: `${match?.pathnameBase}/rebalance`,
          disabled: true,
        };
      }
      if (isLoadingRebalancingPlans) {
        return {
          url: `${match?.pathnameBase}/rebalance`,
          disabled: true,
          tooltip: `Page is not ready yet, loading rebalancing history please wait...`,
        };
      }
      if (!isManaged) {
        return {
          url: `${match?.pathnameBase}/rebalance`,
          disabled: true,
          tooltip: 'Enable cost optimization',
        };
      }
      if (
        cluster &&
        ![ClusterStatus.READY, ClusterStatus.WARNING].includes(cluster.status)
      ) {
        return {
          url: `${match?.pathnameBase}/rebalance`,
          disabled: true,
          tooltip: 'Invalid cluster status, cluster must be in ready state',
        };
      }
    }

    if (
      rebalancePlan &&
      REBALANCING_IN_PROGRESS_STATES.includes(rebalancePlan.status)
    ) {
      const progress = getRebalanceProgress(rebalancePlan);

      return {
        url: `${match?.pathnameBase}/rebalance/${rebalancePlan.rebalancingPlanId}/progress`,
        progressBar: {
          show: Boolean(progress.overall?.percentage),
          value: progress.overall?.percentage ?? 0,
        },
        disabled: false,
      };
    }

    return {
      url: `${match?.pathnameBase}/rebalance`,
      disabled: false,
    };
  };
