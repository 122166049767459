/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CostreportV1beta1GetClusterAgentStatusResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterCostHistoryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterCostReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterEfficiencyReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterResourceUsageResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterSavingsReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterScoreHistoryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterScoreResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterSummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterUnscheduledPodsResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClustersCostReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClustersSummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetCostAnomaliesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetOrganizationClustersCostReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetOrganizationClustersEfficiencySummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetOrganizationEfficiencyReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetOrganizationEfficiencySummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetRightsizingSummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetSavingsRecommendationResponse } from '../models';
/**
 * ClusterReportAPIApi - axios parameter creator
 * @export
 */
export const ClusterReportAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get status of all castware components in the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterAgentStatus: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterAgentStatus', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/agent-status`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available savings history over time. Real cluster cost and estimated optimal cost.
         * @param {string} clusterId ID of the cluster.
         * @param {string} fromDate Filter items to include from specified time.
         * @param {string} toDate Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterCostHistory: async (clusterId: string, fromDate: string, toDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostHistory', 'clusterId', clusterId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostHistory', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostHistory', 'toDate', toDate)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/estimated-savings-history`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster cost report data.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterCostReport: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterCostReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/cost`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster efficiency report data.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterEfficiencyReport: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterEfficiencyReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterEfficiencyReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterEfficiencyReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/efficiency`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster resource usage.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterResourceUsage: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterResourceUsage', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterResourceUsage', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterResourceUsage', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/resource-usage`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster savings report.
         * @param {string} clusterId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterSavingsReport: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterSavingsReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterSavingsReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterSavingsReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/savings`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the Score for a cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterScore: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterScore', 'clusterId', clusterId)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/cluster-score`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the historic scores for a cluster. The scores are available only for clusters in phase 2.
         * @param {string} clusterId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterScoreHistory: async (clusterId: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterScoreHistory', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterScoreHistory', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterScoreHistory', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/cluster-score-history`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets current details about the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterSummary: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterSummary', 'clusterId', clusterId)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/summary`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets unscheduled pods in the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterUnscheduledPods: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetClusterUnscheduledPods', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/unscheduled-pods`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cost report data over all clusters of user organization.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClustersCostReport: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClustersCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetClustersCostReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/organization/daily-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets current details about all clusters of organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClustersSummary: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cost-reports/organization/clusters/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the all the detected cost anomalies for a cluster in a given time period.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetCostAnomalies: async (clusterId: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetCostAnomalies', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetCostAnomalies', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetCostAnomalies', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/cost-anomalies`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets summary for all clusters for an organization
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationClustersCostReport: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationClustersCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationClustersCostReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/organization/clusters/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the efficiency summary per cluster for an organization.
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationClustersEfficiencySummary: async (startTime: string, endTime: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationClustersEfficiencySummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationClustersEfficiencySummary', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/organization/clusters/efficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the efficiency per timestamp for an organization.
         * @param {string} startTime Start of period for the report
         * @param {string} endTime End of period for the report
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationEfficiencyReport: async (startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationEfficiencyReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationEfficiencyReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/organization/efficiency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the efficiency summary for an organization.
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationEfficiencySummary: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationEfficiencySummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('clusterReportAPIGetOrganizationEfficiencySummary', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/organization/efficiency/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the rightsizing summary for the cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetRightsizingSummary: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetRightsizingSummary', 'clusterId', clusterId)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/rightsizing-summary`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the available savings estimation report. Current cluster state is evaluated and a report is generated based on the workloads and nodes running in your cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetSavingsRecommendation: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterReportAPIGetSavingsRecommendation', 'clusterId', clusterId)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/estimated-savings`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterReportAPIApi - functional programming interface
 * @export
 */
export const ClusterReportAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusterReportAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get status of all castware components in the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterAgentStatus(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterAgentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterAgentStatus(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterAgentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets available savings history over time. Real cluster cost and estimated optimal cost.
         * @param {string} clusterId ID of the cluster.
         * @param {string} fromDate Filter items to include from specified time.
         * @param {string} toDate Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterCostHistory(clusterId: string, fromDate: string, toDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterCostHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterCostHistory(clusterId, fromDate, toDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterCostHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster cost report data.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterCostReport(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterCostReport(clusterId, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster efficiency report data.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterEfficiencyReport(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterEfficiencyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterEfficiencyReport(clusterId, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterEfficiencyReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster resource usage.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterResourceUsage(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterResourceUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterResourceUsage(clusterId, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterResourceUsage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster savings report.
         * @param {string} clusterId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterSavingsReport(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterSavingsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterSavingsReport(clusterId, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterSavingsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the Score for a cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterScore(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterScoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterScore(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterScore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the historic scores for a cluster. The scores are available only for clusters in phase 2.
         * @param {string} clusterId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterScoreHistory(clusterId: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterScoreHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterScoreHistory(clusterId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterScoreHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets current details about the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterSummary(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterSummary(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets unscheduled pods in the cluster.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClusterUnscheduledPods(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterUnscheduledPodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClusterUnscheduledPods(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClusterUnscheduledPods']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cost report data over all clusters of user organization.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClustersCostReport(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClustersCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClustersCostReport(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClustersCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets current details about all clusters of organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetClustersSummary(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClustersSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetClustersSummary(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetClustersSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the all the detected cost anomalies for a cluster in a given time period.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetCostAnomalies(clusterId: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostAnomaliesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetCostAnomalies(clusterId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetCostAnomalies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets summary for all clusters for an organization
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetOrganizationClustersCostReport(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetOrganizationClustersCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetOrganizationClustersCostReport(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetOrganizationClustersCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the efficiency summary per cluster for an organization.
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetOrganizationClustersEfficiencySummary(startTime: string, endTime: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetOrganizationClustersEfficiencySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetOrganizationClustersEfficiencySummary(startTime, endTime, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetOrganizationClustersEfficiencySummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the efficiency per timestamp for an organization.
         * @param {string} startTime Start of period for the report
         * @param {string} endTime End of period for the report
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetOrganizationEfficiencyReport(startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetOrganizationEfficiencyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetOrganizationEfficiencyReport(startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetOrganizationEfficiencyReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the efficiency summary for an organization.
         * @param {string} startTime Start of period for summary
         * @param {string} endTime End of period for summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetOrganizationEfficiencySummary(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetOrganizationEfficiencySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetOrganizationEfficiencySummary(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetOrganizationEfficiencySummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the rightsizing summary for the cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetRightsizingSummary(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetRightsizingSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetRightsizingSummary(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetRightsizingSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the available savings estimation report. Current cluster state is evaluated and a report is generated based on the workloads and nodes running in your cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterReportAPIGetSavingsRecommendation(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetSavingsRecommendationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterReportAPIGetSavingsRecommendation(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClusterReportAPIApi.clusterReportAPIGetSavingsRecommendation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClusterReportAPIApi - factory interface
 * @export
 */
export const ClusterReportAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusterReportAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Get status of all castware components in the cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterAgentStatus(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterAgentStatusResponse> {
            return localVarFp.clusterReportAPIGetClusterAgentStatus(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available savings history over time. Real cluster cost and estimated optimal cost.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterCostHistory(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterCostHistoryResponse> {
            return localVarFp.clusterReportAPIGetClusterCostHistory(requestParameters.clusterId, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster cost report data.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterCostReportResponse> {
            return localVarFp.clusterReportAPIGetClusterCostReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster efficiency report data.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterEfficiencyReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterEfficiencyReportResponse> {
            return localVarFp.clusterReportAPIGetClusterEfficiencyReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster resource usage.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterResourceUsage(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterResourceUsageResponse> {
            return localVarFp.clusterReportAPIGetClusterResourceUsage(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster savings report.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterSavingsReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterSavingsReportResponse> {
            return localVarFp.clusterReportAPIGetClusterSavingsReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the Score for a cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterScore(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterScoreResponse> {
            return localVarFp.clusterReportAPIGetClusterScore(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the historic scores for a cluster. The scores are available only for clusters in phase 2.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterScoreHistory(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterScoreHistoryResponse> {
            return localVarFp.clusterReportAPIGetClusterScoreHistory(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets current details about the cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterSummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterSummaryResponse> {
            return localVarFp.clusterReportAPIGetClusterSummary(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets unscheduled pods in the cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClusterUnscheduledPods(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterUnscheduledPodsResponse> {
            return localVarFp.clusterReportAPIGetClusterUnscheduledPods(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cost report data over all clusters of user organization.
         * @param {ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClustersCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClustersCostReportResponse> {
            return localVarFp.clusterReportAPIGetClustersCostReport(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets current details about all clusters of organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetClustersSummary(options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClustersSummaryResponse> {
            return localVarFp.clusterReportAPIGetClustersSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the all the detected cost anomalies for a cluster in a given time period.
         * @param {ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetCostAnomalies(requestParameters: ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostAnomaliesResponse> {
            return localVarFp.clusterReportAPIGetCostAnomalies(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets summary for all clusters for an organization
         * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationClustersCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetOrganizationClustersCostReportResponse> {
            return localVarFp.clusterReportAPIGetOrganizationClustersCostReport(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the efficiency summary per cluster for an organization.
         * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationClustersEfficiencySummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetOrganizationClustersEfficiencySummaryResponse> {
            return localVarFp.clusterReportAPIGetOrganizationClustersEfficiencySummary(requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the efficiency per timestamp for an organization.
         * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationEfficiencyReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetOrganizationEfficiencyReportResponse> {
            return localVarFp.clusterReportAPIGetOrganizationEfficiencyReport(requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the efficiency summary for an organization.
         * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetOrganizationEfficiencySummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetOrganizationEfficiencySummaryResponse> {
            return localVarFp.clusterReportAPIGetOrganizationEfficiencySummary(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the rightsizing summary for the cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetRightsizingSummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetRightsizingSummaryResponse> {
            return localVarFp.clusterReportAPIGetRightsizingSummary(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the available savings estimation report. Current cluster state is evaluated and a report is generated based on the workloads and nodes running in your cluster.
         * @param {ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterReportAPIGetSavingsRecommendation(requestParameters: ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetSavingsRecommendationResponse> {
            return localVarFp.clusterReportAPIGetSavingsRecommendation(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clusterReportAPIGetClusterAgentStatus operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterAgentStatus
     */
    readonly clusterId: string
}

/**
 * Request parameters for clusterReportAPIGetClusterCostHistory operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostHistory
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostHistory
     */
    readonly fromDate: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostHistory
     */
    readonly toDate: string
}

/**
 * Request parameters for clusterReportAPIGetClusterCostReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterCostReport
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for clusterReportAPIGetClusterEfficiencyReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReport
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for clusterReportAPIGetClusterResourceUsage operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterResourceUsage
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterResourceUsage
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterResourceUsage
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterResourceUsage
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for clusterReportAPIGetClusterSavingsReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterSavingsReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterSavingsReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterSavingsReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterSavingsReport
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for clusterReportAPIGetClusterScore operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterScore
     */
    readonly clusterId: string
}

/**
 * Request parameters for clusterReportAPIGetClusterScoreHistory operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterScoreHistory
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterScoreHistory
     */
    readonly startTime: string

    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterScoreHistory
     */
    readonly endTime: string
}

/**
 * Request parameters for clusterReportAPIGetClusterSummary operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterSummary
     */
    readonly clusterId: string
}

/**
 * Request parameters for clusterReportAPIGetClusterUnscheduledPods operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPods
     */
    readonly clusterId: string
}

/**
 * Request parameters for clusterReportAPIGetClustersCostReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClustersCostReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetClustersCostReport
     */
    readonly endTime: string
}

/**
 * Request parameters for clusterReportAPIGetCostAnomalies operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetCostAnomalies
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetCostAnomalies
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetCostAnomalies
     */
    readonly endTime: string
}

/**
 * Request parameters for clusterReportAPIGetOrganizationClustersCostReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest {
    /**
     * Start of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReport
     */
    readonly startTime: string

    /**
     * End of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReport
     */
    readonly endTime: string
}

/**
 * Request parameters for clusterReportAPIGetOrganizationClustersEfficiencySummary operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest {
    /**
     * Start of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly startTime: string

    /**
     * End of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly endTime: string

    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummary
     */
    readonly sortOrder?: ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum
}

/**
 * Request parameters for clusterReportAPIGetOrganizationEfficiencyReport operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest {
    /**
     * Start of period for the report
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReport
     */
    readonly startTime: string

    /**
     * End of period for the report
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReport
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for clusterReportAPIGetOrganizationEfficiencySummary operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest {
    /**
     * Start of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummary
     */
    readonly startTime: string

    /**
     * End of period for summary
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummary
     */
    readonly endTime: string
}

/**
 * Request parameters for clusterReportAPIGetRightsizingSummary operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetRightsizingSummary
     */
    readonly clusterId: string
}

/**
 * Request parameters for clusterReportAPIGetSavingsRecommendation operation in ClusterReportAPIApi.
 * @export
 * @interface ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest
 */
export interface ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterReportAPIApiClusterReportAPIGetSavingsRecommendation
     */
    readonly clusterId: string
}

/**
 * ClusterReportAPIApi - object-oriented interface
 * @export
 * @class ClusterReportAPIApi
 * @extends {BaseAPI}
 */
export class ClusterReportAPIApi extends BaseAPI {
    /**
     * 
     * @summary Get status of all castware components in the cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterAgentStatus(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterAgentStatusRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterAgentStatus(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available savings history over time. Real cluster cost and estimated optimal cost.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterCostHistory(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterCostHistoryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterCostHistory(requestParameters.clusterId, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster cost report data.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterCostReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterCostReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster efficiency report data.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterEfficiencyReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterEfficiencyReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterEfficiencyReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster resource usage.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterResourceUsage(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterResourceUsageRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterResourceUsage(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster savings report.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterSavingsReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterSavingsReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterSavingsReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the Score for a cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterScore(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterScoreRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterScore(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the historic scores for a cluster. The scores are available only for clusters in phase 2.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterScoreHistory(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterScoreHistoryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterScoreHistory(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets current details about the cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterSummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterSummaryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterSummary(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets unscheduled pods in the cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClusterUnscheduledPods(requestParameters: ClusterReportAPIApiClusterReportAPIGetClusterUnscheduledPodsRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClusterUnscheduledPods(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cost report data over all clusters of user organization.
     * @param {ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClustersCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetClustersCostReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClustersCostReport(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets current details about all clusters of organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetClustersSummary(options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetClustersSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the all the detected cost anomalies for a cluster in a given time period.
     * @param {ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetCostAnomalies(requestParameters: ClusterReportAPIApiClusterReportAPIGetCostAnomaliesRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetCostAnomalies(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets summary for all clusters for an organization
     * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetOrganizationClustersCostReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationClustersCostReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetOrganizationClustersCostReport(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the efficiency summary per cluster for an organization.
     * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetOrganizationClustersEfficiencySummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationClustersEfficiencySummaryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetOrganizationClustersEfficiencySummary(requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the efficiency per timestamp for an organization.
     * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetOrganizationEfficiencyReport(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencyReportRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetOrganizationEfficiencyReport(requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the efficiency summary for an organization.
     * @param {ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetOrganizationEfficiencySummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetOrganizationEfficiencySummaryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetOrganizationEfficiencySummary(requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the rightsizing summary for the cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetRightsizingSummary(requestParameters: ClusterReportAPIApiClusterReportAPIGetRightsizingSummaryRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetRightsizingSummary(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the available savings estimation report. Current cluster state is evaluated and a report is generated based on the workloads and nodes running in your cluster.
     * @param {ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterReportAPIApi
     */
    public clusterReportAPIGetSavingsRecommendation(requestParameters: ClusterReportAPIApiClusterReportAPIGetSavingsRecommendationRequest, options?: RawAxiosRequestConfig) {
        return ClusterReportAPIApiFp(this.configuration).clusterReportAPIGetSavingsRecommendation(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum = typeof ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum[keyof typeof ClusterReportAPIGetOrganizationClustersEfficiencySummarySortOrderEnum];
