import keyBy from 'lodash/keyBy';

import { FeatureFlagResolved } from '@cast/types';

// prettier-ignore
export const isWOOPEnabled = (flagsResolved: FeatureFlagResolved[]) => {
  const flagsByName = keyBy(flagsResolved, 'flagName');
  const woopPhase1Enabled = flagsByName['woop-phase1-enabled'];
  const woopEnabled = flagsByName['ui__page-modules--cluster__workload-optimization'];

  return woopPhase1Enabled?.boolean || woopEnabled?.boolean;
};
