import { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@cast/design-system';

import { AccountType } from '../../../../types/accounts';
import { CloseDialogButton } from '../../../dialogs';
import { SelectAccountTypeRadio } from '../../_components/SelectAccountType';

type Props = {
  onContinue: () => void;
  onConfirm: () => void;
  onClose: () => void;
};

export const InitializeTrialSlide = ({
  onContinue,
  onConfirm,
  onClose,
}: Props) => {
  const [accountType, setAccountType] = useState<AccountType>('paid');

  return (
    <Stack position="relative" data-testid="initialize-trial-slide">
      <DialogTitle sx={{ padding: '24px 32px' }}>
        <Typography variant="h5">Try Cast AI for free today</Typography>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <Stack gap="24px">
          <Stack display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="8px">
            <SelectAccountTypeRadio
              type="free"
              checked={accountType === 'free'}
              onClick={() => setAccountType('free')}
            />
            <SelectAccountTypeRadio
              type="paid"
              checked={accountType === 'paid'}
              onClick={() => setAccountType('paid')}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '24px 32px', borderTop: 'grey.200' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="8px"
          flex={1}
        >
          <Typography variant="P12R" color="textSecondary" width="354px">
            No credit card required. Start using Cast AI right away with zero
            financial commitment or future obligations.
          </Typography>
          {accountType === 'free' && (
            <Button
              testId="continue-button"
              variant="tertiary"
              onClick={onContinue}
            >
              Continue
            </Button>
          )}
          {accountType === 'paid' && (
            <Button testId="start-trial-button" onClick={onConfirm}>
              Start trial
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Stack>
  );
};
