import { ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { ConditionalWrapper } from '@cast/design-system';

import { LabelChip } from 'components/chips';
import { RffControlsGroup } from 'core/forms/rff';

type Props = {
  title: string;
  inputA: ReactNode;
  inputB?: ReactNode;
  comingSoon?: boolean;
};

export const InputsSection = ({ title, inputA, inputB, comingSoon }: Props) => {
  return (
    <Stack gap={8}>
      <ConditionalWrapper
        condition={!!comingSoon}
        wrapper={(title) => (
          <Stack direction="row" alignItems="center" gap={8}>
            {title}
            <LabelChip
              sx={{ backgroundColor: 'indigo.200', color: 'white' }}
              size="micro"
            >
              Coming soon
            </LabelChip>
          </Stack>
        )}
      >
        <Typography variant="A2">{title}</Typography>
      </ConditionalWrapper>
      <RffControlsGroup disabled={comingSoon}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={24}>
          {inputA}
          {inputB}
        </Box>
      </RffControlsGroup>
    </Stack>
  );
};
