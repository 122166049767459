import { Breakpoint, CSSObject } from '@mui/material';
import { Record } from 'phosphor-react';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    laptop: true;
  }
}

export const breakpoints: { [key in Breakpoint]: number } = {
  laptop: 1024,
  xs: 1366,
  sm: 1440,
  md: 1680,
  lg: 1920,
  xl: 2560,
};

export const responsiveStyles = (
  css: Partial<{
    [key in Breakpoint]: CSSObject;
  }>
) => {
  return Object.keys(css).reduce((acc, key) => {
    acc[
      `@media only screen and (min-width: ${
        breakpoints[key as keyof typeof breakpoints]
      }px)`
    ] = css[key as keyof typeof css] as CSSObject;
    return acc;
  }, {} as Record<string, CSSObject>);
};
