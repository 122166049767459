import { useEffect, useMemo, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import { useMatch } from 'react-router-dom';

import { DrawerProvider, useDrawer } from '@cast/design-system';
import { NotificationSeverity } from '@cast/types';
import { usePrevious } from '@cast/utils';

import { notificationsEvents } from 'core/analytics';
import { useNotificationDrawer } from 'features/notifications/hooks/useNotificationDrawer';
import { useGetNotificationsQuery } from 'hooks/queries/notifications';
import { useOrganizations } from 'hooks/useOrganizations';
import { ContentContainer } from 'main-layout/ContentContainer';
import { useMainLayout } from 'main-layout/hooks';

import { CriticalNotificationItem } from './CriticalNotificationItem';
import {
  GroupedNotification,
  isGroupedNotification,
  NotificationItem,
} from './types';
import { groupNotificationsByType } from './utils';

export const CriticalNotificationListInner = () => {
  const { openNotificationDrawer } = useNotificationDrawer();
  const { drawers } = useDrawer();
  const [isExpanded, setExpanded] = useState(false);
  const [expansionTransitionEnded, setExpansionTransitionEnded] =
    useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const isNotificationDrawerOpen = useMemo(() => {
    return !!Object.values(drawers).find((drawer) => {
      return !!drawer.props?.notification;
    });
  }, [drawers]);

  const { currentOrganization } = useOrganizations();
  const {
    notificationsResponse: { items },
  } = useGetNotificationsQuery({
    filter: {
      onlyUnresolved: true,
      isExpired: false,
      severities: [NotificationSeverity.CRITICAL],
    },
  });
  const prevItems = usePrevious(items);
  const [visibleNotifications, setVisibleNotifications] = useState<
    GroupedNotification[]
  >([]);
  const notificationTotal = visibleNotifications.length;

  useEffect(() => {
    if (currentOrganization && notificationTotal > 0) {
      notificationsEvents.viewedCriticalNotifications(currentOrganization.name);
    }
  }, [currentOrganization, notificationTotal]);

  useEffect(() => {
    if (!isEqual(items, prevItems)) {
      setVisibleNotifications(groupNotificationsByType(items));
    }
  }, [items, prevItems]);

  useEffect(() => {
    const controller = new AbortController();

    if (
      elementRef.current &&
      isExpanded &&
      expansionTransitionEnded &&
      !isNotificationDrawerOpen
    ) {
      const expandedHeight = elementRef.current.scrollHeight;
      const { top, left, right } = elementRef.current.getBoundingClientRect();

      window.addEventListener(
        'mousemove',
        throttle((e: MouseEvent) => {
          const isOutsideHorizontalBounds =
            e.clientX < left || e.clientX > right;
          const isOutsideVerticalBounds =
            e.clientY < top || e.clientY > top + expandedHeight;

          if (isOutsideHorizontalBounds || isOutsideVerticalBounds) {
            setExpanded(false);
            setExpansionTransitionEnded(false);
            controller.abort();
          }
        }, 350),
        {
          signal: controller.signal,
        }
      );
    }

    return () => {
      controller.abort();
    };
  }, [isExpanded, expansionTransitionEnded, isNotificationDrawerOpen]);

  const handleNotificationDismiss = (notification: GroupedNotification) => {
    notificationsEvents.dismissedCriticalNotification(
      currentOrganization!.name,
      notification.name!
    );

    setVisibleNotifications(
      visibleNotifications.filter((item) => item.id !== notification.id)
    );
  };

  const handleNotificationClick = (notification: NotificationItem) => {
    notificationsEvents.clickedCriticalNotification(
      currentOrganization!.name,
      notification.name!
    );

    openNotificationDrawer({
      // Grouped notification can only be clicked directly if it has a single item
      notification: isGroupedNotification(notification)
        ? notification.items[0]
        : notification,
      openedFrom: 'critical notifications list',
    });
  };

  if (!notificationTotal) {
    return null;
  }

  return (
    <Stack
      mt={-10}
      height={95}
      gap={4}
      sx={{
        isolation: 'isolate',
        zIndex: 100,
        position: 'relative',
        overflow: 'visible',
      }}
      onTransitionEnd={() => {
        if (isExpanded) {
          setExpansionTransitionEnded(true);
        }
      }}
      ref={elementRef}
      data-testid="critical-notification-list"
    >
      {notificationTotal > 1 && (
        <Typography
          variant="P12B"
          color="grey.600"
          sx={{
            position: 'absolute',
            top: -20,
          }}
        >
          {notificationTotal} Critical alerts
        </Typography>
      )}
      {visibleNotifications.map((notification, index) => (
        <CriticalNotificationItem
          key={notification.id}
          notification={notification}
          index={index}
          notificationTotal={notificationTotal}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          onClick={handleNotificationClick}
          onDismiss={handleNotificationDismiss}
        />
      ))}
    </Stack>
  );
};

export const CriticalNotificationList = () => {
  const isInNotificationsPage = !!useMatch('/notifications');
  const { isFullWidthContent } = useMainLayout();
  const content = (
    <DrawerProvider>
      <CriticalNotificationListInner />
    </DrawerProvider>
  );

  if (isInNotificationsPage) {
    return null;
  }

  // Ensure critical notifications have the same width as the main page content
  if (isFullWidthContent) {
    return <ContentContainer>{content}</ContentContainer>;
  }

  return content;
};
