import { Typography, Stack } from '@mui/material';

type Props = {
  icon: React.ReactNode;
  text: string;
};

export const TrialListItemWithIcon = ({ icon, text }: Props) => {
  return (
    <Stack
      display="grid"
      gridTemplateColumns="16px auto"
      alignItems="flex-start"
      gap="8px"
      fontSize="16px"
    >
      {icon}
      <Typography variant="P12R" color="textSecondary">
        {text}
      </Typography>
    </Stack>
  );
};
