import { Box, Stack, Typography } from '@mui/material';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icons,
} from '@cast/design-system';

import { CloseDialogButton } from '../../../dialogs';

type Props = {
  onBack: () => void;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmationSlide = ({ onBack, onConfirm, onClose }: Props) => {
  return (
    <Stack position="relative">
      <DialogTitle sx={{ padding: '24px 32px' }}>
        <Typography variant="h5">Continue for free</Typography>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <Stack gap="8px">
          <Typography variant="h6">Are you sure?</Typography>

          <Typography variant="P12R" color="textSecondary">
            By downgrading to the free plan, you&apos;ll lose access to premium
            features that help you optimize and secure your infrastructure.
            Here&apos;s what will change:
          </Typography>

          <Typography variant="P12R" color="textSecondary">
            <Box
              component="ul"
              sx={{
                listStyleType: 'disc',
                paddingLeft: '16px',
                margin: '0',
              }}
            >
              <li>
                <strong>No more workload rightsizing</strong>, which means your
                resources might not be fully optimized.
              </li>
              <li>
                <strong>Autoscaling will no longer be available</strong>, so
                scaling clusters will need to be managed manually.
              </li>
              <li>
                <strong>
                  No access to Kubernetes Security Posture Management (KSPM)
                </strong>
                , leaving actionable security insights unavailable.
              </li>
              <li>
                <strong>Limited to 5 clusters</strong>, with no possibility to
                add more.
              </li>
            </Box>
          </Typography>

          <Typography variant="P12R" color="textSecondary">
            The loss of these features could significantly affect the efficiency
            and cost of your infrastructure.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px 32px',
          borderTop: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <Button
            data-testid="back-button"
            variant="tertiary"
            onClick={onBack}
            startIcon={<Icons.ArrowLeft />}
          >
            Back
          </Button>

          <Button
            data-testid="confirm-button"
            variant="danger"
            onClick={onConfirm}
          >
            Yes, I&apos;m sure
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  );
};
