import { PriceType } from '@cast/types';

export enum Period {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
}

export enum Instance {
  ALL = 'all instances',
  SPOT = 'spot',
  ON_DEMAND = 'on-demand',
}

export type AvailableSavingsHistoryCostRate =
  | PriceType.HOURLY
  | PriceType.DAILY
  | PriceType.MONTHLY;

export enum HistoryChartDataType {
  CPU = 'cpu',
  MEMORY = 'memory',
  NODES = 'nodes',
}

export enum Chart {
  CURRENT_BAR_CHART = 'currentBarChart',
  OPTIMIZED_BAR_CHART = 'optimizedBarChart',
  CURRENT_COST = 'currentCost',
  OPTIMIZED_COST = 'optimizedCost',
}

export type ChartState = Record<Chart, boolean>;
