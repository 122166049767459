import { SvgIcon } from '@mui/material';

import { Icons } from '@cast/design-system';

import { useThemeColors } from 'hooks/theme';

import { HexIconContainer, HexIconContainerProps } from './_components';

export type HexIconProps = Pick<
  HexIconContainerProps,
  'size' | 'animationType' | 'icon' | 'sx' | 'testId'
> & {
  hexColor?: string;
  iconColor?: string;
  iconSize?: number | string;
};

export const HexIcon = ({
  icon = <Icons.CheckSquare weight="fill" color="#417AE9" />,
  size,
  animationType,
  hexColor,
  iconColor,
  iconSize,
  sx,
  ...props
}: HexIconProps) => {
  [hexColor, iconColor] = useThemeColors(hexColor, iconColor);
  return (
    <Icons.IconContext.Provider
      value={{ color: iconColor, weight: 'fill', size: iconSize }}
    >
      <HexIconContainer
        color={hexColor!}
        size={size}
        animationType={animationType}
        icon={icon}
        sx={sx}
        {...props}
      >
        <SvgIcon viewBox="0 0 65 72" fill="none">
          <path
            d="M12.5241 22.3487C11.2729 23.0595 10.5 24.3876 10.5 25.8266L10.5 46.1718C10.5 47.6107 11.2728 48.9387 12.5238 49.6495L30.523 59.8771C31.7484 60.5734 33.2498 60.5735 34.4752 59.8772L52.476 49.6495C53.7271 48.9387 54.5 47.6106 54.5 46.1717L54.5 25.8267C54.5 24.3876 53.727 23.0595 52.4758 22.3487L34.475 12.1225C33.2497 11.4264 31.7485 11.4264 30.5232 12.1226L12.5241 22.3487Z"
            fill={hexColor}
          />
          <defs>
            <filter
              id="filter0_i_1869_33700"
              x="20.5"
              y="24"
              width="26"
              height="26"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_1869_33700"
              />
            </filter>
          </defs>
        </SvgIcon>
      </HexIconContainer>
    </Icons.IconContext.Provider>
  );
};
