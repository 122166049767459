import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';

import { DATE_SIMPLE } from '@cast/constants';
import {
  demoSavingsProgress,
  demoAllocationGroupNetworkCostSummaries,
  demoAllocationGroupNetworkCostWorkloads,
  demoAllocationGroups,
  demoAllocationGroupSummaries,
  demoAllocationGroupWorkloads,
  demoClusterEfficiencyDetails,
  demoClusterEstimatedSavings,
  demoClusterEstimatedSavingsHistory,
  demoCostReport,
  demoDailyCost,
  demoWorkloadDataTransferCost,
  demoOrganizationClustersSummary,
  demoOrganizationClusterSummary,
  demoResourceUsage,
  demoWorkloadCostReport,
  demoWorkloadEfficiency,
  demoWorkloadEfficiencyDetails,
  demoRightsizingSummary,
  demoWorkloadTrafficDestinations,
  demoWorkloadTrafficDestinationsHistory,
  demoWorkloadLabelNames,
  demoWorkloadLabelValues,
  demoOrganizationClustersReport,
  demoNamespaceCostReport,
  demoWorkloadSummaries,
  demoAllocationGroupTotalCosts,
  demoAllocationGroupsEfficiencySummary,
  demoDataTransferCost,
  demoAllocationGroupsWorkloadsEfficiency,
  demoWorkloadsGpuUtilizationSummary,
  demoWorkloadsGpuWasteCostImpact,
  demoReportCapabilities,
  demoWorkloadGpuUtilizationSummary,
  demoClusterAnomalies,
  demoOrganizationClusterUnscheduledPods,
  demoNamespacesTotalCosts,
  demoNamespacesCostsSummary,
  demoNamespacesNetworkCostsSummary,
  demoNamespacesNetworkTotalCosts,
  demoAllocationGroupTimedSummaries,
  demoClustersEfficiencySummary,
  demoClustersTimedEfficiency,
  demoClustersEfficiencySummaryByCluster,
} from '@cast/fixtures';
import {
  CostReportGetWorkloadsMetadataResponse,
  NamespaceFiltersResponse,
  SingleWorkloadCostReport,
} from '@cast/types';
import { isDemoMode } from '@cast/utils';

import { CostReportApiAdapter } from '../api-client/cost-report-api-adapter';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  rejectWithErrorMessage,
  resolveDemoClusterRequest,
} from '../utils';

export const CostReportApiProxy = (
  origin: ReturnType<typeof CostReportApiAdapter>
) => {
  return {
    ...origin,
    getOrganizationClustersReport: async (
      ...args: Parameters<typeof origin.getOrganizationClustersReport>
    ) => {
      if (isDemoMode()) {
        try {
          let fixture = await demoOrganizationClustersReport();
          let dailyCosts = fixture.totalDailyCost?.map((item) => ({
            timestamp: dayjs.utc(item.timestamp).format(DATE_SIMPLE),
            value: item.value,
          }));
          const groupedCosts = groupBy(dailyCosts, 'timestamp');

          dailyCosts = values(
            map(groupedCosts, (group, timestamp) => ({
              timestamp,
              value: sumBy(group, (item) => parseFloat(item.value || '0')) + '',
            }))
          ).filter((item) =>
            dayjs.utc(item.timestamp).isSameOrAfter(args[0]?.startTime)
          );

          // Need to extend by 1 day to align with backend timestamp boundaries, when the current day represents midnight of the next day
          const lastDailyCostItem = dailyCosts[dailyCosts.length - 1];
          dailyCosts = [
            ...dailyCosts,
            {
              ...lastDailyCostItem,
              timestamp: dayjs
                .utc(lastDailyCostItem.timestamp)
                .add(1, 'day')
                .toISOString(),
            },
          ];

          fixture = {
            ...fixture,
            totalDailyCost: dailyCosts,
            topClustersCost: fixture.topClustersCost?.map((item) => ({
              ...item,
              items: dailyCosts,
            })),
          };
          return resolveDemoClusterRequest(fixture);
        } catch (error) {
          console.error(error);
          return rejectWithErrorMessage('Something went wrong!');
        }
      }

      return origin.getOrganizationClustersReport(...args);
    },
    getOrganizationClustersDailyCosts: async (
      ...args: Parameters<typeof origin.getOrganizationClustersDailyCosts>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoDailyCost();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getOrganizationClustersDailyCosts(...args);
    },
    getEstimatedSavings: async (
      ...args: Parameters<typeof origin.getEstimatedSavings>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterEstimatedSavings();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getEstimatedSavings(...args);
    },
    getRightsizingSummary: async (
      ...args: Parameters<typeof origin.getRightsizingSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoRightsizingSummary();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getRightsizingSummary(...args);
    },

    getCostHistory: async (
      ...args: Parameters<typeof origin.getCostHistory>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterEstimatedSavingsHistory();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getCostHistory(...args);
    },
    getCostReport: async (...args: Parameters<typeof origin.getCostReport>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoCostReport();
        return resolveDemoClusterRequest({
          ...fixture,
          items: fixture.items?.filter((entry) =>
            dayjs(entry.timestamp).isBetween(
              dayjs(args[0]?.startTime),
              dayjs(args[0]?.endTime)
            )
          ),
        });
      }

      return origin.getCostReport(...args);
    },
    getWorkloadLabelNames: async (
      ...args: Parameters<typeof origin.getWorkloadLabelNames>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadLabelNames();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadLabelNames(...args);
    },
    getWorkloadLabelValues: async (
      ...args: Parameters<typeof origin.getWorkloadLabelValues>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadLabelValues();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadLabelValues(...args);
    },
    getNamespaces: async (...args: Parameters<typeof origin.getNamespaces>) => {
      if (isDemoClusterRequest(args)) {
        const { clusterId, items } = await demoWorkloadCostReport(
          args[0].startTime,
          args[0].endTime
        );
        const namespaces = items?.map((item) => item.namespace || '') || [];
        const response: NamespaceFiltersResponse = {
          clusterNamespaces: [
            {
              clusterId,
              namespaces,
            },
          ],
        };
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespaces(...args);
    },
    getNamespace: async (...args: Parameters<typeof origin.getNamespace>) => {
      if (isDemoClusterRequest(args)) {
        const response = await demoNamespaceCostReport(
          args[0]?.clusterId,
          args[0]?.namespace,
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespace(...args);
    },
    getNamespacesCostSummary: async (
      ...args: Parameters<typeof origin.getNamespacesCostSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const response = await demoNamespacesCostsSummary();
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespacesCostSummary(...args);
    },
    getNamespacesTotalCosts: async (
      ...args: Parameters<typeof origin.getNamespacesTotalCosts>
    ) => {
      if (isDemoClusterRequest(args)) {
        const response = await demoNamespacesTotalCosts(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespacesTotalCosts(...args);
    },
    getNamespacesDataTransferCostSummary: async (
      ...args: Parameters<typeof origin.getNamespacesDataTransferCostSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const response = await demoNamespacesNetworkCostsSummary();
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespacesDataTransferCostSummary(...args);
    },
    getNamespacesDataTransferTotalCosts: async (
      ...args: Parameters<typeof origin.getNamespacesDataTransferTotalCosts>
    ) => {
      if (isDemoClusterRequest(args)) {
        const response = await demoNamespacesNetworkTotalCosts(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(response);
      }

      return origin.getNamespacesDataTransferTotalCosts(...args);
    },
    getWorkloadsMetadata: async (
      ...args: Parameters<typeof origin.getWorkloadsMetadata>
    ) => {
      if (isDemoClusterRequest(args)) {
        const { clusterId, items } = await demoWorkloadCostReport(
          args[0]?.startTime,
          args[0]?.endTime
        );
        const response: CostReportGetWorkloadsMetadataResponse = {
          workloads: items?.map((item) => ({
            clusterId,
            namespace: item.namespace,
            workloadName: item.workloadName,
            workloadType: item.workloadType,
          })),
        };
        return resolveDemoClusterRequest(response);
      }

      return origin.getWorkloadsMetadata(...args);
    },

    getWorkloadsCostReport: async (
      ...args: Parameters<typeof origin.getWorkloadsCostReport>
    ): ReturnType<typeof origin.getWorkloadsCostReport> => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadCostReport(
          args[0]?.startTime,
          args[0]?.endTime,
          args[0]?.filter
        );
        // const fixture = await demoCostReportWorkloadCosts();
        const pageLimit = parseInt(args[0]?.pageLimit || '5', 10);
        return resolveDemoClusterRequest({
          ...fixture,
          items: fixture.items?.slice(0, pageLimit),
        });
      }

      return origin.getWorkloadsCostReport(...args);
    },

    getWorkloadsCostSummaries: async (
      ...args: Parameters<typeof origin.getWorkloadsCostSummaries>
    ): ReturnType<typeof origin.getWorkloadsCostSummaries> => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadSummaries(
          args[0]?.startTime,
          args[0]?.endTime,
          args[0]?.filter
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadsCostSummaries(...args);
    },

    getSingleWorkloadCostReport: async (
      ...args: Parameters<typeof origin.getSingleWorkloadCostReport>
    ) => {
      if (isDemoClusterRequest(args)) {
        const { clusterId, items } = await demoWorkloadCostReport(
          args[0]?.startTime,
          args[0]?.endTime
        );
        const workload = items?.find(
          (item) =>
            item.namespace === args[0]?.namespace &&
            item.workloadType === args[0]?.workloadType &&
            item.workloadName === args[0]?.workloadName
        );
        const fixture: SingleWorkloadCostReport = { clusterId, item: workload };
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getSingleWorkloadCostReport(...args);
    },

    getWorkloadEfficiency: async (
      ...args: Parameters<typeof origin.getWorkloadEfficiency>
    ) => {
      if (isDemoClusterRequest(args)) {
        const startTime = args[0]?.startTime;
        const endTime = args[0]?.endTime;
        const fixture = await demoWorkloadEfficiency(startTime);
        return resolveDemoClusterRequest({
          ...fixture,
          topItems: fixture.topItems?.map((item) => ({
            ...item,
            costImpactHistory: item.costImpactHistory?.filter((entry) =>
              dayjs(entry.timestamp).isBetween(dayjs(startTime), dayjs(endTime))
            ),
          })),
        });
      }

      return origin.getWorkloadEfficiency(...args);
    },

    getAllocationGroups: async (
      ...args: Parameters<typeof origin.getAllocationGroups>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroups();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroups(...args);
    },

    getAllocationGroupSummaries: async (
      ...args: Parameters<typeof origin.getAllocationGroupSummaries>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupSummaries(args[0].groupId);
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupSummaries(...args);
    },

    getAllocationGroupTotalCosts: async (
      ...args: Parameters<typeof origin.getAllocationGroupTotalCosts>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupTotalCosts(
          args[0].startTime,
          args[0].endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupTotalCosts(...args);
    },

    getAllocationGroupTimedSummaries: async (
      ...args: Parameters<typeof origin.getAllocationGroupTimedSummaries>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupTimedSummaries(
          args[0].startTime,
          args[0].endTime,
          args[0].groupId
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupTimedSummaries(...args);
    },

    getAllocationGroupWorkloads: async (
      ...args: Parameters<typeof origin.getAllocationGroupWorkloads>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupWorkloads(args[0]?.groupId);
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupWorkloads(...args);
    },

    getAllocationGroupDataTransferSummary: async (
      ...args: Parameters<typeof origin.getAllocationGroupDataTransferSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupNetworkCostSummaries();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupDataTransferSummary(...args);
    },

    getAllocationGroupDataTransferWorkloads: async (
      ...args: Parameters<typeof origin.getAllocationGroupDataTransferWorkloads>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupNetworkCostWorkloads(
          args[0]?.groupId
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupDataTransferWorkloads(...args);
    },

    getAllocationGroupEfficiencySummary: async (
      ...args: Parameters<typeof origin.getAllocationGroupEfficiencySummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupsEfficiencySummary(
          args[0]?.startTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupEfficiencySummary(...args);
    },

    getAllocationGroupWorkloadsEfficiency: async (
      ...args: Parameters<typeof origin.getAllocationGroupWorkloadsEfficiency>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAllocationGroupsWorkloadsEfficiency();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAllocationGroupWorkloadsEfficiency(...args);
    },

    createAllocationGroup: (
      ...args: Parameters<typeof origin.createAllocationGroup>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.createAllocationGroup(...args);
    },

    updateAllocationGroup: (
      ...args: Parameters<typeof origin.updateAllocationGroup>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateAllocationGroup(...args);
    },

    deleteAllocationGroup: (
      ...args: Parameters<typeof origin.deleteAllocationGroup>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteAllocationGroup(...args);
    },

    getWorkloadEfficiencyDetails: async (
      ...args: Parameters<typeof origin.getWorkloadEfficiencyDetails>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadEfficiencyDetails(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadEfficiencyDetails(...args);
    },
    getOrganizationClustersSummary: async (
      ...args: Parameters<typeof origin.getOrganizationClustersSummary>
    ) => {
      if (isDemoMode()) {
        const fixture = await demoOrganizationClustersSummary();
        return resolveDemoClusterRequest(fixture);
      }

      return await origin.getOrganizationClustersSummary(...args);
    },
    getClusterSummary: async (
      ...args: Parameters<typeof origin.getClusterSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoOrganizationClusterSummary();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getClusterSummary(...args);
    },
    getResourceUsage: async (
      ...args: Parameters<typeof origin.getResourceUsage>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoResourceUsage(
          args[0]?.startTime,
          args[0]?.endTime,
          args[0]?.stepSeconds ?? 600
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getResourceUsage(...args);
    },
    getUnscheduledPods: async (
      ...args: Parameters<typeof origin.getUnscheduledPods>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoOrganizationClusterUnscheduledPods();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getUnscheduledPods(...args);
    },
    getDataTransferCost: async (
      ...args: Parameters<typeof origin.getDataTransferCost>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoDataTransferCost(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getDataTransferCost(...args);
    },
    getWorkloadDataTransferCost: async (
      ...args: Parameters<typeof origin.getWorkloadDataTransferCost>
    ) => {
      if (isDemoClusterRequest(args)) {
        const listFixture = await demoWorkloadDataTransferCost(
          args[0]?.endTime
        );
        const fixture = {
          clusterId: listFixture.clusterId,
          egressdStatus: listFixture.egressdStatus,
          item:
            listFixture.items?.find(
              (i) =>
                i.workloadName === args[0]?.workloadName &&
                i.namespace === args[0]?.namespace
            ) ?? null,
        };

        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadDataTransferCost(...args);
    },
    getClusterEfficiencyDetails: async (
      ...args: Parameters<typeof origin.getClusterEfficiencyDetails>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterEfficiencyDetails(
          args[0]?.startTime,
          args[0]?.endTime,
          args[0]?.stepSeconds
        );
        return resolveDemoClusterRequest(fixture);
      }
      return origin.getClusterEfficiencyDetails(...args);
    },
    getSavingsProgress: async (
      ...args: Parameters<typeof origin.getSavingsProgress>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoSavingsProgress(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }
      return origin.getSavingsProgress(...args);
    },
    getWorkloadTrafficDestinations: async (
      ...args: Parameters<typeof origin.getWorkloadTrafficDestinations>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadTrafficDestinations();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadTrafficDestinations(...args);
    },
    getWorkloadTrafficDestinationsHistory: async (
      ...args: Parameters<typeof origin.getWorkloadTrafficDestinationsHistory>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadTrafficDestinationsHistory(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadTrafficDestinationsHistory(...args);
    },
    getWorkloadsGpuUtilizationSummary: async (
      ...args: Parameters<typeof origin.getWorkloadsGpuUtilizationSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadsGpuUtilizationSummary();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadsGpuUtilizationSummary(...args);
    },
    getWorkloadsWastedGPUCostImpact: async (
      ...args: Parameters<typeof origin.getWorkloadsWastedGPUCostImpact>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadsGpuWasteCostImpact(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadsWastedGPUCostImpact(...args);
    },
    getReportCapabilities: async (
      ...args: Parameters<typeof origin.getReportCapabilities>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoReportCapabilities();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getReportCapabilities(...args);
    },
    getWorkloadGpuUtilizationSummary: async (
      ...args: Parameters<typeof origin.getWorkloadGpuUtilizationSummary>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoWorkloadGpuUtilizationSummary(
          args[0]?.startTime,
          args[0]?.endTime
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloadGpuUtilizationSummary(...args);
    },
    getClusterAnomalies: async (
      ...args: Parameters<typeof origin.getClusterAnomalies>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterAnomalies();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getClusterAnomalies(...args);
    },

    getOrganizationClustersEfficiencySummary: async (
      ...args: Parameters<
        typeof origin.getOrganizationClustersEfficiencySummary
      >
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClustersEfficiencySummary();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getOrganizationClustersEfficiencySummary(...args);
    },

    getOrganizationClustersTimedEfficiency: async (
      ...args: Parameters<typeof origin.getOrganizationClustersTimedEfficiency>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClustersTimedEfficiency();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getOrganizationClustersTimedEfficiency(...args);
    },

    getOrganizationClustersEfficiencyByCluster: async (
      ...args: Parameters<
        typeof origin.getOrganizationClustersEfficiencyByCluster
      >
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClustersEfficiencySummaryByCluster();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getOrganizationClustersEfficiencyByCluster(...args);
    },
  };
};
