import { Stack, StackProps, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { Icons, mergeSx } from '@cast/design-system';
import { isDemoMode } from '@cast/utils';

import { useInitializeTrialDialog } from './dialogs/useInitializeTrialDialog';
import { useTrialHasEndedDialog } from './dialogs/useTrialHasEndedDialog';
import { useTrialInProgressDialog } from './dialogs/useTrialInProgressDialog';
import { useTrial } from '../../core/trial/TrialProvider';

type BadgeBoxProps = StackProps & {
  text: string;
  icon: React.ReactNode;
};

const BadgeBox = ({ text, icon, sx, ...props }: BadgeBoxProps) => {
  return (
    <Stack
      data-testid="trial-badge"
      direction="row"
      alignItems="center"
      sx={mergeSx(
        {
          position: 'absolute',
          top: '10px',
          left: '0',
          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'currentColor',
          color: 'green.600',
          backgroundColor: 'green.50',
          padding: '4px 8px',
          gap: '4px',
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.8,
          },
        },
        sx
      )}
      {...props}
    >
      <Typography variant="P14M" color="inherit">
        {text}
      </Typography>

      {icon}
    </Stack>
  );
};

export const TrialBadge = () => {
  const { isEligible, hasDowngraded, isAboutToExpire, state, expiresInDays } =
    useTrial();
  const { openInitializeTrialDialog } = useInitializeTrialDialog();
  const { openTrialInProgressDialog } = useTrialInProgressDialog();
  const { openTrialHasEndedDialog } = useTrialHasEndedDialog();

  if (
    isDemoMode() ||
    !isEligible || // user is not eligible for trial
    (isEligible && hasDowngraded) // trial expired, user choose free plan
  ) {
    return null;
  }

  if (state === 'post-trial') {
    return (
      <BadgeBox
        text="Trial expired"
        icon={<Icons.Clock weight="bold" />}
        sx={{ color: 'red.400', backgroundColor: 'red.50' }}
        onClick={() => openTrialHasEndedDialog()}
      />
    );
  }

  if (state === 'active') {
    if (isAboutToExpire) {
      return (
        <BadgeBox
          text={
            expiresInDays === 0
              ? 'Trial expires today'
              : `Trial expires in ${expiresInDays} ${pluralize(
                  'day',
                  expiresInDays
                )}`
          }
          icon={<Icons.Clock weight="bold" />}
          sx={{ color: 'red.400', backgroundColor: 'red.50' }}
          onClick={() => openTrialInProgressDialog()}
        />
      );
    }

    return (
      <BadgeBox
        text={`Trial: ${expiresInDays} ${pluralize('day', expiresInDays)} left`}
        icon={<Icons.Clock weight="bold" />}
        onClick={() => openTrialInProgressDialog()}
      />
    );
  }

  return (
    <BadgeBox
      text="Start free trial"
      icon={<Icons.Lightning weight="bold" />}
      onClick={() => openInitializeTrialDialog()}
    />
  );
};
