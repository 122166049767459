import { useMemo } from 'react';

import { Stack, Typography } from '@mui/material';
import * as yup from 'yup';

import {
  Button,
  DialogActions,
  DialogContent,
  useDialogInstance,
} from '@cast/design-system';
import { Cluster, ClusterPricing } from '@cast/types';
import { makeValidateSync } from '@cast/utils';

import { RffForm, RffInput } from 'core/forms/rff';
import { notify } from 'core/snackbars';
import { useUpdateCastAnywherePricingMutation } from 'hooks/mutations/pricing';

import { InputsSection } from './InputsSection';
import { pricingToFormModel } from './utils';

export const validate = makeValidateSync(
  yup.object({
    cpuHourlyPrice: yup.number().required('Required'),
    memoryGibHourlyPrice: yup.number().required('Required'),
  })
);

type Props = {
  cluster: Cluster;
  pricing?: ClusterPricing;
  showCancel?: boolean;
  showComingSoonFields?: boolean;
};

export const SetupCastAnywhere = ({
  cluster,
  pricing,
  showCancel,
  showComingSoonFields,
}: Props) => {
  const { destroy } = useDialogInstance();

  const { mutate: updatePricing, isPending: isUpdating } =
    useUpdateCastAnywherePricingMutation();

  const initialValues = useMemo(() => pricingToFormModel(pricing), [pricing]);

  return (
    <Stack data-testid="cast-anywhere-pricing-form">
      <DialogContent sx={{ py: 0 }}>
        <Typography variant="A1" mb={2}>
          Provide Resource Costs
        </Typography>
        <Typography variant="P12R" color="textSecondary" mb={16}>
          Please enter the normalized costs for your resources. This will help
          us accurately calculate your cloud costs.
        </Typography>
        <Typography variant="L10M" color="grey.400" mb={8}>
          Enter normalised costs
        </Typography>
      </DialogContent>
      <RffForm
        onSubmit={(values: ClusterPricing) => {
          updatePricing(
            {
              priceClusterId: cluster.id,
              priceOrganizationId: cluster.organizationId!,
              price: {
                cpuHourlyPrice: String(values.cpuHourlyPrice),
                memoryGibHourlyPrice: String(values.memoryGibHourlyPrice),
              },
            },
            {
              onSuccess: () => {
                destroy?.();
                notify.success('Cluster pricing saved');
              },
              onError: () => {
                notify.error('Failed to save pricing');
              },
            }
          );
        }}
        initialValues={initialValues}
        validate={validate}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'grey.100',
            py: 16,
            gap: 16,
          }}
        >
          <InputsSection
            title="Compute cost"
            inputA={
              <RffInput
                name="cpuHourlyPrice"
                label="CPU"
                type="number"
                endAdornment="$/h"
                testId="cpu-hourly-price"
              />
            }
            inputB={
              <RffInput
                name="memoryGibHourlyPrice"
                label="Memory"
                type="number"
                endAdornment="$/h"
                hintAbsolute
                testId="memory-hourly-price"
              />
            }
          />
          {showComingSoonFields && (
            <>
              <InputsSection
                title="Network cost"
                inputA={
                  <RffInput
                    name="networkIngressHourlyPrice"
                    label="Ingress"
                    type="number"
                    endAdornment="$/h"
                    hintAbsolute
                  />
                }
                inputB={
                  <RffInput
                    name="networkEgressHourlyPrice"
                    label="Egress"
                    type="number"
                    endAdornment="$/h"
                    hintAbsolute
                  />
                }
                comingSoon
              />
              <InputsSection
                title="Storage cost"
                inputA={
                  <RffInput
                    name="storageHourlyPrice"
                    label="Storage"
                    type="number"
                    endAdornment="$/h"
                    hintAbsolute
                  />
                }
                comingSoon
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ gap: 8, pr: 32 }}>
          {showCancel && (
            <Button variant="tertiary" onClick={destroy}>
              Cancel
            </Button>
          )}
          <Button type="submit" loading={isUpdating} testId="save-button">
            Save
          </Button>
        </DialogActions>
      </RffForm>
    </Stack>
  );
};
