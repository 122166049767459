import { Divider, Stack } from '@mui/material';

import { GreenCheckIcon, GreyCheckIcon, RedXIcon } from './icons';
import { TrialListItemWithIcon } from './TrialListItemWithIcon';
import { AccountType } from '../../../types/accounts';

type Props = {
  type: AccountType;
  isTrialActive: boolean;
};

export const AccountTypeDescription = ({ type, isTrialActive }: Props) => {
  if (type === 'free') {
    return (
      <Stack direction="column" gap="8px">
        <TrialListItemWithIcon
          icon={<GreyCheckIcon />}
          text="Basic cost monitoring"
        />
        <TrialListItemWithIcon
          icon={<GreyCheckIcon />}
          text="Up to 5 connected clusters"
        />
        <TrialListItemWithIcon
          icon={<GreyCheckIcon />}
          text="30-day data retention"
        />

        <Divider />

        <TrialListItemWithIcon
          icon={<RedXIcon />}
          text="Automated cost optimization"
        />
        <TrialListItemWithIcon
          icon={<RedXIcon />}
          text="Automated workload rightsizing"
        />
        <TrialListItemWithIcon
          icon={<RedXIcon />}
          text="Advanced Kubernetes Security Posture Management (KSPM)"
        />

        {!isTrialActive && (
          <>
            <Divider />

            <TrialListItemWithIcon icon={<span />} text="Free forever" />
          </>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap="8px">
      <TrialListItemWithIcon
        icon={<GreenCheckIcon />}
        text="Automated cost optimization"
      />
      <TrialListItemWithIcon
        icon={<GreenCheckIcon />}
        text="Connect unlimited clusters"
      />
      <TrialListItemWithIcon
        icon={<GreenCheckIcon />}
        text="Automated workload rightsizing"
      />

      <TrialListItemWithIcon
        icon={<GreenCheckIcon />}
        text="Advanced Kubernetes Security Posture Management (KSPM)"
      />

      {!isTrialActive && (
        <>
          <Divider />

          <TrialListItemWithIcon
            icon={<span />}
            text="30-day free trial. Revert back to the limited version after the trial ends"
          />
        </>
      )}
    </Stack>
  );
};
