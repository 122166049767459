import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';

import { useTheme, Popover, PopperProps, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { bindToggle } from 'material-ui-popup-state/core';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

import {
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  mergeSx,
  Icons,
  ListItemIcon,
  IconButtonProps,
  IconButton,
  ListProps,
  TooltipProps,
  ConditionalWrapper,
  Tooltip,
  useTableContext,
} from '@cast/design-system';
import { pseudoUnique } from '@cast/utils';

type EntityActionProps = ListItemProps & {
  disruptive?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  subtext?: string;
  primaryTextPros?: TypographyProps<'span', { component?: 'span' }>;
};
export const EntityAction = ({
  children,
  sx,
  disruptive,
  tooltipProps,
  subtext,
  primaryTextPros,
  ...rest
}: EntityActionProps) => {
  return (
    <ConditionalWrapper
      condition={Boolean(tooltipProps)}
      wrapper={(child: ReactNode) => (
        <Tooltip {...tooltipProps!}>
          <div>{child}</div>
        </Tooltip>
      )}
    >
      <ListItem
        sx={mergeSx(
          {
            display: 'flex',
            gap: '4px',
            color: rest.disabled
              ? 'grey.300'
              : disruptive
              ? 'red.500'
              : 'grey.900',
            paddingY: '4px',
          },
          sx
        )}
        {...rest}
        startAdornment={
          rest.startAdornment ? (
            <ListItemIcon color="inherit">{rest.startAdornment}</ListItemIcon>
          ) : null
        }
        startAdornmentSx={mergeSx(
          {
            '&&': {
              color: rest.disabled
                ? 'grey.300'
                : disruptive
                ? 'red.500'
                : 'grey.400',
            },
          },
          rest.startAdornmentSx
        )}
      >
        <ListItemText
          primaryTypographyProps={{
            typography: 'P12R',
            color: rest.disabled
              ? 'grey.300'
              : disruptive
              ? 'red.500'
              : 'grey.900',
            sx: {
              opacity: rest.disabled ? 0.6 : undefined,
              display: 'flex',
              flexDirection: 'row',
            },
            ...primaryTextPros,
          }}
          secondary={subtext}
        >
          {children}
        </ListItemText>
      </ListItem>
    </ConditionalWrapper>
  );
};

export type EntityActionsProps = PropsWithChildren<{
  triggerButtonProps?: IconButtonProps;
  listProps?: ListProps;
  icon?: ReactElement;
  container?: PopperProps['container'];
  stopClickEventBubbling?: boolean;
  testId?: string;
}>;

export const EntityActions = ({
  triggerButtonProps,
  listProps,
  children,
  icon,
  container,
  stopClickEventBubbling = true,
  testId = 'entity-actions',
}: EntityActionsProps) => {
  const popupId = useMemo(pseudoUnique, []);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${popupId}-actions`,
  });
  const theme = useTheme();

  const isInTable = !!useTableContext();
  if (!icon) {
    icon = isInTable ? (
      <Icons.DotsThree size={16} />
    ) : (
      <Icons.DotsThreeVertical size={16} />
    );
  }

  let toggleIcon = icon;

  if (triggerButtonProps?.children) {
    if (isValidElement(triggerButtonProps.children)) {
      toggleIcon = cloneElement(triggerButtonProps.children, {
        className: clsx(
          triggerButtonProps?.className,
          'EntityActions-toggleIcon'
        ),
      } as IconButtonProps);
    }
  }

  const _children = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        ...child.props,
        onClick: (event: MouseEvent) => {
          popupState.close();
          child.props.onClick?.(event);
        },
      });
    }
    return child;
  });

  const buttonProps = bindToggle(popupState);
  const popoverProps = bindPopover(popupState);

  return (
    <>
      <IconButton
        data-testid={testId}
        {...buttonProps}
        onClick={(event: MouseEvent) => {
          if (stopClickEventBubbling) {
            event.stopPropagation();
          }
          buttonProps.onClick(event);
        }}
        variant="ghost"
        size="small"
        {...triggerButtonProps}
        sx={mergeSx(
          {
            padding: 0,
            ...(popupState.isOpen && {
              backgroundColor: 'grey.100',
              border: 'grey.300',

              '.EntityActions-toggleIcon': {
                color: 'blue.600',
              },
            }),
          },
          triggerButtonProps?.sx
        )}
      >
        {toggleIcon}
      </IconButton>

      <Popover
        {...popoverProps}
        sx={{
          mt: 4,
          zIndex: theme.zIndex.drawer + 1,
          '& ul li:not(:first-of-type)': {
            borderTop: '1px solid',
            borderColor: 'grey.100',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        container={container}
        onClick={
          stopClickEventBubbling
            ? (event) => {
                event.stopPropagation();
              }
            : undefined
        }
        disableScrollLock
      >
        <List
          size="small"
          {...listProps}
          sx={mergeSx({ minWidth: 109 }, listProps?.sx)}
          testId={`${testId}-list`}
        >
          {_children}
        </List>
      </Popover>
    </>
  );
};
