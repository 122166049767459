import { PropsWithChildren, useContext } from 'react';

import { RffControlsGroupContext } from './context';
import { RffControlsGroupState } from './types';

export const RffControlsGroup = ({
  children,
  disabled,
  readOnly,
  namePrefix,
}: PropsWithChildren<RffControlsGroupState>) => {
  const parentState = useContext(RffControlsGroupContext);

  return (
    <RffControlsGroupContext.Provider
      value={{
        disabled: disabled || parentState.disabled,
        readOnly: readOnly || parentState.readOnly,
        namePrefix: namePrefix
          ? `${
              parentState.namePrefix
                ? `${parentState.namePrefix}.${namePrefix}`
                : namePrefix
            }`
          : parentState.namePrefix,
      }}
    >
      {children}
    </RffControlsGroupContext.Provider>
  );
};
