import { Stack } from '@mui/material';

import { Button } from '@cast/design-system';

import {
  DialogFeedbackLayout,
  DialogFeedbackMessage,
} from 'components/dialogs';

type FeedbackMessageProps = {
  success: boolean;
  onClose: () => void;
  onContinue: () => void;
};

export const FeedbackMessageSlide = ({
  success,
  onClose,
  onContinue,
}: FeedbackMessageProps) => {
  const variant = success ? 'success' : 'error';

  return (
    <DialogFeedbackLayout
      variant={variant}
      actions={<Button onClick={onContinue}>Close</Button>}
      onClose={onClose}
    >
      <DialogFeedbackMessage
        variant={variant}
        title={success ? 'Great!' : 'Opps, something went wrong'}
        body={
          success ? (
            <Stack direction="row" gap={8} alignItems="center">
              Your trial version launched successfully!
              <br />
              All premium features are now available. Connect your cluster to
              get started.
            </Stack>
          ) : (
            `Please try again later`
          )
        }
      />
    </DialogFeedbackLayout>
  );
};
