import { useQuery } from '@tanstack/react-query';

import { K8sProvider } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { RosaClusterOnboardingParameters } from 'types/cluster';

type UseAgentScriptQuery = {
  isLoading: boolean;
  isFetching: boolean;
  script?: string;
  refetch: () => void;
  error: unknown;
};

type AgentScriptParameters = {
  provider?: `${K8sProvider}`;
  rosa?: RosaClusterOnboardingParameters;
  anywhere?: { clusterName: string };
};

export const useAgentScriptQuery = ({
  provider,
  rosa,
  anywhere,
}: AgentScriptParameters): UseAgentScriptQuery => {
  const {
    isLoading,
    isFetching,
    data: script,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKeys.EXTERNAL_CLUSTER_REGISTRATION_SCRIPT,
      provider,
      JSON.stringify(rosa || {}),
      JSON.stringify(anywhere || {}),
    ],
    queryFn: async () => {
      const { data } = await apiClient.autoscaler.getAgentInstallScript({
        provider,
        openshiftRunAsUser: rosa?.runAsUser,
        openshiftRunAsGroup: rosa?.runAsGroup,
        openshiftFsGroup: rosa?.fsGroup,
        anywhereClusterName: anywhere?.clusterName,
      });
      return data;
    },
    enabled: !!provider && (!anywhere || !!anywhere.clusterName),
  });

  return {
    isLoading,
    isFetching,
    script: script as unknown as string,
    error,
    refetch,
  };
};
