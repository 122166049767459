import { Stack, Typography } from '@mui/material';
import * as yup from 'yup';

import { Button, Icons } from '@cast/design-system';
import { makeValidateSync } from '@cast/utils';

import { RffForm, RffInput } from 'core/forms/rff';

export const validate = makeValidateSync(
  yup.object({
    clusterName: yup.string().required('Cluster name is required'),
  })
);

type Props = {
  onGenerateScript: (name: string) => void;
  wasScriptGenerated: boolean;
  isScriptGenerating: boolean;
};

export const CastAnywhereSection = ({
  onGenerateScript,
  wasScriptGenerated,
  isScriptGenerating,
}: Props) => {
  return (
    <Stack borderBottom="grey.100" mx={-32} px={32} pb={16}>
      <Typography variant="A1" mb={2}>
        Connect any Kubernetes cluster with internet access
      </Typography>
      <Typography variant="P12R" mb={16}>
        Connect any Kubernetes cluster with internet access, whether it&apos;s
        on-premises, self-hosted, or from other cloud provider.
      </Typography>
      <RffForm
        onSubmit={({ clusterName }: { clusterName: string }) =>
          onGenerateScript(clusterName)
        }
        initialValues={{ clusterName: '' }}
        validate={validate}
        subscription={{ values: true }}
      >
        {({ values }) => (
          <RffInput
            name="clusterName"
            label="Enter cluster name:"
            size="small"
            disabled={isScriptGenerating}
            endOutsideAdornment={
              wasScriptGenerated ? (
                <Button
                  size="small"
                  variant="text"
                  startIcon={<Icons.ArrowCounterClockwise />}
                  type="submit"
                  loading={isScriptGenerating}
                  disabled={!values.clusterName}
                  sx={{ ml: 16 }}
                >
                  Regenerate script
                </Button>
              ) : (
                <Button
                  size="small"
                  type="submit"
                  loading={isScriptGenerating}
                  disabled={!values.clusterName}
                  sx={{ ml: 16 }}
                  testId="generate-script-button"
                >
                  Generate script
                </Button>
              )
            }
            sx={{
              width: '100%',
              '& .DS-InputLabel-label': {
                typography: 'L10M',
                pb: 4,
                color: 'grey.400',
              },
            }}
            testId="anywhere-cluster-name-input"
          />
        )}
      </RffForm>
    </Stack>
  );
};
