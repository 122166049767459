import { Stack, Typography } from '@mui/material';

import { Radio, RadioProps } from '@cast/design-system';

import { AccountTypeDescription } from './AccountTypeDescription';
import { useTrial } from '../../../core/trial';
import { AccountType } from '../../../types/accounts';
import { ExternalLink } from '../../router/ExternalLink';

type Props = Omit<RadioProps, 'onClick'> & {
  type: AccountType;
  onClick: () => void;
};

export const SelectAccountTypeRadio = ({
  type,
  onClick,
  checked,
  ...props
}: Props) => {
  const { state } = useTrial();

  return (
    <Stack
      data-testid={`select-account-type-radio-${type}`}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        border: '1px solid',
        borderColor: checked ? 'blue.500' : 'grey.200',
        borderRadius: '8px',
        padding: '12px',
        gap: '12px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap="8px">
          <Radio size="small" checked={checked} {...props} />
          <Typography variant="A1">
            {state === 'active'
              ? type === 'free'
                ? 'Continue for free'
                : 'Upgrade to premium'
              : type === 'free'
              ? 'Free version'
              : 'Trial version'}
          </Typography>
        </Stack>
        {type === 'paid' && state !== 'active' && (
          <Typography variant="P10M">
            <ExternalLink link="">Learn more</ExternalLink>
          </Typography>
        )}
      </Stack>
      <AccountTypeDescription type={type} isTrialActive={state === 'active'} />
    </Stack>
  );
};
