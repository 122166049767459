import { FeatureFlagResolved, OrganizationRole } from '@cast/types';

import { useOrganizations } from 'hooks/useOrganizations';

import { ConsoleRules, UseRules } from '../types';
import { ruleBuilder as ruleBuilderV1 } from '../utils/rule-builder.v1';

export const useRulesV1 = (
  flags: FeatureFlagResolved[],
  subjectId?: string
): UseRules => {
  const { currentOrganization } = useOrganizations();

  const rules: ConsoleRules[] = [];

  if (subjectId && currentOrganization && currentOrganization?.id) {
    rules.push(
      ...ruleBuilderV1(
        subjectId,
        currentOrganization.id,
        // Viewer role will be used as fallback for RBACv2 migration
        (currentOrganization as unknown as { role?: OrganizationRole }).role ??
          OrganizationRole.viewer,
        flags
      )
    );
  }

  return {
    isLoading: false,
    rules,
    error: undefined,
  };
};
