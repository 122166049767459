import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Organization as ApiOrganization, Organization } from '@cast/types';

import { useAuth } from 'core/auth/hooks/useAuth';
import { apiClient, QueryKeys } from 'core/react-query';

type UseOrganizationsQuery = {
  isLoading: boolean;
  organizations?: Organization[];
  error: unknown;
};

export const makeOrganizations = (organizations?: ApiOrganization[]) => {
  return organizations?.map((organization) => ({
    ...organization,
    name: organization.name || 'My Organization',
  }));
};

export const useOrganizationsQuery = (): UseOrganizationsQuery => {
  const { isAuthenticated } = useAuth();
  const { isLoading, data, error } = useQuery({
    queryKey: [QueryKeys.ORGANIZATIONS],
    queryFn: async () => {
      const { data } = await apiClient.user.listOrganizations();
      return (data?.organizations as ApiOrganization[] | undefined) || [];
    },
    enabled: isAuthenticated,
    staleTime: 86_400_000,
  });

  const organizationsWithDefaults = useMemo(
    () => makeOrganizations(data),
    [data]
  );

  return {
    isLoading,
    organizations: organizationsWithDefaults as Organization[],
    error,
  };
};
