import { Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@cast/design-system';

import { EXTERNAL_LINKS } from '../../../../common/links';
import { useTrial } from '../../../../core/trial/TrialProvider';
import { CloseDialogButton } from '../../../dialogs';
import { ExternalLink } from '../../../router/ExternalLink';
import { AccountTypeDescription } from '../../_components/AccountTypeDescription';

type Props = {
  onSelfCheckout: () => void;
  onScheduleCall: () => void;
  onClose: () => void;
};

export const InProgressSlide = ({
  onSelfCheckout,
  onScheduleCall,
  onClose,
}: Props) => {
  const { expiresInDays, isAboutToExpire } = useTrial();

  return (
    <Stack data-testid="in-progress-slide" position="relative">
      <DialogTitle sx={{ padding: '24px 32px' }}>
        <Typography variant="h5" alignItems="center">
          {isAboutToExpire && <>⏳ </>}
          {expiresInDays === 0 ? (
            <>
              Your trial expires{' '}
              <Typography
                variant="inherit"
                color={isAboutToExpire ? 'red.400' : 'textPrimary'}
                component="span"
              >
                today
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="inherit"
                color={isAboutToExpire ? 'red.400' : 'textPrimary'}
                component="span"
              >
                {expiresInDays} {pluralize('day', expiresInDays)}
              </Typography>{' '}
              left of your trial
            </>
          )}
        </Typography>
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>

      <DialogContent sx={{ padding: '24px 32px' }}>
        <Stack gap="16px">
          <Stack gap="8px">
            <Typography variant="h6">
              Don&apos;t lose access to these premium features:
            </Typography>
            <AccountTypeDescription type="paid" isTrialActive={true} />
          </Stack>

          <Typography variant="P12R" color="textSecondary">
            Ready to see how Cast AI can continue to drive success for your
            organization? Schedule a call with our team to explore your options
            and maximize your savings potential.
          </Typography>
        </Stack>

        <Stack mt="32px" gap="4px">
          <Typography variant="h6">
            What happens to my clusters after the trial ends?
          </Typography>

          <Typography variant="P12R" color="textSecondary">
            A downgrading process will take place to transition your account
            back to the free version, ensuring your infrastructure is preserved.
            For more details,{' '}
            <ExternalLink link={EXTERNAL_LINKS.docs_trial} noIcon>
              see our documentation
            </ExternalLink>
            .
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px 32px',
          borderTop: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Button
          data-testid="self-checkout-button"
          variant="tertiary"
          onClick={onSelfCheckout}
        >
          Self-checkout
        </Button>

        <Button
          data-testid="schedule-sales-call-button"
          variant="primary"
          onClick={onScheduleCall}
        >
          Talk to us to upgrade
        </Button>
      </DialogActions>
    </Stack>
  );
};
