import { PropsWithChildren, SyntheticEvent } from 'react';

import { DialogContext } from './context';

export const DialogProvider = ({
  destroy,
  children,
}: PropsWithChildren<{ destroy?: (event?: SyntheticEvent) => void }>) => {
  return (
    <DialogContext.Provider value={{ destroy }}>
      {children}
    </DialogContext.Provider>
  );
};
