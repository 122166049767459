import { Stack } from '@mui/material';

import { Tooltip } from '@cast/design-system';

interface Props {
  slide: Slide;
  disabled: boolean;
  setSlide: (slide: Slide) => void;
}

export type Slide = 'contacts' | 'role';

export const ContactFormStepper = ({ slide, disabled, setSlide }: Props) => {
  const steps: Slide[] = ['contacts', 'role'];
  const currentStep = steps.indexOf(slide);

  return (
    <Stack
      direction="column"
      gap="8px"
      sx={{
        padding: '0px 80px 8px',
        maxWidth: '552px',
        placeContent: 'flex-start center',
        alignItems: 'flex-start',
      }}
    >
      <Stack
        direction="row"
        gap="8px"
        style={{
          placeContent: 'flex-end flex-start',
          alignItems: 'flex-end',
        }}
      >
        {steps.map((step, index) => (
          <Tooltip
            key={index}
            title={index + 1 + '/' + steps.length + ' steps'}
          >
            <span>
              <button
                style={{
                  width: '32px',
                  height: '4px',
                  border: 'none',
                  borderRadius: '4px',
                  backgroundColor: index <= currentStep ? '#0669FD' : '#E1E4EA',
                  cursor: index !== currentStep ? 'pointer' : '',
                }}
                type="button"
                disabled={disabled}
                onClick={() => {
                  setSlide(step);
                }}
              ></button>
            </span>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
};
