import { useRef } from 'react';

import { useModal } from 'mui-modal-provider';

import { Cluster } from '@cast/types';

import { useCluster } from 'hooks/useCluster';
import { ShowModalOutput } from 'types/modal';

import { AssignAutoscalerCredentialsDialog } from './AssignAutoscalerCredentialsDialog';
import { useInitializeTrialDialog } from '../../../../../components/trial/dialogs/useInitializeTrialDialog';
import { useTrial } from '../../../../../core/trial';

type Props = {
  cluster?: Cluster;
  onEnabled?: () => void;
};

export const useAssignAutoscalerCredentialsDialog = ({
  cluster: clusterProp,
  onEnabled,
}: Props = {}) => {
  const modalRef = useRef<ShowModalOutput>();
  const { cluster } = useCluster();
  const { showModal } = useModal();

  const destroy = () => {
    modalRef.current?.destroy();
  };

  const openAssignAutoscalerCredentialsDialog = () => {
    modalRef.current = showModal(
      AssignAutoscalerCredentialsDialog,
      {
        cluster: clusterProp ?? cluster,
        destroy,
        onEnabled,
      },
      { rootId: 'root' }
    );
  };

  const { openInitializeTrialDialog } = useInitializeTrialDialog();

  const { isEligible, state } = useTrial();

  return {
    openAssignAutoscalerCredentialsDialog:
      isEligible && state === 'pre-trial'
        ? openInitializeTrialDialog.bind(null, {
            onSettle: openAssignAutoscalerCredentialsDialog,
          })
        : openAssignAutoscalerCredentialsDialog,
  };
};
