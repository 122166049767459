import { useEffect, useState } from 'react';

import { Box, FormControlLabel, Slide, Stack, Typography } from '@mui/material';

import {
  Button,
  Checkbox,
  DialogContent,
  IconButton,
  Icons,
  Skeleton,
} from '@cast/design-system';
import { ClusterEditParams, K8sProvider } from '@cast/types';

import { AgentCodeSnippet } from 'components/code';
import { useUpdateClusterMutation } from 'hooks/mutations';
import {
  useAgentScriptQuery,
  useCredentialsScriptQuery,
} from 'hooks/queries/cluster';
import { useNavigate } from 'hooks/useNavigate';
import { Cluster, isEKSCluster, isROSACluster } from 'types/cluster';
import { parseApiError } from 'utils/api';
import { mapClusterToCloud } from 'utils/csp';

import { AssignCredentialsForm } from '../../../_components/AssignCredentialsForm';
import {
  RosaParametersForm,
  useRosaParametersForm,
} from '../../../_components/RosaParametersForm';

const securityConstructByProvider: Partial<Record<K8sProvider, string>> = {
  [K8sProvider.EKS]: 'Generated role ARN',
  [K8sProvider.AKS]: 'App secret',
  [K8sProvider.GKE]: 'Service Account JSON',
  [K8sProvider.KOPS]: 'IAM user key/secret',
};

type GetScriptContentProps = {
  cluster: Cluster;
  phase: 1 | 2;
  openManualStep: boolean;
  onClose: () => void;
  setOpenManualStep: (open: boolean) => void;
  setSubmitForm: (submitHandler: () => void) => void;
  setIsManuallySubmitting: (isSubmitting: boolean) => void;
};

export const GetScriptContent = ({
  cluster,
  phase,
  openManualStep,
  onClose,
  setOpenManualStep,
  setSubmitForm,
  setIsManuallySubmitting,
}: GetScriptContentProps) => {
  const navigate = useNavigate();
  const [configVisible, setConfigVisible] = useState(false);
  const [installGPUPlugin, setInstallGPUPlugin] = useState<boolean>(false);
  const [assignCredentialsErrorMessage, setAssignCredentialsErrorMessage] =
    useState<string | undefined>(undefined);

  const isPhase1 = phase === 1;
  const { rosaFormProps, rosaQueryArg } = useRosaParametersForm();

  const {
    isLoading: isLoadingReadOnlyScript,
    isFetching: isFetchingReadOnlyScript,
    script: readOnlyScript,
    refetch: refetchReadOnlyScript,
    error: readOnlyScriptError,
  } = useAgentScriptQuery({
    provider: isPhase1 ? cluster.providerType : undefined,
    rosa: rosaQueryArg,
  });

  const {
    isLoading: isLoadingManagedScript,
    isFetching: isFetchingManagedScript,
    script: managedScript,
    refetch: refetchMangedScript,
    error: errorManagedScript,
  } = useCredentialsScriptQuery({
    id: !isPhase1 ? cluster.id : undefined,
    installGPUPlugin,
  });

  const { mutate, isPending: mutationIsLoading } = useUpdateClusterMutation(
    cluster.id
  );

  const isLoading = isPhase1 ? isLoadingReadOnlyScript : isLoadingManagedScript;
  const isFetching = isPhase1
    ? isFetchingReadOnlyScript
    : isFetchingManagedScript;
  const script = isPhase1 ? readOnlyScript : managedScript;
  const refetch = isPhase1 ? refetchReadOnlyScript : refetchMangedScript;
  const error = isPhase1 ? readOnlyScriptError : errorManagedScript;

  const securityConstruct = securityConstructByProvider[cluster.providerType];

  const csp = mapClusterToCloud(cluster);

  const handleCloseManualStep = () => {
    setOpenManualStep(false);
    setAssignCredentialsErrorMessage('');
  };

  const onManualSubmit = (payload: ClusterEditParams) => {
    const mutationPayload = {
      ...payload,
    };
    if (payload.credentials) {
      mutationPayload.credentials = JSON.stringify(payload.credentials);
    }
    mutate(mutationPayload, {
      onError: (error: unknown) => {
        setAssignCredentialsErrorMessage(parseApiError(error));
      },
      onSuccess: (cluster) => {
        onClose();
        navigate({
          pathname: `/external-clusters/${cluster.id}/autoscaler`,
          hash: 'access_granted',
        });
      },
    });
  };

  useEffect(() => {
    setIsManuallySubmitting(mutationIsLoading);
  }, [mutationIsLoading, setIsManuallySubmitting]);

  return (
    <DialogContent sx={{ p: '8px 32px 12px' }}>
      <Stack gap={4}>
        <Typography variant="h4">Reconnect cluster</Typography>

        {isPhase1 && isROSACluster(cluster) && (
          <Box my={12}>
            <RosaParametersForm {...rosaFormProps} />
          </Box>
        )}

        <Typography variant="h6">
          Open your cloud shell or terminal and run the script
        </Typography>

        <Typography variant="P12R" color="textSecondary">
          Make sure to have kubectl installed and able to access your cluster.
          Copy the script below and run it in your cloud shell or terminal. Then
          click “I ran the script”.
        </Typography>
      </Stack>

      <Box mt={12}>
        {isEKSCluster(cluster) && !isPhase1 && (
          <Box
            data-testid="configuration-section"
            mx="-32px"
            p="12px 32px 8px"
            borderTop="1px solid"
            borderColor="grey.100"
            overflow="hidden"
          >
            {configVisible ? (
              <Slide in={true} mountOnEnter unmountOnExit>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Stack>
                    <FormControlLabel
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 8,
                        m: 0,
                      }}
                      control={
                        <Checkbox
                          testId="install-nvidia-checkbox"
                          size="small"
                          color="primary"
                          value={installGPUPlugin}
                          onChange={(_, checked) =>
                            setInstallGPUPlugin(checked)
                          }
                        />
                      }
                      label={
                        <Stack>
                          <Typography variant="P12B">
                            Install NVIDIA device plugin
                          </Typography>

                          <Typography variant="P12R" color="grey.600">
                            Required to run GPU enabled containers in your
                            cluster.
                          </Typography>
                        </Stack>
                      }
                    />
                  </Stack>

                  <IconButton
                    size="small"
                    variant="ghost"
                    onClick={
                      openManualStep ? undefined : () => setConfigVisible(false)
                    }
                  >
                    <Icons.X />
                  </IconButton>
                </Stack>
              </Slide>
            ) : (
              <Button
                testId="configure-script-button"
                variant="text"
                size="medium"
                onClick={
                  openManualStep ? undefined : () => setConfigVisible(true)
                }
              >
                Configure
              </Button>
            )}
          </Box>
        )}

        <AgentCodeSnippet
          code={script}
          isLoading={isLoading || !script}
          skeletonHeight={104}
          sx={openManualStep ? { opacity: 0.5 } : undefined}
          refetch={refetch}
          error={error}
        />
      </Box>

      {!!securityConstruct && (
        <>
          {!isPhase1 && !openManualStep && (
            <Box mt={12}>
              {isFetching ? (
                <Skeleton width="268px" height="33px" />
              ) : (
                <Stack direction="row" alignItems="baseline" gap={4}>
                  <Typography variant="P12R" color="textSecondary">
                    Add {securityConstruct}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => setOpenManualStep(true)}
                    sx={{ typography: 'P12M' }}
                    testId="add-security-construct-manualy-button"
                  >
                    manually
                  </Button>
                </Stack>
              )}
            </Box>
          )}
          {openManualStep && (
            <Box overflow="hidden" mt={12}>
              <Slide in={true} mountOnEnter unmountOnExit>
                <Stack gap={8}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="P12B">
                      Add {securityConstruct} manually
                    </Typography>

                    <IconButton
                      variant="ghost"
                      size="small"
                      onClick={handleCloseManualStep}
                    >
                      <Icons.X />
                    </IconButton>
                  </Stack>

                  <Stack>
                    <AssignCredentialsForm
                      csp={csp}
                      onSubmit={onManualSubmit}
                      setSubmitHandler={setSubmitForm}
                      error={assignCredentialsErrorMessage}
                    />
                  </Stack>
                </Stack>
              </Slide>
            </Box>
          )}
        </>
      )}
    </DialogContent>
  );
};
