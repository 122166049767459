/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiInventoryV1beta1ClusterPrice } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1GetPricingForClusterNodeResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1GetPricingForClusterNodesResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1ListOrganizationClusterPricesResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse } from '../models';
// @ts-ignore
import type { ClusterPricing } from '../models';
/**
 * PricingAPIApi - axios parameter creator
 * @export
 */
export const PricingAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} priceOrganizationId Organization id.
         * @param {string} priceClusterId Cluster id.
         * @param {ClusterPricing} price Cluster pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPICreateClusterPrice: async (priceOrganizationId: string, priceClusterId: string, price: ClusterPricing, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceOrganizationId' is not null or undefined
            assertParamExists('pricingAPICreateClusterPrice', 'priceOrganizationId', priceOrganizationId)
            // verify required parameter 'priceClusterId' is not null or undefined
            assertParamExists('pricingAPICreateClusterPrice', 'priceClusterId', priceClusterId)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('pricingAPICreateClusterPrice', 'price', price)
            const localVarPath = `/pricing/v1/organizations/{price.organizationId}/clusters/{price.clusterId}/price`
                .replace(`{${"price.organizationId"}}`, encodeURIComponent(String(priceOrganizationId)))
                .replace(`{${"price.clusterId"}}`, encodeURIComponent(String(priceClusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(price, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} organizationId Organization id.
         * @param {string} clusterId Cluster id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetClusterPrice: async (organizationId: string, clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('pricingAPIGetClusterPrice', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('pricingAPIGetClusterPrice', 'clusterId', clusterId)
            const localVarPath = `/pricing/v1/organizations/{organizationId}/clusters/{clusterId}/price`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The node id
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNode: async (clusterId: string, nodeId: string, pricingAsOf?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNode', 'clusterId', clusterId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNode', 'nodeId', nodeId)
            const localVarPath = `/v1/pricing/clusters/{clusterId}/nodes/{nodeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {string} clusterId The ID of the cluster.
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {string} [pricingPeriodStartTime] Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
         * @param {string} [pricingPeriodEndTime] Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNodes: async (clusterId: string, nodeIds?: Array<string>, pricingAsOf?: string, pricingPeriodStartTime?: string, pricingPeriodEndTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNodes', 'clusterId', clusterId)
            const localVarPath = `/v1/pricing/clusters/{clusterId}/nodes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeIds) {
                localVarQueryParameter['nodeIds'] = nodeIds;
            }

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }

            if (pricingPeriodStartTime !== undefined) {
                localVarQueryParameter['pricingPeriod.startTime'] = (pricingPeriodStartTime as any instanceof Date) ?
                    (pricingPeriodStartTime as any).toISOString() :
                    pricingPeriodStartTime;
            }

            if (pricingPeriodEndTime !== undefined) {
                localVarQueryParameter['pricingPeriod.endTime'] = (pricingPeriodEndTime as any instanceof Date) ?
                    (pricingPeriodEndTime as any).toISOString() :
                    pricingPeriodEndTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {string} [pricingPeriodStartTime] Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
         * @param {string} [pricingPeriodEndTime] Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForOrganizationNodes: async (nodeIds?: Array<string>, pricingAsOf?: string, pricingPeriodStartTime?: string, pricingPeriodEndTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeIds) {
                localVarQueryParameter['nodeIds'] = nodeIds;
            }

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }

            if (pricingPeriodStartTime !== undefined) {
                localVarQueryParameter['pricingPeriod.startTime'] = (pricingPeriodStartTime as any instanceof Date) ?
                    (pricingPeriodStartTime as any).toISOString() :
                    pricingPeriodStartTime;
            }

            if (pricingPeriodEndTime !== undefined) {
                localVarQueryParameter['pricingPeriod.endTime'] = (pricingPeriodEndTime as any instanceof Date) ?
                    (pricingPeriodEndTime as any).toISOString() :
                    pricingPeriodEndTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List hourly unit prices of CPU and memory for CAST Anywhere clusters in organization.
         * @param {string} organizationId Organization id.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIListOrganizationClusterPrices: async (organizationId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('pricingAPIListOrganizationClusterPrices', 'organizationId', organizationId)
            const localVarPath = `/pricing/v1/organizations/{organizationId}/clusters/prices`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {string} [clusterId] GCP cluster ID.
         * @param {string} [billingAccountId] GCP billing account ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPISyncGCPBillingAPIData: async (clusterId?: string, billingAccountId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing/sync/gcp/billing-api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (billingAccountId !== undefined) {
                localVarQueryParameter['billingAccountId'] = billingAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} priceOrganizationId Organization id.
         * @param {string} priceClusterId Cluster id.
         * @param {ClusterPricing} price Cluster pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIUpdateClusterPrice: async (priceOrganizationId: string, priceClusterId: string, price: ClusterPricing, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceOrganizationId' is not null or undefined
            assertParamExists('pricingAPIUpdateClusterPrice', 'priceOrganizationId', priceOrganizationId)
            // verify required parameter 'priceClusterId' is not null or undefined
            assertParamExists('pricingAPIUpdateClusterPrice', 'priceClusterId', priceClusterId)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('pricingAPIUpdateClusterPrice', 'price', price)
            const localVarPath = `/pricing/v1/organizations/{price.organizationId}/clusters/{price.clusterId}/price`
                .replace(`{${"price.organizationId"}}`, encodeURIComponent(String(priceOrganizationId)))
                .replace(`{${"price.clusterId"}}`, encodeURIComponent(String(priceClusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(price, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricingAPIApi - functional programming interface
 * @export
 */
export const PricingAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricingAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} priceOrganizationId Organization id.
         * @param {string} priceClusterId Cluster id.
         * @param {ClusterPricing} price Cluster pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPICreateClusterPrice(priceOrganizationId: string, priceClusterId: string, price: ClusterPricing, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ClusterPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPICreateClusterPrice(priceOrganizationId, priceClusterId, price, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPICreateClusterPrice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} organizationId Organization id.
         * @param {string} clusterId Cluster id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetClusterPrice(organizationId: string, clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ClusterPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetClusterPrice(organizationId, clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetClusterPrice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The node id
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForClusterNode(clusterId: string, nodeId: string, pricingAsOf?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForClusterNode(clusterId, nodeId, pricingAsOf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForClusterNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {string} clusterId The ID of the cluster.
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {string} [pricingPeriodStartTime] Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
         * @param {string} [pricingPeriodEndTime] Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForClusterNodes(clusterId: string, nodeIds?: Array<string>, pricingAsOf?: string, pricingPeriodStartTime?: string, pricingPeriodEndTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForClusterNodes(clusterId, nodeIds, pricingAsOf, pricingPeriodStartTime, pricingPeriodEndTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForClusterNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {string} [pricingPeriodStartTime] Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
         * @param {string} [pricingPeriodEndTime] Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForOrganizationNodes(nodeIds?: Array<string>, pricingAsOf?: string, pricingPeriodStartTime?: string, pricingPeriodEndTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForOrganizationNodes(nodeIds, pricingAsOf, pricingPeriodStartTime, pricingPeriodEndTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForOrganizationNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List hourly unit prices of CPU and memory for CAST Anywhere clusters in organization.
         * @param {string} organizationId Organization id.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIListOrganizationClusterPrices(organizationId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ListOrganizationClusterPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIListOrganizationClusterPrices(organizationId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIListOrganizationClusterPrices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {string} [clusterId] GCP cluster ID.
         * @param {string} [billingAccountId] GCP billing account ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPISyncGCPBillingAPIData(clusterId?: string, billingAccountId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPISyncGCPBillingAPIData(clusterId, billingAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPISyncGCPBillingAPIData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upsert the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {string} priceOrganizationId Organization id.
         * @param {string} priceClusterId Cluster id.
         * @param {ClusterPricing} price Cluster pricing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIUpdateClusterPrice(priceOrganizationId: string, priceClusterId: string, price: ClusterPricing, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ClusterPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIUpdateClusterPrice(priceOrganizationId, priceClusterId, price, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIUpdateClusterPrice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PricingAPIApi - factory interface
 * @export
 */
export const PricingAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricingAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {PricingAPIApiPricingAPICreateClusterPriceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPICreateClusterPrice(requestParameters: PricingAPIApiPricingAPICreateClusterPriceRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ClusterPrice> {
            return localVarFp.pricingAPICreateClusterPrice(requestParameters.priceOrganizationId, requestParameters.priceClusterId, requestParameters.price, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {PricingAPIApiPricingAPIGetClusterPriceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetClusterPrice(requestParameters: PricingAPIApiPricingAPIGetClusterPriceRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ClusterPrice> {
            return localVarFp.pricingAPIGetClusterPrice(requestParameters.organizationId, requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {PricingAPIApiPricingAPIGetPricingForClusterNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNode(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodeResponse> {
            return localVarFp.pricingAPIGetPricingForClusterNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.pricingAsOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {PricingAPIApiPricingAPIGetPricingForClusterNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodesResponse> {
            return localVarFp.pricingAPIGetPricingForClusterNodes(requestParameters.clusterId, requestParameters.nodeIds, requestParameters.pricingAsOf, requestParameters.pricingPeriodStartTime, requestParameters.pricingPeriodEndTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForOrganizationNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse> {
            return localVarFp.pricingAPIGetPricingForOrganizationNodes(requestParameters.nodeIds, requestParameters.pricingAsOf, requestParameters.pricingPeriodStartTime, requestParameters.pricingPeriodEndTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List hourly unit prices of CPU and memory for CAST Anywhere clusters in organization.
         * @param {PricingAPIApiPricingAPIListOrganizationClusterPricesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIListOrganizationClusterPrices(requestParameters: PricingAPIApiPricingAPIListOrganizationClusterPricesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ListOrganizationClusterPricesResponse> {
            return localVarFp.pricingAPIListOrganizationClusterPrices(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPISyncGCPBillingAPIData(requestParameters: PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse> {
            return localVarFp.pricingAPISyncGCPBillingAPIData(requestParameters.clusterId, requestParameters.billingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
         * @param {PricingAPIApiPricingAPIUpdateClusterPriceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIUpdateClusterPrice(requestParameters: PricingAPIApiPricingAPIUpdateClusterPriceRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ClusterPrice> {
            return localVarFp.pricingAPIUpdateClusterPrice(requestParameters.priceOrganizationId, requestParameters.priceClusterId, requestParameters.price, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pricingAPICreateClusterPrice operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPICreateClusterPriceRequest
 */
export interface PricingAPIApiPricingAPICreateClusterPriceRequest {
    /**
     * Organization id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPICreateClusterPrice
     */
    readonly priceOrganizationId: string

    /**
     * Cluster id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPICreateClusterPrice
     */
    readonly priceClusterId: string

    /**
     * Cluster pricing.
     * @type {ClusterPricing}
     * @memberof PricingAPIApiPricingAPICreateClusterPrice
     */
    readonly price: ClusterPricing
}

/**
 * Request parameters for pricingAPIGetClusterPrice operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetClusterPriceRequest
 */
export interface PricingAPIApiPricingAPIGetClusterPriceRequest {
    /**
     * Organization id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetClusterPrice
     */
    readonly organizationId: string

    /**
     * Cluster id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetClusterPrice
     */
    readonly clusterId: string
}

/**
 * Request parameters for pricingAPIGetPricingForClusterNode operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForClusterNodeRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForClusterNodeRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly clusterId: string

    /**
     * The node id
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly nodeId: string

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly pricingAsOf?: string
}

/**
 * Request parameters for pricingAPIGetPricingForClusterNodes operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForClusterNodesRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForClusterNodesRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly clusterId: string

    /**
     * The node ids
     * @type {Array<string>}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly nodeIds?: Array<string>

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly pricingAsOf?: string

    /**
     * Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly pricingPeriodStartTime?: string

    /**
     * Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly pricingPeriodEndTime?: string
}

/**
 * Request parameters for pricingAPIGetPricingForOrganizationNodes operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest {
    /**
     * The node ids
     * @type {Array<string>}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly nodeIds?: Array<string>

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly pricingAsOf?: string

    /**
     * Optional. Inclusive start of the interval.  If specified, a Timestamp matching this interval will have to be the same or after the start.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly pricingPeriodStartTime?: string

    /**
     * Optional. Exclusive end of the interval.  If specified, a Timestamp matching this interval will have to be before the end.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly pricingPeriodEndTime?: string
}

/**
 * Request parameters for pricingAPIListOrganizationClusterPrices operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIListOrganizationClusterPricesRequest
 */
export interface PricingAPIApiPricingAPIListOrganizationClusterPricesRequest {
    /**
     * Organization id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIListOrganizationClusterPrices
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof PricingAPIApiPricingAPIListOrganizationClusterPrices
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIListOrganizationClusterPrices
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for pricingAPISyncGCPBillingAPIData operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest
 */
export interface PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest {
    /**
     * GCP cluster ID.
     * @type {string}
     * @memberof PricingAPIApiPricingAPISyncGCPBillingAPIData
     */
    readonly clusterId?: string

    /**
     * GCP billing account ID.
     * @type {string}
     * @memberof PricingAPIApiPricingAPISyncGCPBillingAPIData
     */
    readonly billingAccountId?: string
}

/**
 * Request parameters for pricingAPIUpdateClusterPrice operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIUpdateClusterPriceRequest
 */
export interface PricingAPIApiPricingAPIUpdateClusterPriceRequest {
    /**
     * Organization id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIUpdateClusterPrice
     */
    readonly priceOrganizationId: string

    /**
     * Cluster id.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIUpdateClusterPrice
     */
    readonly priceClusterId: string

    /**
     * Cluster pricing.
     * @type {ClusterPricing}
     * @memberof PricingAPIApiPricingAPIUpdateClusterPrice
     */
    readonly price: ClusterPricing
}

/**
 * PricingAPIApi - object-oriented interface
 * @export
 * @class PricingAPIApi
 * @extends {BaseAPI}
 */
export class PricingAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
     * @param {PricingAPIApiPricingAPICreateClusterPriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPICreateClusterPrice(requestParameters: PricingAPIApiPricingAPICreateClusterPriceRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPICreateClusterPrice(requestParameters.priceOrganizationId, requestParameters.priceClusterId, requestParameters.price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
     * @param {PricingAPIApiPricingAPIGetClusterPriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetClusterPrice(requestParameters: PricingAPIApiPricingAPIGetClusterPriceRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetClusterPrice(requestParameters.organizationId, requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pricing for single cluster node
     * @param {PricingAPIApiPricingAPIGetPricingForClusterNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForClusterNode(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodeRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForClusterNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.pricingAsOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
     * @param {PricingAPIApiPricingAPIGetPricingForClusterNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForClusterNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodesRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForClusterNodes(requestParameters.clusterId, requestParameters.nodeIds, requestParameters.pricingAsOf, requestParameters.pricingPeriodStartTime, requestParameters.pricingPeriodEndTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
     * @param {PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForOrganizationNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest = {}, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForOrganizationNodes(requestParameters.nodeIds, requestParameters.pricingAsOf, requestParameters.pricingPeriodStartTime, requestParameters.pricingPeriodEndTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List hourly unit prices of CPU and memory for CAST Anywhere clusters in organization.
     * @param {PricingAPIApiPricingAPIListOrganizationClusterPricesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIListOrganizationClusterPrices(requestParameters: PricingAPIApiPricingAPIListOrganizationClusterPricesRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIListOrganizationClusterPrices(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync GCP billing pricing API data
     * @param {PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPISyncGCPBillingAPIData(requestParameters: PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest = {}, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPISyncGCPBillingAPIData(requestParameters.clusterId, requestParameters.billingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert the hourly unit price for the CPU and memory of the CAST Anywhere cluster.
     * @param {PricingAPIApiPricingAPIUpdateClusterPriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIUpdateClusterPrice(requestParameters: PricingAPIApiPricingAPIUpdateClusterPriceRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIUpdateClusterPrice(requestParameters.priceOrganizationId, requestParameters.priceClusterId, requestParameters.price, options).then((request) => request(this.axios, this.basePath));
    }
}

