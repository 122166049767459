import { useQuery, UndefinedInitialDataOptions } from '@tanstack/react-query';

import { ClusterPricing } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type CastAnywhereClusterPricingQueryArgs = {
  organizationId?: string;
  clusterId?: string;
} & Omit<UndefinedInitialDataOptions, 'queryFn' | 'queryKey'>;

export const useCastAnywhereClusterPricingQuery = ({
  organizationId,
  clusterId,
  enabled = true,
  // Don't try to refetch if error is 404. It means that pricing was not entered yet.
  retry = (failureCount, error) => error.code !== '404' && failureCount < 1,
  ...rest
}: CastAnywhereClusterPricingQueryArgs) => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: [
      QueryKeys.PRICING_CAST_ANYWHERE_CLUSTER_COSTS,
      organizationId,
      clusterId,
    ],
    queryFn: async () => {
      const { data } = await apiClient.pricing.getCastAnywhereClusterPricing({
        organizationId: organizationId!,
        clusterId: clusterId!,
      });
      return data;
    },
    retry,
    enabled: !!organizationId && !!clusterId && enabled,
    ...rest,
  });

  return {
    clusterPricing: data as ClusterPricing | undefined,
    isLoading,
    error,
    refetch,
  };
};
