import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { DataPopperRow } from '@cast/design-system';
import { K8sProvider } from '@cast/types';
import { getCurrencyPrecision } from '@cast/utils';

import { WhenProvider } from 'components/cluster';
import { Money } from 'components/money';
import { CostReportChartTooltip } from 'features/cost-report/components/chart/CostReportChartTooltip';
import { computeSpendColorsMap, ComputeSpendType } from 'types/cost-report';

import { useCostOverTime } from '../../../../cost-report/hooks/useCostOverTime';
import { useCostReportContext } from '../../../../cost-report/hooks/useCostReportContext';
import { CostReportChartType } from '../../../../cost-report/types/costOverTime';

type TooltipRowProps = {
  label: string;
  cost: number | null;
  projected?: number;
  type: ComputeSpendType;
  hasProjectedCost: boolean;
};

const TooltipRow = ({
  label,
  cost,
  projected,
  type,
  hasProjectedCost,
}: TooltipRowProps) => {
  const costPrecision = cost !== null ? getCurrencyPrecision(cost) : 0;
  const projectedPrecision = getCurrencyPrecision(projected);

  return (
    <DataPopperRow
      indicator={{
        color: computeSpendColorsMap[type]!,
      }}
      left={<Typography variant="L10R">{label}</Typography>}
      right={
        <Stack direction="row" flexWrap="nowrap" gap={16}>
          <Typography variant="P10R" align="right" minWidth="50px">
            {cost !== null ? (
              <Money
                amount={cost}
                precision={costPrecision}
                fixed={costPrecision}
              />
            ) : (
              '--'
            )}
          </Typography>

          {hasProjectedCost && (
            <Typography
              variant="P10R"
              align="right"
              minWidth="50px"
              sx={{ opacity: 0.5 }}
            >
              <Money
                amount={projected}
                precision={projectedPrecision}
                fixed={projectedPrecision}
              />
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

type ComputeSpendTooltipProps = {
  payload?: any[];
  showStorage: boolean;
  timezone: string;
};

export const ComputeSpendTooltip = ({
  payload,
  showStorage,
  timezone,
}: ComputeSpendTooltipProps) => {
  const tooltipData = payload?.[0]?.payload || {};
  const {
    timestamp,
    onDemand,
    spot,
    fallback,
    storage,
    onDemandForecast,
    fallbackForecast,
    spotForecast,
    storageForecast,
    forecast,
    anomalies,
  } = tooltipData;
  const { chartType } = useCostReportContext();
  const { key, projectEndOfDayCost } = useCostOverTime();

  if (isEmpty(tooltipData)) {
    return null;
  }

  let cost =
    onDemand !== null || spot !== null || fallback !== null
      ? onDemand + spot + fallback
      : null;

  let projectedCost = projectEndOfDayCost
    ? onDemandForecast + spotForecast + fallbackForecast + storageForecast ||
      undefined
    : undefined;

  if (showStorage) {
    cost = cost === null ? storage : cost + (storage || 0);
    projectedCost =
      projectedCost === undefined
        ? projectedCost
        : projectedCost + (storageForecast || 0);
  }

  const date = dayjs.utc(timestamp).tz(timezone);

  const hasAnomalies = !!anomalies?.length;

  const secondaryValue = () => {
    if (key !== 'cluster') {
      return undefined;
    }
    if (!hasAnomalies) {
      return chartType === CostReportChartType.AREA
        ? date.format('hh:mmA')
        : undefined;
    }

    if (anomalies.length > 1) {
      return `${anomalies.length} Cost Anomalies ${
        chartType === CostReportChartType.AREA ? date.format('hh:mmA') : ''
      }`;
    }

    return `Cost Anomaly ${dayjs
      .utc(anomalies[0].timestamp)
      .tz(timezone)
      .format('hh:mmA')}`;
  };

  const hasProjectedCost = projectedCost && cost < projectedCost;

  return (
    <CostReportChartTooltip
      primary={date.format('MMM D')}
      secondary={secondaryValue()}
      secondaryProps={{ color: hasAnomalies ? 'red.500' : 'inherit' }}
      title="Compute cost"
      total={cost}
      projectedCost={projectedCost}
      projected={hasProjectedCost}
      forecast={forecast}
      sx={{ minWidth: 240 }}
    >
      {showStorage && (
        <TooltipRow
          label=" STORAGE"
          cost={storage}
          projected={storageForecast}
          type={ComputeSpendType.STORAGE}
          hasProjectedCost={hasProjectedCost}
        />
      )}
      <WhenProvider isNot provider={K8sProvider.ANYWHERE}>
        <TooltipRow
          label=" ON-DEMAND"
          cost={onDemand}
          projected={onDemandForecast}
          type={ComputeSpendType.ON_DEMAND}
          hasProjectedCost={hasProjectedCost}
        />
        {!forecast && (
          <TooltipRow
            label=" FALLBACK"
            cost={fallback}
            type={ComputeSpendType.FALLBACK}
            hasProjectedCost={false}
          />
        )}
        <TooltipRow
          label=" SPOT"
          cost={spot}
          projected={spotForecast}
          type={ComputeSpendType.SPOT}
          hasProjectedCost={hasProjectedCost}
        />
      </WhenProvider>
    </CostReportChartTooltip>
  );
};
