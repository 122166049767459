import { ClusterPricing } from '@cast/types';

import { PricingFormModel } from './types';

export const pricingToFormModel = (
  pricing?: ClusterPricing
): PricingFormModel => {
  return {
    cpuHourlyPrice: pricing?.cpuHourlyPrice
      ? Number(pricing.cpuHourlyPrice)
      : null,
    memoryGibHourlyPrice: pricing?.memoryGibHourlyPrice
      ? Number(pricing.memoryGibHourlyPrice)
      : null,
  };
};
